/* eslint-disable react-hooks/exhaustive-deps */
import { faTimes, faSearch } from '@fortawesome/pro-light-svg-icons';
import { faUserPlus, faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import propTypes from 'prop-types';
import React, { useContext, useEffect, useRef } from 'react';
import ReactMarkdown from 'react-markdown';

import { SessionContext } from 'app_state/session/SessionProvider';
import Card from 'generics/Card/Card';
import CardsList from 'generics/CardsList/CardsList';
import Eyebrow from 'generics/Eyebrow/Eyebrow';
import IconButton from 'generics/IconButton/IconButton';
import SearchResults from 'generics/SearchResults/SearchResults';
import URLS from 'app_constants/urls';
import useSearch from 'custom_hooks/useSearch';

import styles from './SearchBar.scss';

const SearchBar = ({ isVisible, onClose }: ISearchBarProps) : JSX.Element => {
  const { t } = useTranslation('search');
  const searchInputRef = useRef<HTMLInputElement>(null);

  const {
    onClearSearch,
    onGetMoreResults,
    areMorePages,
    query,
    results,
    areResultsVisible,
    onChangeQuery,
  } = useSearch();

  const handleClearButtonClick = () => {
    if (searchInputRef?.current) {
      searchInputRef.current.focus();
    }
    onClearSearch();
  };

  // Toggle searchBarVisibility
  useEffect(() => {
    if (isVisible) {
      if (searchInputRef?.current) {
        searchInputRef.current.focus();
      }
    } else if (query) {
      onClearSearch();
    }
  }, [isVisible]);

  return (
    <div
      className={classNames(
        [styles['search-bar__platform']],
        { [styles['is-visible']]: isVisible },
      )}
    >

      <div className={styles['search-bar__stage']}>
        <div
          className={classNames(
            [styles['search-bar__wrapper']],
            { [styles['is-visible']]: isVisible },
          )}
        >
          <IconButton
            className={styles['search-bar__close-button']}
            icon={faTimes}
            onClick={onClose}
          />
          <div className={styles['search-bar']}>
            <div className={styles['search-bar__input__wrapper']}>
              <FontAwesomeIcon
                className={styles['search-bar__input__icon']}
                icon={faSearch}
              />
              <input
                autoComplete="off"
                className={styles['search-bar__input']}
                id="search"
                name="search"
                onChange={(e) => { onChangeQuery(e.currentTarget.value); }}
                placeholder={t('defaultPlaceholder')}
                ref={searchInputRef}
                type="text"
                value={query}
              />
              {query && (
                <button
                  className={styles['search-bar__input__clear-button']}
                  type="button"
                  onClick={handleClearButtonClick}
                >
                  <FontAwesomeIcon icon={faTimesCircle} />
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      {(areResultsVisible && query) && (
        <div
          className={classNames(
            styles['search-bar__results__stage'],
            styles['is-visible'],
          )}
        >
          <SearchResults
            profiles={results}
            areMorePages={areMorePages}
            onGetMore={onGetMoreResults}
          />
          <div className={styles['search-bar__results__wrapper']}>
            <Eyebrow>
              <ReactMarkdown source={t('eyebrowText')} />
            </Eyebrow>
            <CardsList
              className={styles['search-bar__results']}
              cards={[
                <Card
                  media={<FontAwesomeIcon icon={faUserPlus} />}
                  route={URLS.sendInvitation}
                  showBeachBall
                  title={t('inviteFriendsByEmail')}
                />,
              ]}
            />
          </div>
        </div>
      )}
    </div>
  );
};

SearchBar.propTypes = {
  isVisible: propTypes.bool,
  onClose: propTypes.func.isRequired,
};

SearchBar.defaultProps = {
  isVisible: false,
};

interface ISearchBarProps {
  isVisible: boolean,
  onClose: () => void,
}

export default SearchBar;
