import { formatRoute } from 'react-router-named-routes';
import { size } from 'lodash';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import propTypes from 'prop-types';
import React, { useState } from 'react';

import Avatar from 'generics/Avatar/Avatar';
import Button from 'generics/Button/Button';
import Card from 'generics/Card/Card';
import CardsList from 'generics/CardsList/CardsList';
import Profile from 'classes/Profile/Profile.class';
import URLS from 'app_constants/urls';
import useAddFriend from 'custom_hooks/useAddFriend';

import styles from './SearchResults.scss';

const SearchResults = ({
  className,
  areMorePages,
  onGetMore,
  profiles,
}: ISearchResultsProps) : JSX.Element => {
  const { t } = useTranslation('search');
  const [friendRequests, setFriendRequests] = useState<number[]>([]);
  const { onAddFriend } = useAddFriend();

  const handleAddFriendClick = (profileId: number) => {
    const onFinishAddFriend = () => setFriendRequests([...friendRequests, profileId]);
    onAddFriend(profileId, onFinishAddFriend);
  };

  const cards = profiles.map(({
    assessmentScores, avatar, id, isConnected, isConnectionRequestPending, name,
  }) => {
    const hasFriendRequest = isConnectionRequestPending || friendRequests.includes(id || 0);

    return (
      <Card
        assessmentScores={assessmentScores}
        media={<Avatar path={avatar} alt={`${name}`} />}
        route={formatRoute(URLS.profile, { id })}
        showBeachBall
        title={name || ''}
        subtitle={hasFriendRequest ? t('friendRequestSent') : undefined}
      >
        {!isConnected && !hasFriendRequest && (
          <Button isSmall onClick={() => handleAddFriendClick(id || 0)} data-cy="add-friend-btn">
            {t('addFriend')}
          </Button>
        )}
      </Card>
    );
  });

  const cardsResult = size(profiles) === 0 ? (
    <p className={styles['search-results__not-found']} data-cy="search-no-results">
      {t('noResults')}
    </p>
  ) : (
    <CardsList
      className={styles['search-results']}
      cards={cards}
    />
  );

  return (
    <div className={classNames(styles['search-results__wrapper'], className)} data-cy="search-results">
      { cardsResult }
      {
        areMorePages && (
          <Button
            className={styles['search-results__show-more']}
            isLink
            onClick={onGetMore}
            data-cy="more-results-btn"
          >
            { t('moreResults') }
          </Button>
        )
      }
    </div>
  );
};

SearchResults.propTypes = {
  className: propTypes.string,
  areMorePages: propTypes.bool,
  onGetMore: propTypes.func.isRequired,
  profiles: propTypes.arrayOf(propTypes.instanceOf(Profile)),
};

SearchResults.defaultProps = {
  className: null,
  areMorePages: false,
  profiles: [],
};

interface ISearchResultsProps {
  className?: string,
  areMorePages: boolean,
  onGetMore: () => void,
  profiles: Profile[],
}

export default SearchResults;
