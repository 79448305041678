/* eslint-disable react-hooks/exhaustive-deps */
import { faCheck, faUserPlus } from '@fortawesome/pro-solid-svg-icons';
import { formatRoute } from 'react-router-named-routes';
import { isNil, isEmpty } from 'lodash';
import { sprintf } from 'sprintf-js';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';

import { SessionContext } from 'app_state/session/SessionProvider';
import api from 'api';
import fetchAccount from 'app_state/session/actions/fetchAccount';
import URLS from 'app_constants/urls';
import useForm, { IErrors } from 'custom_hooks/useForm';
import useFormContextMenu from 'custom_hooks/useFormContextMenu';
import useFormValidation from 'custom_hooks/useFormValidation';
import useNotification from 'custom_hooks/useNotification';
import useSearch from 'custom_hooks/useSearch';

import {
  IInviteResult,
  ISendInvitationForm,
  IUseSendInvitation,
} from './types';

const useSendInvitation = () : IUseSendInvitation => {
  const { t } = useTranslation(['sendInvitation']);
  const { state: { currentUser }, dispatch } = useContext(SessionContext);
  const history = useHistory();
  const [isContextMenuVisible, setContextMenuVisibility] = useState(false);
  const { validator } = useFormValidation();
  const homeRoute = formatRoute(URLS.home, { id: currentUser?.id });
  const { addErrorNotification, addSuccessNotification, clearNotification } = useNotification();
  const [emailError, setEmailError] = useState<string | null>(null);

  useEffect(() => () => clearNotification(), []);

  const handleValidate = ({ email, firstName, lastName } : ISendInvitationForm): IErrors => {
    setEmailError(null);

    return {
      email: validator.validateRequired(email || '') || validator.validateEmail(email || ''),
      firstName: validator.validateRequired(firstName || '') || validator.validateName(firstName || ''),
      lastName: validator.validateRequired(lastName || '') || validator.validateName(lastName || ''),
    };
  };

  const handleSubmit = (values: ISendInvitationForm, nextRoute?: string, isBackSend?: boolean) => {
    const onFinish = () => {
      history.push(nextRoute ?? homeRoute);
      addSuccessNotification('invitationSent');
    };

    const onSuccess = ({ anyErrors, invite } : { anyErrors: boolean, invite: IInviteResult }) => {
      if (!anyErrors) {
        if (isBackSend) {
          onFinish();
        } else {
          setContextMenuVisibility(true);
          fetchAccount(dispatch);
        }
      } else {
        setEmailError(invite.errors[0]);
      }
    };

    const onError = () => {
      addErrorNotification('sendInviteApiError');
    };

    api.invites.sendInvitation(values, onSuccess, onError);
  };

  const {
    errors,
    isUpdated,
    isValid,
    onChangeField,
    onResetForm,
    onSubmitForm,
    values = { email: null, firstName: null, lastName: null },
  } = useForm<ISendInvitationForm>({ onSubmit: handleSubmit, onValidate: handleValidate });

  const {
    areMorePages,
    areResultsVisible,
    onChangeQuery,
    onClearSearch,
    onGetMoreResults,
    results,
  } = useSearch();

  // Updates query to search when values change
  useEffect(() => {
    const isFirstNameValid = !!values.firstName && isNil(errors.firstName);
    const isLastNameValid = !!values.lastName && isNil(errors.lastName);
    const isEmailValid = !!values.email && isNil(errors.email);

    if (isFirstNameValid || isLastNameValid || isEmailValid) {
      if (isEmailValid) {
        onChangeQuery(values.email || '');
      } else {
        const arrayQuery = [];

        if (isFirstNameValid) {
          arrayQuery.push(values.firstName);
        }

        if (isLastNameValid) {
          arrayQuery.push(values.lastName);
        }

        onChangeQuery(arrayQuery.join(' '));
      }
    } else {
      onClearSearch();
    }
  }, [values]);

  const handleSendAnotherInvite = () => {
    onResetForm();
    setContextMenuVisibility(false);
  };

  const contextMenuOptions = [
    {
      icon: faUserPlus,
      label: t('contextMenu.options.sendAnotherInvite'),
      onClick: handleSendAnotherInvite,
    },
    {
      icon: faCheck,
      label: t('contextMenu.options.done'),
      onClick: () => { history.push(URLS.home); },
    },
  ];

  const contextMenu = useFormContextMenu({
    onSubmitForm: (nextRoute) => handleSubmit(values, nextRoute, true),
  });

  const canSave = false;

  const canSend = (isValid && isUpdated) || !isUpdated;

  const handleChangeRoute = (route: string, e?: MouseEvent) => {
    if (e) {
      e.preventDefault();
    }

    if (isUpdated) {
      contextMenu.onShow(route, canSave, canSend);
    } else {
      history.push(route);
    }
  };

  return {
    search: {
      areMorePages,
      areResultsVisible,
      onGetMoreResults,
      results,
      showResults: !!(values.firstName || values.lastName || values.email) && !!results.length,
    },
    contextMenu: {
      options: contextMenu.isVisible ? contextMenu.options : contextMenuOptions,
      message: contextMenu.isVisible ? t('backContextMenu.message') : sprintf(t('contextMenu.message'), values.email),
      title: contextMenu.isVisible ? t('backContextMenu.title') : t('contextMenu.title'),
      isVisible: contextMenu.isVisible || isContextMenuVisible,
      onClose: contextMenu.isVisible ? contextMenu.onClose : handleSendAnotherInvite,
    },
    form: {
      canSubmit: isValid && isEmpty(results) && !emailError,
      emailError,
      errors,
      onChangeField,
      onSubmitForm,
      values,
    },
    onGoBack: () => handleChangeRoute(homeRoute),
  };
};

export default useSendInvitation;
