import { mapValues } from 'lodash';
import { IEnergy } from 'app_constants/energies';

import { IElements, IGeometry } from '../types';
import geometrySettings from './geometrySettings.json';
import getElementGeometry from './getElementGeometry';
import getScales from './getScales';

const initGraph = (energies: IEnergy[], elements: IElements) : any => {
  const geometry : IGeometry = geometrySettings;

  const {
    canvas,
    svg,
    mainG,
  } = elements;

  geometry.container = getElementGeometry(elements.svg?.node().parentNode, [
    'fontSize',
    'paddingBottom',
    'paddingLeft',
    'paddingRight',
    'paddingTop',
  ]);

  // x
  geometry.width = geometry.container.width
    - geometry.container.paddingLeft
    - geometry.container.paddingRight;

  geometry.widthHalf = 0.5 * geometry.width;

  geometry.innerWidth = geometry.width
    - geometry.safety.left
    - geometry.safety.right;

  geometry.innerWidthHalf = 0.5 * geometry.innerWidth;

  const legendHeight = geometry.legend.marginBottomFromReference;

  const labelsHeight = geometry.labels.dynamicNameFontSize
    + geometry.labels.energyNameLineHeight
    + 4;

  const aspectRatio = (
    labelsHeight
    + geometry.safety.top
    + geometry.labels.margin.top
    + geometry.innerWidth
    + geometry.labels.margin.bottom
    + legendHeight
    + geometry.safety.bottom)
    / geometry.innerWidth;

  // y
  geometry.height = aspectRatio * geometry.width;
  geometry.heightHalf = 0.5 * geometry.height;

  geometry.innerHeight = geometry.height
    - geometry.safety.top
    - geometry.safety.bottom;

  geometry.innerHeightHalf = 0.5 * geometry.innerHeight;

  geometry.center = {
    x: geometry.widthHalf,
    y: geometry.heightHalf - 2 - 0.5 * (legendHeight - labelsHeight),
  };

  // side
  geometry.side = 0.5 * geometry.innerWidth;

  // central area
  geometry.offset = geometry.offsetRatio * geometry.side;
  geometry.dotRadius = geometry.dotRadiusOffsetRatio * geometry.offset;

  // balloons
  geometry.maxBalloonSide = geometry.side - geometry.offset;

  // bands
  geometry.bandHalfSide = mapValues(geometry.bandSideRatios, (ratio: number) => (geometry.offset
    + (ratio * geometry.maxBalloonSide)));

  if (canvas) {
    canvas
      .attr('width', geometry.width)
      .attr('height', geometry.height);
  }

  if (svg) {
    svg.attr('viewBox', `0 0 ${geometry.width} ${geometry.height}`);
  }

  if (mainG) {
    mainG.attr('transform', `translate(${geometry.center.x}, ${geometry.center.y})`);
  }

  const newElements = {
    ...elements,
    canvas,
    svg,
    mainG,
  };

  return {
    geometry,
    elements: newElements,
    scales: getScales(energies, geometry),
  };
};

export default initGraph;
