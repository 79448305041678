/* eslint-disable react-hooks/exhaustive-deps */
import { faPenSquare, faTrash, faCheck } from '@fortawesome/pro-light-svg-icons';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';

import { IContextMenuOptionProps } from 'generics/ContextMenu/components/MenuOption/MenuOption';

const useFormContextMenu = ({ onSubmitForm }: IUseFormContextMenuProps)
  : IUseFormContextMenuResults => {
  const history = useHistory();
  const [isContextMenuVisible, setIsContextMenuVisible] = useState(false);
  const [options, setOptions] = useState<IContextMenuOptionProps[]>([]);
  const { t } = useTranslation('generics');

  const handleClose = () => setIsContextMenuVisible(false);

  const handleShow = (nextRoute: string, canSave: boolean, canSend?: boolean | null) => {
    const newOptions = [
      {
        icon: faTrash,
        label: t('formContextMenu.discard'),
        onClick: () => history.push(nextRoute),
      },
      {
        icon: faPenSquare,
        label: t('formContextMenu.continue'),
        onClick: handleClose,
      },
    ];

    if (canSave || canSend) {
      newOptions.unshift({
        icon: faCheck,
        label: canSave ? t('formContextMenu.save') : t('formContextMenu.send'),
        onClick: () => {
          onSubmitForm(nextRoute);
          handleClose();
        },
      });
    }

    setOptions(newOptions);
    setIsContextMenuVisible(true);
  };

  return {
    isVisible: isContextMenuVisible,
    onClose: handleClose,
    onShow: handleShow,
    options,
  };
};

export default useFormContextMenu;

export interface IUseFormContextMenuProps {
  onSubmitForm: (nextRoute: string) => void,
}

export interface IUseFormContextMenuResults {
  isVisible: boolean,
  onClose: () => void,
  onShow: (nextRoute: string, canSave: boolean, canSend?: boolean | null) => void,
  options: IContextMenuOptionProps[],
}
