import { Dispatch } from 'react';

import { ApiError } from 'classes/errors';
import { ISessionAction } from 'app_state/types';
import api from 'api';
import sessionActionTypes from 'app_state/session/actionTypes';

const fetchContentFailure = (error: typeof ApiError) : ISessionAction => ({
  payload: error,
  type: sessionActionTypes.FETCH_CONTENT_FAILURE,
});

const fetchContentRequest = () : ISessionAction => ({
  payload: null,
  type: sessionActionTypes.FETCH_CONTENT_REQUEST,
});

const fetchContentSuccess = (payload: any) : ISessionAction => ({
  payload,
  type: sessionActionTypes.FETCH_CONTENT_SUCCESS,
});

export default (
  dispatch: Dispatch<ISessionAction>,
) : void => {
  dispatch(fetchContentRequest());

  const onSuccess = (response : any) => {
    dispatch(fetchContentSuccess(response));
  };

  const onError = (error: typeof ApiError) => {
    dispatch(fetchContentFailure(error));
  };

  api.contentManager.getContent({ contentType: 'individual' }, onSuccess, onError);
};
