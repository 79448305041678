import { Dispatch } from 'react';

import { ISessionAction } from 'app_state/types';
import api from 'api';
import history from 'utils/history';
import resetState from 'app_state/session/actions/resetState';
import sessionActionTypes from 'app_state/session/actionTypes';
import URLS from 'app_constants/urls';

const logoutRequest = () : ISessionAction => ({
  payload: null,
  type: sessionActionTypes.FETCH_LOGOUT_REQUEST,
});

export default (
  dispatch: Dispatch<ISessionAction>,
) : void => {
  dispatch(logoutRequest());

  // clean the session even if it fails
  const onFinish = () => {
    dispatch(resetState());
    history.push(URLS.login);
  };

  api.session.logout(null, onFinish, onFinish);
};
