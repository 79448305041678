/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Button from 'generics/Button/Button';
import Content from 'generics/Content/Content';
import EnergyMap from 'generics/EnergyMap/EnergyMap';
import LoadingAnimation from 'generics/LoadingAnimation/LoadingAnimation';
import MenuMessage from 'generics/ContextMenu/components/MenuMessage/MenuMessage';
import MenuOptions from 'generics/ContextMenu/components/MenuOptions/MenuOptions';
import Modal from 'generics/Modal/Modal';
import PageSection from 'generics/PageSection/PageSection';
import PageTitle from 'generics/PageTitle/PageTitle';
import PageWrapper from 'generics/PageWrapper/PageWrapper';
import Payment from 'components/Payment/Payment';
import TradeshowForm from 'components/Tradeshow/TradeshowForm';
import URLS from 'app_constants/urls';

import useKit from './useKit';
import styles from './Kit.scss';

const KitPage = () : JSX.Element => {
  const { IS_TRADESHOW } = process.env;
  const { t } = useTranslation('kits');
  const history = useHistory();

  const {
    contextMenu,
    isPaymentModalVisible,
    isReportPaid,
    isDownloadLoading,
    isDownloadReportOptionsVisible,
    isLoading,
    isLoadingReportStatus,
    isTradeshowModalVisible,
    kit,
    setIsConfirmationDownloadModalVisible,
    setIsPaymentModalVisible,
    setIsDownloadReportOptionsVisible,
    setIsTradeshowModalVisible,
    handleDownloadReport,
    energyMapUtils,
  } = useKit();

  const contextMenuMessage = (
    <span>
      <strong>{contextMenu.title}</strong>
      <br />
      {contextMenu.message}
    </span>
  );

  return (
    <>
      <PageWrapper>
        {
          isLoading
            ? (
              <PageSection>
                <LoadingAnimation />
              </PageSection>
            )
            : (
              <div className={styles.layout}>
                <PageSection>
                  <PageTitle
                    title={kit?.title}
                    onBackButtonClick={() => history.push(URLS.home)}
                  />
                </PageSection>
                <PageSection className={styles['main-content']}>
                  <div className={styles.graphic__wrapper}>
                    <img
                      className={styles.graphic}
                      src={kit?.imageUrl}
                      alt={kit?.title}
                      title={kit?.title}
                    />
                  </div>
                  <Content content={kit?.content ?? ''} useHtmlParser />
                  {kit?.customPayment && (
                    <div className={styles['my-report__wrapper']}>
                      {isDownloadLoading ? (
                        <LoadingAnimation />
                      ) : (
                        <Button
                          disabled={isLoadingReportStatus}
                          onClick={() => (
                            isReportPaid
                              ? handleDownloadReport()
                              : !IS_TRADESHOW
                                ? setIsPaymentModalVisible(true)
                                : setIsDownloadReportOptionsVisible(true)
                          )}
                        >
                          {t('seeMyReportButton')}
                        </Button>
                      )}
                    </div>
                  )}
                </PageSection>
              </div>
            )
        }
        <EnergyMap energyMapUtils={energyMapUtils} />
      </PageWrapper>
      {(kit?.customPayment && isDownloadReportOptionsVisible) && (
        <Modal
          isSmall
          isVisible
          onClose={() => setIsDownloadReportOptionsVisible(false)}
        >
          <div className={styles.intro}>
            <strong>{t('selectDownloadOption')}</strong>
          </div>
          <div className={styles['download-options__wrapper']}>
            <Button
              isBlock
              onClick={() => {
                setIsTradeshowModalVisible(true);
                setIsDownloadReportOptionsVisible(false);
              }}
            >
              {t('promotionalCode')}
            </Button>
            <Button
              isBlock
              isSecondary
              onClick={() => {
                setIsPaymentModalVisible(true);
                setIsDownloadReportOptionsVisible(false);
              }}
            >
              {t('continuePayment')}
            </Button>
          </div>
        </Modal>
      )}
      {(kit?.customPayment && isPaymentModalVisible) && (
        <Modal
          isSmall
          isVisible
          onClose={() => setIsPaymentModalVisible(false)}
          shouldCloseOnEnterKey={false}
        >
          <Payment onFinish={() => {
            setIsPaymentModalVisible(false);
            handleDownloadReport();
          }}
          />
        </Modal>
      )}
      {(kit?.customPayment && isTradeshowModalVisible) && (
        <Modal
          isSmall
          isVisible
          onClose={() => setIsTradeshowModalVisible(false)}
          shouldCloseOnEnterKey={false}
        >
          <TradeshowForm
            onFinish={(validCode) => {
              setIsTradeshowModalVisible(false);
              handleDownloadReport(validCode);
            }}
          />
        </Modal>
      )}
      {(kit?.customPayment && contextMenu?.isVisible) && (
        <Modal
          isSmall
          isVisible
          onClose={() => setIsConfirmationDownloadModalVisible(false)}
          data-cy="confirm-download-modal"
        >
          <MenuMessage color="secondary" message={contextMenuMessage} />
          <MenuOptions color="secondary" options={contextMenu.options} />
        </Modal>
      )}
    </>
  );
};

export default KitPage;
