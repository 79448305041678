import { isNil } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React from 'react';

import Avatar from 'generics/Avatar/Avatar';
import Button from 'generics/Button/Button';
import Card from 'generics/Card/Card';
import Form from 'generics/Form/Form';
import Input from 'generics/Input/Input';
import PageSection from 'generics/PageSection/PageSection';
import PageWrapper from 'generics/PageWrapper/PageWrapper';
import URLS from 'app_constants/urls';

import styles from './ResetPassword.scss';
import useResetPassword from './useResetPassword';

const ResetPassword = () : JSX.Element => {
  const { t } = useTranslation('resetPassword');
  const history = useHistory();

  const {
    form: {
      errors,
      onChangeField,
      onSubmitForm,
      isValid,
      values = { password: null, passwordConfirmation: null },
    },
    profile,
  } = useResetPassword();

  return (
    <PageWrapper>
      <div className={styles.layout}>
        <PageSection>
          <div className={styles.intro}>
            <h1>{t('title')}</h1>
            <p>{t('description')}</p>
          </div>
          <Card
            title={profile?.name ?? ''}
            media={<Avatar path={profile?.avatar} alt={profile?.name ?? ''} />}
          />
          <Form onSubmit={onSubmitForm}>

            <Input
              autoComplete="off"
              error={isNil(values.password) ? null : errors.password}
              id="password"
              label={t('form.password')}
              name="password"
              onChange={(e) => onChangeField('password', e.currentTarget.value)}
              type="password"
              value={values.password || ''}
            />
            <Input
              autoComplete="off"
              error={isNil(values.passwordConfirmation) ? null : errors.passwordConfirmation}
              id="passwordConfirmation"
              label={t('form.passwordConfirmation')}
              name="passwordConfirmation"
              onChange={(e) => onChangeField('passwordConfirmation', e.currentTarget.value)}
              type="password"
              value={values.passwordConfirmation || ''}
            />

            <Button
              className={styles.submit}
              disabled={!isValid}
              type="submit"
              isBlockAtMobile
            >
              {t('form.btn')}
            </Button>
          </Form>

          <p className={styles['login-back']}>
            <Button
              isLink
              onClick={() => history.push(URLS.login)}
            >
              {t('backToLogin')}
            </Button>
          </p>

        </PageSection>
      </div>
    </PageWrapper>
  );
};

export default ResetPassword;
