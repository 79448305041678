import React from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';
import styles from './Avatar.scss';

const alphabetPosition = (s) => (
  s.toLowerCase().split('')
    .filter((c) => c >= 'a' && c <= 'z')
    .map((c) => c.charCodeAt(0) - 'a'.charCodeAt(0) + 1)
);

const Avatar = ({ alt, className, path }: IAvatarProps) : JSX.Element => {
  const initial = alt.charAt(0);

  return (
    <span
      className={classNames(className, styles.avatar__wrapper)}
      role="img"
    >
      {
        path ? (
          <img
            className={styles.avatar}
            src={path}
            alt={alt}
          />
        ) : (
          <span
            className={styles.initial__wrapper}
            data-avatar-bg-color={alphabetPosition(initial) % 13}
            title={alt}
          >
            <span className={styles.initial}>
              {initial}
            </span>
          </span>
        )
      }
    </span>
  );
};

Avatar.propTypes = {
  alt: propTypes.string.isRequired,
  className: propTypes.string,
  path: propTypes.string,
};

Avatar.defaultProps = {
  className: null,
  path: null,
};

export interface IAvatarProps {
  alt: string,
  className?: string | null,
  path?: string | null,
}

export default Avatar;
