/* eslint-disable react-hooks/exhaustive-deps */

import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { StripeCardElement } from '@stripe/stripe-js';
import { useContext, useEffect, useState } from 'react';

import { IPaymentProps } from 'api/controllers/types';
import { SessionContext } from 'app_state/session/SessionProvider';
import { STRIPE_CUSTOMER_CARD } from 'app_constants/apiErrorCodes';
import api from 'api';
import ApiError from 'classes/errors/ApiError.class';
import useForm, { IErrors } from 'custom_hooks/useForm';
import useFormValidation from 'custom_hooks/useFormValidation';
import useNotification from 'custom_hooks/useNotification';

import { IUseCheckoutForm } from './types';

const useCheckoutForm = (
  onFinish : (() => void) | undefined,
  setError : (string: string) => void,
) : IUseCheckoutForm => {
  const { state } = useContext(SessionContext);
  const { currentUser } = state;
  const { email: stripeEmail } = currentUser || {};
  const { validator } = useFormValidation();
  const { addSuccessNotification, addErrorNotification } = useNotification();
  const [countries, setCountries] = useState([]);
  const [defaultCountryId, setDefaultCountryId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const fetchAllCountries = () => {
    const onSuccess = (response: any) => {
      const { countries: countriesCollection } = response;
      setCountries(countriesCollection.map(({ name, id } : any) => (
        {
          text: name,
          value: `${id}`,
        }
      )));
    };

    api.charges.getAllCountries(null, onSuccess);
  };

  useEffect(() => {
    if (countries) {
      const usaObj:any = countries.find(({ text }) => text === 'United States');
      if (usaObj) setDefaultCountryId(usaObj.value);
    }
  }, [countries]);

  useEffect(() => {
    if (defaultCountryId) {
      // eslint-disable-next-line no-use-before-define
      onChangeField('countryId', defaultCountryId || '');
    }
  }, [defaultCountryId]);

  useEffect(() => {
    fetchAllCountries();
  }, []);

  const sendPayment = (stripeToken: string, card: StripeCardElement, values: IPaymentProps) => {
    const onSuccess = () => {
      setIsLoading(false);
      const label = 'transactionComplete';
      if (card && card.clear) {
        card.clear();
      }
      if (onFinish) {
        onFinish();
      }
      addSuccessNotification(label);
    };

    const onError = (error: ApiError) => {
      setIsLoading(false);
      let label = 'paymentError';
      if (error && error.errorCode === STRIPE_CUSTOMER_CARD) {
        label = error.message;
      }
      addErrorNotification(label);
    };

    const countryId = Number(values?.countryId);
    const postalCode = countryId === Number(defaultCountryId) ? Number(values?.postalCode) : '';
    const { fullName } = values || '';

    api.charges.sendPayment(
      { countryId, fullName, postalCode, stripeToken, stripeEmail }, onSuccess, onError,
    );
  };

  const handleValidation = ({
    countryId,
    fullName,
    postalCode,
  } : IPaymentProps) : IErrors => ({
    countryId: validator.validateRequired(countryId || ''),
    fullName: validator.validateName(fullName || ''),
    postalCode: defaultCountryId === countryId ? validator.validateRequired(postalCode || '') || validator.validateZipCode(postalCode || '') : null,
  });

  const handleSubmit = async (values: IPaymentProps) => {
    if (!stripe || !elements) {
      return;
    }
    const card = elements.getElement(CardElement);
    if (card) {
      const result = await stripe.createToken(card);
      if (result.error) {
        setError(result.error.message || '');
      } else {
        setError('');
        setIsLoading(true);
        sendPayment(result.token.id, card, values);
      }
    }
  };

  const defaultValues = { countryId: defaultCountryId, fullName: null, postalCode: null };

  const {
    errors,
    onChangeField,
    onSubmitForm,
    isValid,
    values = defaultValues,
  } = useForm<IPaymentProps>({
    onSubmit: handleSubmit,
    onValidate: handleValidation,
  });

  return {
    countries,
    defaultCountryId,
    errors,
    isLoading,
    isValid,
    onChangeField,
    onSubmitForm,
    stripe,
    stripeEmail,
    values,
  };
};

export default useCheckoutForm;
