import { faStar } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { sprintf } from 'sprintf-js';
import { useTranslation } from 'react-i18next';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';

import Button from 'generics/Button/Button';
import PageSection from 'generics/PageSection/PageSection';
import PageWrapper from 'generics/PageWrapper/PageWrapper';

import useCorporateAccount from './useCorporateAccount';
import styles from './CorporateAccount.scss';

const CorporateAccount = () : JSX.Element => {
  const { t } = useTranslation(['corporateAccount']);

  const {
    canTakeAssessment,
    userFirstName,
    onLinkProfile,
    onContinue,
  } = useCorporateAccount();

  const pageDescription = canTakeAssessment ? (
    <>
      <p>{t('description1')}</p>
      <p>{t('description2')}</p>
    </>
  ) : <p>{t('description3')}</p>;

  return (
    <PageWrapper>
      <div className={styles.layout}>
        {
          canTakeAssessment && (
            <PageSection>
              <div className={styles.question}>
                <div className={styles['question__media-wrapper']}>
                  <FontAwesomeIcon icon={faStar} />
                </div>
                <span className={styles.question__text}>
                  { ReactHtmlParser(t('question')) }
                </span>
                <Button isSmall className={styles['mobile-button']} onClick={onLinkProfile}>
                  {t('linkProfileBtn')}
                </Button>
              </div>
            </PageSection>
          )
        }
        <PageSection>
          <h2>{sprintf(t('welcome'), userFirstName)}</h2>
          { pageDescription }
          {
            canTakeAssessment && (
              <Button className={styles['desktop-button']} onClick={onLinkProfile}>
                {t('linkProfileBtn')}
              </Button>
            )
          }
          <Button isSecondary onClick={canTakeAssessment ? onContinue : onLinkProfile}>
            {t('continue')}
          </Button>
        </PageSection>
      </div>
    </PageWrapper>
  );
};

export default CorporateAccount;
