import React, { ReactNode, ComponentProps } from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';
import { CHILDREN } from 'app_constants/propTypes';
import styles from './Button.scss';

const defaultButtonType = 'button';

const Button = ({
  children,
  className,
  isBlock,
  isBlockAtMobile,
  isBlockAtTablet,
  isLink,
  isSecondary,
  isSmall,
  isRounded,
  isRoundedSecondary,
  type = defaultButtonType,
  ...rest
}: IButtonProps) : JSX.Element => (
  <button
    className={classNames(
      className,
      styles.button,
      { [styles['button--block']]: isBlock },
      { [styles['button--block@mobile']]: isBlockAtMobile },
      { [styles['button--block@tablet']]: isBlockAtTablet },
      { [styles['button--link']]: isLink },
      { [styles['button--grey']]: isSecondary },
      { [styles['button--small']]: isSmall },
      { [styles['button--rounded']]: isRounded },
      { [styles['button--rounded@grey']]: isRoundedSecondary },
    )}
    // eslint-disable-next-line react/button-has-type
    type={type}
    {...rest}
  >
    {children}
  </button>
);

Button.propTypes = {
  children: CHILDREN,
  className: propTypes.string,
  isBlock: propTypes.bool,
  isBlockAtMobile: propTypes.bool,
  isBlockAtTablet: propTypes.bool,
  isLink: propTypes.bool,
  isSecondary: propTypes.bool,
  isSmall: propTypes.bool,
  isRounded: propTypes.bool,
  isRoundedSecondary: propTypes.bool,
  onClick: propTypes.func,
  type: propTypes.string,
};

Button.defaultProps = {
  children: null,
  className: null,
  isBlock: false,
  isBlockAtMobile: false,
  isBlockAtTablet: false,
  isLink: false,
  isSecondary: false,
  isSmall: false,
  isRounded: false,
  isRoundedSecondary: false,
  onClick: null,
  type: defaultButtonType,
};

interface IButtonProps extends ComponentProps<'button'> {
  children: ReactNode,
  className?: string,
  isBlock?: boolean,
  isBlockAtMobile?: boolean,
  isBlockAtTablet?: boolean,
  isLink?: boolean,
  isSecondary?: boolean,
  isSmall?: boolean,
  isRounded?: boolean,
  isRoundedSecondary?: boolean,
}

export default Button;
