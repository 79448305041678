import { faBirthdayCake, faPlus, IconDefinition } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import propTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import shortid from 'shortid';

import { API_DATE_FORMAT } from 'app_constants/dateFormats';
import { CHILDREN } from 'app_constants/propTypes';
import Avatar from 'generics/Avatar/Avatar';
import Button from 'generics/Button/Button';
import Profile from 'classes/Profile/Profile.class';
import URLS from 'app_constants/urls';

import styles from './Hero.scss';

const Hero = ({ children, isMyProfile, profile }: IHeroProps) : JSX.Element => {
  const history = useHistory();
  const { t } = useTranslation(['profile']);

  const [metaInfo, setMetaInfo] = useState<IMetaInfo | null>(null);

  // set metaInfo
  useEffect(() => {
    const info = profile?.birthday
      ? { icon: faBirthdayCake, label: moment(profile.birthday, API_DATE_FORMAT).utc().format('MMM, D') }
      : null;

    setMetaInfo(info);
  }, [profile]);

  return (
    <div className={styles['profile-hero__wrapper']}>

      <div className={styles['profile-hero']}>
        <h1 className={styles['profile-hero__name']}>
          {profile?.name}
        </h1>
        {metaInfo && (
          <ul className={styles['profile-hero__meta-info__list']}>
            <li key={shortid()}>
              <FontAwesomeIcon icon={metaInfo.icon} />
              <span>{metaInfo.label}</span>
            </li>
          </ul>
        )}
      </div>

      <div className={styles['profile-hero__avatar__stage']}>
        <div className={styles['profile-hero__avatar__wrapper']}>
          <Avatar
            className={styles['profile-hero__avatar']}
            path={profile?.avatar}
            alt={profile?.name || ''}
          />
        </div>
        <div className={styles['profile-hero__actions']}>
          {children}
        </div>
      </div>

      {profile?.bio && (
        <div className={styles['profile-hero__bio__wrapper']}>
          <p className={styles['profile-hero__bio']}>
            {profile.bio}
          </p>
        </div>
      )}

      {isMyProfile && !profile?.bio && (
        <div className={styles['profile-hero__bio__wrapper']}>
          <Button
            isLink
            className={styles['profile-hero__add-bio-button']}
            onClick={() => history.push(URLS.editProfile)}
          >
            <FontAwesomeIcon icon={faPlus} />
            <span className={styles['profile-hero__add-bio-button__label']}>
              {t('hero.addBioLink')}
            </span>
          </Button>
        </div>
      )}

    </div>
  );
};

Hero.propTypes = {
  children: CHILDREN,
  isMyProfile: propTypes.bool,
  profile: propTypes.instanceOf(Profile),
};

Hero.defaultProps = {
  children: null,
  isMyProfile: false,
  profile: null,
};

interface IHeroProps {
  children: React.ReactChild,
  isMyProfile: boolean,
  profile: Profile | null,
}

interface IMetaInfo {
  icon: IconDefinition,
  label: string,
}

export default Hero;
