import {
  IList,
  IAction,
} from 'app_state/types';
import initialState from 'app_state/session/initialState.json';
import sessionActionTypes from 'app_state/session/actionTypes';

const friends = (
  state: IList = initialState.relationships.friends,
  action: IAction,
): IList => {
  switch (action.type) {
    case sessionActionTypes.FETCH_FRIENDS_REQUEST:
      return {
        ...initialState.relationships.friends,
        isLoading: true,
      };

    case sessionActionTypes.FETCH_FRIENDS_SUCCESS:
      return action.payload;

    case sessionActionTypes.RESET_STATE:
    case sessionActionTypes.FETCH_FRIENDS_FAILURE:
      return initialState.relationships.friends;

    default:
      return state;
  }
};

export default friends;
