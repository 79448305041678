import { ajax, AjaxRequest, AjaxResponse } from 'rxjs/ajax';
import { get, isEmpty, merge } from 'lodash';
import { map, mergeMap, retryWhen } from 'rxjs/operators';
import { Observable, range, timer, throwError, zip } from 'rxjs';
import { toCamelCase, toSnakeCase } from 'case-converter';

import { SettingsMethodType } from 'api/controllers/types';
import { ApiError } from 'classes/errors';

import {
  API_NOT_FOUND,
  APPLICATION_AUTHENTICATE_TOKEN,
  INVALID_USER_OR_TOKEN,
  NO_RESULTS,
  SESSION_EXPIRED,
  CAN_NOT_CONNECT,
} from 'app_constants/apiErrorCodes';

import { CONTENT_TYPES, APPLICATION_JSON_REGEX, TEXT_PLAIN_REGEX } from 'app_constants/contentTypes';

import Api5D from './Api.class';
import ApiCrypto from './ApiCrypto.class';

const AJAX_ERROR = 'ajax error';
const MAX_TRIES = 2;
const NOT_FOUND = 404;
const RETRY_TIME = 500;

const {
  API_ENCRYPTION_PASSPHRASE = '',
  API_RESPONSE_HAS_ENCRYPTION,
  API_URL,
} = process.env;

const DEFAULT_CONTENT_TYPE = CONTENT_TYPES.json;
const API_VERSION = 2;

type EncryptSettingsType = {
  forceDefaultPassphrase: boolean,
  useOptionsToken: boolean,
};

type ApiEndpointProps = {
  api: Api5D,
  clearTimeout?: boolean,
  crypto?: ApiCrypto | undefined,
  encrypt?: EncryptSettingsType | undefined,
  ignoreNotFound?: boolean,
  [key: string]: any,
};

type ErrorFnType = (error: typeof ApiError | typeof Error) => (void);

type SuccessFnType = (response: any) => (void);

type APIRequestType = {
  responseType: '*',
  [key: string]: any,
  body: string,
  headers: {
    [key: string]: string,
  },
  url: string,
};

/**
 * @class ApiEndpoint
 * @description Contains functions to send the request and process the response
 */
export default class ApiEndpoint {
  afterResponse?: (response: any, onSuccess?: SuccessFnType) => void;

  api: Api5D;

  clearTimeout: boolean;

  crypto: ApiCrypto;

  encrypt: EncryptSettingsType | undefined;

  forceTimeout: boolean;

  ignoreNotFound: boolean;

  settings: SettingsMethodType

  constructor(
    { api, clearTimeout = false, crypto, encrypt, ignoreNotFound = false, settings, ...controller }
    : ApiEndpointProps,
  ) {
    this.api = api;
    this.clearTimeout = clearTimeout;
    this.encrypt = encrypt;
    this.forceTimeout = false;
    this.settings = settings;
    this.ignoreNotFound = ignoreNotFound;

    merge(this, { ...controller });

    this.crypto = crypto || this.api.crypto;
  }

  /**
   * @function retryWhen
   * @description Sends the request while it fails and the response is not a valid API error
   * @param {number} attempts
   */
  retryWhen(attempts: Observable<any>): Observable<any> {
    return zip(attempts, range(1, MAX_TRIES + 1)).pipe(
      mergeMap(([errorString, i]) => {
        const isAuthenticated = this.api.sessionState?.isAuthenticated;
        const errorResponse = <AjaxResponse> errorString;

        const error = this.decryptResponse(errorResponse);

        if (i > MAX_TRIES) {
          if (DEVELOPMENT) {
            console.log(error);
          }
          return throwError(new ApiError({ errorCode: CAN_NOT_CONNECT }));
        }

        const errorCode = get(error, 'errorCode');

        if (
          isAuthenticated && (
            errorCode === INVALID_USER_OR_TOKEN || errorCode === APPLICATION_AUTHENTICATE_TOKEN
            || (errorResponse.status === 401 && errorResponse.responseType === 'blob')
          )
        ) {
          return throwError(new ApiError({ errorCode: SESSION_EXPIRED }));
        }

        // ignore 404 error (used in empty lists)
        if ((errorCode === API_NOT_FOUND) && this.ignoreNotFound) {
          return throwError(new ApiError({ errorCode: NO_RESULTS }));
        }

        // if there are connection issues, we need to try again
        if ((isEmpty(error) && get(errorResponse, 'message') === AJAX_ERROR)
          || (get(error, '_status') === NOT_FOUND && !errorCode)) {
          return timer(i * RETRY_TIME);
        }

        // is a valid api error, we don't need to try the request again
        return throwError(new ApiError(error));
      }),
    );
  }

  /**
   * @function setTimeout
   * @description sets the expire session timeout
   */
  setTimeout(forceTimeout?: boolean) : void {
    const isAuthenticated = this.api.sessionState?.isAuthenticated;

    if (isAuthenticated || forceTimeout) {
      this.api.setTimeout();
    }
  }

  /**
   * @function request
   * @description Sends a backend request
   * @param {object} options - Request Options
   * @param {function} onSuccess - Success callback function
   * @param {function} onError - Error callback function
   */
  request(
    options: any | null,
    onSuccess?: SuccessFnType,
    onError?: ErrorFnType,
  ) : any {
    const settings = this.settings(options);
    let contentType = {};

    const {
      forceDefaultPassphrase,
      useOptionsToken,
    } = this.encrypt || {};

    const { user } = this.api.sessionState?.currentUser || {};

    const {
      token,
      impersonateToken,
    } = useOptionsToken ? options : (user || {});

    const hasEmptyToken = isEmpty(token) && isEmpty(impersonateToken);

    let Authorization = get(settings.headers, 'Authorization', {});

    if (token) {
      Authorization = `Token token=${token}`;
    } else if (impersonateToken) {
      Authorization = `Token impersonate=${impersonateToken}`;
    }

    if (forceDefaultPassphrase || hasEmptyToken) {
      this.crypto.setPassphrase(API_ENCRYPTION_PASSPHRASE);
    } else {
      this.crypto.setPassphrase(token || impersonateToken);
    }

    let { body } = settings;

    if (body && get(settings.headers, 'enctype') !== CONTENT_TYPES.form) {
      body = JSON.stringify(toSnakeCase(body));
      contentType = {
        'Content-Type': DEFAULT_CONTENT_TYPE,
      };
    }

    const requestSettings = this.encryptRequest({
      responseType: '*',
      ...settings,
      body,
      headers: {
        Accept: `application/json; version=${API_VERSION}`,
        ...(settings.headers || {}),
        Authorization,
        ...contentType,
      },
      url: `${API_URL}${settings.endpoint}`,
    });

    return this.ajax$(requestSettings, onSuccess, onError);
  }

  /**
   * @description Encrypts Request
   * @param {object} settings
   * @returns {object} settings with encrypted body (if it has encryption)
   */
  encryptRequest(settings:APIRequestType): any {
    if (
      API_RESPONSE_HAS_ENCRYPTION && settings.body
      && settings.body.constructor !== FormData && this.crypto
    ) {
      const settingsEncrypt = {
        body: this.crypto.encrypt(settings.body),
        headers: {
          'Content-Type': CONTENT_TYPES.textPlain,
        },
      };

      return merge({}, settings, settingsEncrypt);
    }
    return settings;
  }

  /**
   * @description Decrypt response
   * @param {AjaxResponse} ajaxResponse - Pipe response
   * @throws {Observable}
   * @returns {AjaxResponse} Camel case decrypted response
   */
  decryptResponse(ajaxResponse : AjaxResponse) : any {
    const {
      response,
      status,
      xhr,
    } = ajaxResponse;

    const contentType = xhr.getResponseHeader('Content-Type') || CONTENT_TYPES.json;

    if (response === null) {
      throw new Error('There is not valid response');
    }

    if (TEXT_PLAIN_REGEX.test(contentType) && this.crypto) {
      return {
        ...toCamelCase(this.crypto.decrypt(response)),
        _status: status,
      };
    }
    if (APPLICATION_JSON_REGEX.test(contentType)) {
      let jsonResponse = null;
      try {
        jsonResponse = toCamelCase(JSON.parse(response));
      } catch (error) {
        if (DEVELOPMENT) {
          console.log('Server is returning an invalid JSON');
        }
        jsonResponse = {};
      }
      return {
        ...jsonResponse,
        _status: status,
      };
    }

    return response;
  }

  /**
   * @description Creates an observable for an Ajax request with either a settings object with url,
   * headers, etc or a string for a URL.
   * @param {object} - settings - An object with the request settings.
   * @param {function} onSuccess
   * @param {function} onError
   */
  ajax$(
    settings: AjaxRequest,
    onSuccess: SuccessFnType | undefined,
    onError: ErrorFnType | undefined,
  ) : any {
    return ajax(settings).pipe(
      retryWhen(this.retryWhen.bind(this)),
      map((response) => this.decryptResponse(response)),
    )
      .subscribe((response) => {
        if (this.clearTimeout) {
          this.api.clearTimeout();
        } else {
          this.setTimeout(this.forceTimeout);
        }
        if (this.afterResponse) {
          this.afterResponse(response, onSuccess);
        } else if (onSuccess) {
          onSuccess(response);
        }
      }, (error) => {
        const { errorCode = '' } = error;
        switch (errorCode) {
          case NO_RESULTS:
            this.setTimeout();
            if (onSuccess) {
              onSuccess({});
            }
            break;
          case SESSION_EXPIRED:
            this.api.dispatchExpireSession();
            break;
          default:
            if (onError) {
              onError(error);
            }
        }
      });
  }
}
