import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import propTypes from 'prop-types';
import React, { ComponentProps, MouseEvent } from 'react';

import { ICON } from 'app_constants/propTypes';

import styles from './IconButton.scss';

const defaultTag = 'button';

const IconButton = ({
  className,
  icon,
  onClick,
  tag: Tag = defaultTag,
  ...rest
}: IIconButtonProps) : JSX.Element => (
  <Tag
    className={classNames(className, [styles['icon-button']])}
    onClick={onClick}
    {...(Tag === 'button' && { type: 'button' })}
    {...rest}
  >
    <FontAwesomeIcon icon={icon} />
  </Tag>
);

IconButton.propTypes = {
  className: propTypes.string,
  icon: ICON.isRequired,
  onClick: propTypes.func,
  tag: propTypes.string,
};

IconButton.defaultProps = {
  className: null,
  onClick: null,
  tag: defaultTag,
};

interface IIconButtonProps extends ComponentProps<'button'> {
  className?: string,
  icon: IconDefinition,
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void,
  tag?: keyof JSX.IntrinsicElements,
}

export default IconButton;
