import { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { IContextMenuOptionProps } from 'components/generics/ContextMenu/components/MenuOption/MenuOption';
import { ILinkCorporateAccountProps } from 'api/controllers/types';
import { SESSION_LINK_ACCOUNT_INVALID_USER } from 'app_constants/apiErrorCodes';
import { SessionContext } from 'app_state/session/SessionProvider';
import ApiError from 'classes/errors/ApiError.class';
import fetchLinkCorporate from 'app_state/session/actions/fetchLinkCorporate';
import INVITATION_TYPES from 'app_constants/invitationTypes';
import URLS from 'app_constants/urls';
import useForm, { IErrors } from 'custom_hooks/useForm';
import useFormContextMenu from 'custom_hooks/useFormContextMenu';
import useFormValidation from 'custom_hooks/useFormValidation';
import useNotification from 'custom_hooks/useNotification';

const useLinkCorporateProfile = () : IUseLinkCorporateProfile => {
  const { addErrorNotification, addSuccessNotification } = useNotification();
  const { state: { currentUser }, dispatch } = useContext(SessionContext);
  const { validator } = useFormValidation();
  const history = useHistory();
  const isSSO = currentUser?.user.invitationType === INVITATION_TYPES.personalSSO;

  const handleValidate = (
    { email, password, token }: ILinkCorporateAccountProps,
  ) : IErrors => ({
    email: validator.validateRequired(email ?? '') || validator.validateEmail(email ?? ''),
    password: isSSO ? null : validator.validateRequired(password ?? ''),
    token: isSSO ? validator.validateRequired(`${token}`) : null,
  });

  const handleSubmit = (values: ILinkCorporateAccountProps) => {
    const onSuccess = () => {
      history.push(URLS.home);
      addSuccessNotification('linkCorporateSuccess');
    };

    const onError = (error: ApiError) => {
      let notificationLabel = 'linkCorporateApiError';

      if (error.errorCode === SESSION_LINK_ACCOUNT_INVALID_USER) {
        notificationLabel = isSSO ? 'linkCorporateWrongUserSSO' : 'linkCorporateWrongUserData';
      }

      addErrorNotification(notificationLabel);
    };

    fetchLinkCorporate(dispatch, values, onSuccess, onError);
  };

  const {
    errors,
    isUpdated,
    isValid,
    onChangeField,
    onSubmitForm,
    values = { email: null, password: null, token: null },
  } = useForm<ILinkCorporateAccountProps>({
    onSubmit: handleSubmit, onValidate: handleValidate,
  });

  const contextMenu = useFormContextMenu({ onSubmitForm: () => {
    if (values.email && values.password) {
      handleSubmit(values);
    }
  } });

  const handleGoBack = () => {
    if (isUpdated) {
      contextMenu.onShow(URLS.corporateAccount, isValid);
    } else {
      history.push(URLS.corporateAccount);
    }
  };

  return {
    contextMenu: {
      isVisible: contextMenu.isVisible,
      options: contextMenu.options,
      onClose: contextMenu.onClose,
    },
    form: {
      canSubmit: isValid,
      errors,
      onChangeField,
      onSubmitForm,
      values,
    },
    isSSO,
    onGoBack: handleGoBack,
  };
};

export default useLinkCorporateProfile;

export interface IContextMenu {
  options: IContextMenuOptionProps[],
  isVisible: boolean,
  onClose: () => void,
}

export interface IForm {
  canSubmit: boolean,
  errors: IErrors,
  onChangeField: (name: string, value: string | boolean) => void,
  onSubmitForm: () => void,
  values: ILinkCorporateAccountProps
    | { email: null, password: null, token: null },
}

export interface IUseLinkCorporateProfile {
  contextMenu: IContextMenu,
  form: IForm,
  isSSO: boolean,
  onGoBack: () => void,
}
