enum sessionActionTypes {
  // Fetch Account
  FETCH_ACCOUNT_FAILURE = 'SESSION/FETCH_ACCOUNT_FAILURE',
  FETCH_ACCOUNT_REQUEST = 'SESSION/FETCH_ACCOUNT_REQUEST',
  FETCH_ACCOUNT_SUCCESS = 'SESSION/FETCH_ACCOUNT_SUCCESS',

  // Edit Avatar
  FETCH_UPDATE_AVATAR_FAILURE = 'SESSION/FETCH_UPDATE_AVATAR_FAILURE',
  FETCH_UPDATE_AVATAR_REQUEST = 'SESSION/FETCH_UPDATE_AVATAR_REQUEST',
  FETCH_UPDATE_AVATAR_SUCCESS = 'SESSION/FETCH_UPDATE_AVATAR_SUCCESS',

  // Edit Profile
  FETCH_EDIT_PROFILE_FAILURE = 'SESSION/FETCH_EDIT_PROFILE_FAILURE',
  FETCH_EDIT_PROFILE_REQUEST = 'SESSION/FETCH_EDIT_PROFILE_REQUEST',
  FETCH_EDIT_PROFILE_SUCCESS = 'SESSION/FETCH_EDIT_PROFILE_SUCCESS',

  // Link Corporate
  FETCH_LINK_CORPORATE_FAILURE = 'SESSION/FETCH_LINK_CORPORATE_FAILURE',
  FETCH_LINK_CORPORATE_REQUEST = 'SESSION/FETCH_LINK_CORPORATE_REQUEST',
  FETCH_LINK_CORPORATE_SUCCESS = 'SESSION/FETCH_LINK_CORPORATE_SUCCESS',

  // Login
  FETCH_LOGIN_FAILURE = 'SESSION/FETCH_LOGIN_FAILURE',
  FETCH_LOGIN_REQUEST = 'SESSION/FETCH_LOGIN_REQUEST',
  FETCH_LOGIN_SUCCESS = 'SESSION/FETCH_LOGIN_SUCCESS',

  // logout
  FETCH_LOGOUT_REQUEST = 'SESSION/FETCH_LOGOUT_REQUEST',

  // Save email
  SAVE_EMAIL = 'SESSION/SAVE_EMAIL',

  // Sign Up
  FETCH_SIGN_UP_FAILURE = 'SESSION/FETCH_SIGN_UP_FAILURE',
  FETCH_SIGN_UP_REQUEST = 'SESSION/FETCH_SIGN_UP_REQUEST',
  FETCH_SIGN_UP_SUCCESS = 'SESSION/FETCH_SIGN_UP_SUCCESS',

  // Reset State
  RESET_STATE = 'SESSION/RESET_STATE',

  /* Updates user when something outside the context happens
   * For example when updating assessment results */
  UPDATE_USER = 'SESSION/UPDATE_USER',

  // Friends (My Community)
  FETCH_FRIENDS_FAILURE = 'SESSION/FETCH_FRIENDS_FAILURE',
  FETCH_FRIENDS_REQUEST = 'SESSION/FETCH_FRIENDS_REQUEST',
  FETCH_FRIENDS_SUCCESS = 'SESSION/FETCH_FRIENDS_SUCCESS',

  // Friend Requests (Received)
  FETCH_RECEIVED_FRIEND_REQUESTS_FAILURE = 'SESSION/FETCH_RECEIVED_FRIEND_REQUESTS_FAILURE',
  FETCH_RECEIVED_FRIEND_REQUESTS_REQUEST = 'SESSION/FETCH_RECEIVED_FRIEND_REQUESTS_REQUEST',
  FETCH_RECEIVED_FRIEND_REQUESTS_SUCCESS = 'SESSION/FETCH_RECEIVED_FRIEND_REQUESTS_SUCCESS',

  // Friend Requests (Sent)
  FETCH_SENT_FRIEND_REQUESTS_FAILURE = 'SESSION/FETCH_SENT_FRIEND_REQUESTS_FAILURE',
  FETCH_SENT_FRIEND_REQUESTS_REQUEST = 'SESSION/FETCH_SENT_FRIEND_REQUESTS_REQUEST',
  FETCH_SENT_FRIEND_REQUESTS_SUCCESS = 'SESSION/FETCH_SENT_FRIEND_REQUESTS_SUCCESS',

  // Sent Invitations
  FETCH_SENT_INVITES_FAILURE = 'SESSION/FETCH_SENT_INVITES_FAILURE',
  FETCH_SENT_INVITES_REQUEST = 'SESSION/FETCH_SENT_INVITES_REQUEST',
  FETCH_SENT_INVITES_SUCCESS = 'SESSION/FETCH_SENT_INVITES_SUCCESS',

  // USER CONTENT (Overview, Kits)
  FETCH_CONTENT_FAILURE = 'SESSION/FETCH_CONTENT_FAILURE',
  FETCH_CONTENT_REQUEST = 'SESSION/FETCH_CONTENT_REQUEST',
  FETCH_CONTENT_SUCCESS = 'SESSION/FETCH_CONTENT_SUCCESS',
}

export default sessionActionTypes;
