/**
 * @description API controllers
 */
import accounts from './accounts.controller';
import charges from './charges.controller';
import connectionRequests from './connectionRequests.controller';
import contentManager from './contentManager.controller';
import invites from './invites.controller';
import pdfs from './pdfs.controller';
import profiles from './profiles.controller';
import session from './session.controller';
import tips from './tips.controller';
import tokens from './tokens.controller';
import users from './users.controller';

export default {
  accounts,
  charges,
  connectionRequests,
  contentManager,
  invites,
  pdfs,
  profiles,
  session,
  tips,
  tokens,
  users,
};
