import { faUser, faUserFriends, faHandsHeart, faRingsWedding } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import classNames from 'classnames';
import propTypes from 'prop-types';
import React from 'react';

import { keyType, iconType } from 'app_constants/adviceDesigns';
import { ICON } from 'app_constants/propTypes';

import styles from './AdviceIcon.scss';

library.add(faUser, faUserFriends, faHandsHeart, faRingsWedding);

const AdviceIcon = ({ adviceCategory, className, icon }: IAdviceIconProps) : JSX.Element => (
  <span className={classNames(styles['advice-icon'], styles[adviceCategory], className)}>
    <FontAwesomeIcon icon={icon} />
  </span>
);

AdviceIcon.propTypes = {
  adviceCategory: propTypes.string.isRequired,
  className: propTypes.string,
  icon: ICON.isRequired,
};

AdviceIcon.defaultProps = {
  className: null,
};

interface IAdviceIconProps {
  adviceCategory: keyType,
  className?: string,
  icon: iconType,
}

export default AdviceIcon;
