import React, { ComponentProps } from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';
import styles from './ToggleSwitch.scss';

const defaultToggleSwitchType = 'checkbox';

const ToggleSwitch = ({
  label,
  id,
  type = defaultToggleSwitchType,
  ...rest
}: IToggleSwitchProps) : JSX.Element => (
  <div className={classNames(styles['toggle-switch__wrapper'])}>
    <label htmlFor={id} className={classNames(styles['toggle-switch__container'])}>
      <input {...rest} id={id} type={type} className={classNames(styles['toggle-switch__input'])} />
      <span className={classNames(styles['toggle-switch__slider'])} />
    </label>

    <label
      className={styles['toggle-switch__label']}
      htmlFor={id}
    >
      {label}
    </label>
  </div>
);

ToggleSwitch.propTypes = {
  label: propTypes.string.isRequired,
  id: propTypes.string.isRequired,
  type: defaultToggleSwitchType,
};

ToggleSwitch.defaultProps = {
  type: defaultToggleSwitchType,
};

interface IToggleSwitchProps extends ComponentProps<'input'> {
  label: string
}

export default ToggleSwitch;
