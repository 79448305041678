import React, { useEffect, Dispatch, FC } from 'react';

import { IInvitesInitialState } from 'app_state/types';

import initialState from './initialState.json';
import reducers from './reducers';

interface IInvitesContext {
  state: IInvitesInitialState,
  dispatch: Dispatch<any>,
}

const invitesJSON = localStorage.getItem('invites');
const localState = invitesJSON && invitesJSON !== 'undefined' ? JSON.parse(invitesJSON) : null;

export const InvitesContext = React.createContext<IInvitesContext>(
  { state: initialState, dispatch: () => null },
);

export const InvitesProvider: FC = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducers, localState || initialState);

  useEffect(() => {
    localStorage.setItem('invites', JSON.stringify(state));
  }, [state]);

  return (
    <InvitesContext.Provider value={{ state, dispatch }}>
      {children}
    </InvitesContext.Provider>
  );
};
