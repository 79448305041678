import { chain, indexOf, map, mapValues, find, Dictionary } from 'lodash';

import { IEnergy } from 'app_constants/energies';
import d3 from 'vendor/d3';

import { IBandSide, IGeometry, IScales } from '../types';

const ENERGY_THRESHOLDS_RANGES = {
  Reserved: {
    balloonSize: 0,
    max: 1,
    min: 0,
  },
  Deliberate: {
    balloonSize: 1.5,
    max: 4,
    min: 2,
  },
  'Deliberate+': {
    balloonSize: 1.5,
    max: 8,
    min: 5,
  },
  Effortless: {
    balloonSize: 7,
    max: 11,
    min: 9,
  },
  'Effortless+': {
    balloonSize: 7,
    max: 13,
    min: 12,
  },
  Abundant: {
    balloonSize: 13,
    max: 15,
    min: 14,
  },
};

const THRESHOLDS_LABELS = {
  label: [
    'Reserved', // 0-1 = Reserved
    'Deliberate', // 2-4 = Deliberate
    'Deliberate+', // 5-8 = Deliberate+
    'Effortless', // 9-11 = Effortless
    'Effortless+', // 12-13 = Effortless+
    'Abundant', // 14-15 = Reserved
  ],
  labelAbbreviation: [
    'R', // 0-1 = Reserved
    'D', // 2-4 = Deliberate
    'D+', // 5-8 = Deliberate+
    'E', // 9-11 = Effortless
    'E+', // 12-13 = Effortless+
    'A', // 14-15 = Reserved
  ],
};

const getScales = (energies: IEnergy[], geometry: IGeometry) : any => {
  const scales: IScales = {};
  const energyThresholds = mapValues(ENERGY_THRESHOLDS_RANGES, 'min');

  // balloons

  const energyThresholdsPairs = chain<Dictionary<number>>(energyThresholds)
    .omitBy((value, key) => key.endsWith('+'))
    .toPairs()
    .sortBy((pair) => indexOf(energies.map((energy) => energy.text), pair[0]))
    .value();

  const thresholds = map(energyThresholdsPairs, (pair) => pair[1] || 0);

  const getBandSideRatio = (x: keyof IBandSide) => geometry.bandSideRatios[x];

  const sides = map(
    energyThresholdsPairs, (pair) => getBandSideRatio(<keyof IBandSide>pair[0])
      * geometry.maxBalloonSide,
  );

  scales.getBalloonSize = (value: number) => find(
    ENERGY_THRESHOLDS_RANGES,
    ({ max, min }: { max: number, min: number }) => value >= min && value <= max,
  )?.balloonSize;

  scales.balloonDiameter = d3.scaleLinear<number, number>()
    .domain(thresholds)
    .range(sides);

  scales.energyRangeLabel = d3.scaleThreshold<number, string>()
    .domain([0, 2, 5, 9, 12, 14])
    .range(THRESHOLDS_LABELS.label);

  return scales;
};

export default getScales;
