import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import propTypes from 'prop-types';
import React, { ReactNode } from 'react';

import { CHILDREN } from 'app_constants/propTypes';

import styles from './Modal.scss';

const Modal = ({
  children, isSmall, isVisible, onClose, fullCover, shouldCloseOnEnterKey,
}: IModalProps) : JSX.Element => {
  const { t } = useTranslation('generics');

  return (
    <div
      className={classNames(
        styles.modal__platform,
        { [styles['is-visible']]: isVisible },
        { [styles['is-small']]: isSmall },
        { [styles['full-cover']]: fullCover },
      )}
      tabIndex={-1}
      onClick={({ currentTarget, target }) => (currentTarget === target) && onClose()}
      onKeyDown={({ keyCode }) => (keyCode === 13 && shouldCloseOnEnterKey) && onClose()}
      role="button"
    >
      <div
        className={classNames(
          styles.modal__stage,
          { [styles['is-small']]: isSmall },
        )}
        role="dialog"
      >
        <div className={styles.modal__wrapper}>
          <button
            onClick={onClose}
            className={styles['modal__close-button']}
            type="button"
            title={t('close')}
          >
            <FontAwesomeIcon icon={faTimes} size="lg" />
          </button>
          <div className={styles.modal}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  children: CHILDREN.isRequired,
  fullCover: propTypes.bool,
  isSmall: propTypes.bool,
  isVisible: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
  shouldCloseOnEnterKey: propTypes.bool,
};

Modal.defaultProps = {
  fullCover: false,
  isSmall: false,
  shouldCloseOnEnterKey: true,
};

interface IModalProps {
  children: ReactNode,
  fullCover: boolean,
  isSmall?: boolean,
  isVisible: boolean,
  onClose: () => void,
  shouldCloseOnEnterKey: boolean
}

export default Modal;
