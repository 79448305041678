import { faCircleNotch } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';

import styles from './LoadingAnimation.scss';

const LoadingAnimation = ({ isLight = false } : ILoadingAnimation) : JSX.Element => (
  <div
    className={classNames(
      [styles['loading-animation']],
      { [styles['is-light']]: isLight },
    )}
  >
    <FontAwesomeIcon icon={faCircleNotch} size={!isLight ? '2x' : '3x'} fixedWidth spin />
  </div>
);

export default LoadingAnimation;

export interface ILoadingAnimation {
  isLight: boolean,
}
