import moment from 'moment';

import Answer from 'classes/assessment/Answer.class';

import {
  API_DATE_FORMAT,
  APP_DATE_FORMAT,
} from 'app_constants/dateFormats';

import {
  IChangePasswordProps,
  IResetPasswordProps,
  ISettingsResult,
  ISignUpProps,
} from './types';

/**
 * @description Settings for controller
 */
export default {
  changePassword: {
    /**
     * @description Get request settings (Update Password)
     * @param  {object} options
     * @param  {string} options.currentPassword
     * @param  {string} options.password - new password
     * @param  {string} options.passwordConfirmation - new password confirmation
     * @return {ISettingsResult} Request Settings
     */
    settings(props: IChangePasswordProps) : ISettingsResult {
      const {
        currentPassword,
        password,
        passwordConfirmation,
      } = props;

      return {
        endpoint: '/personal/users/update_password',
        method: 'PATCH',
        body: {
          currentPassword,
          user: {
            password,
            passwordConfirmation,
          },
        },
      };
    },
  },

  getAssessment: {
    /**
     * @description Gets request settings (Get Assessment)
     * @param {Boolean} isAssessmentInProgress - true if the assessment is in progress.
     * @returns {Object} Request settings.
     */
    settings(isAssessmentInProgress: boolean) : ISettingsResult {
      return {
        endpoint: '/personal/users/assessment',
        method: isAssessmentInProgress ? 'GET' : 'POST',
      };
    },
  },

  updateAssessment: {
    /**
     * @description Get request settings (update assessment)
     * @param {Object} questions - Question Answers.
     * @returns {Object} Request settings.
     */
    settings(questions: Answer[]) : ISettingsResult {
      return {
        body: { questions },
        endpoint: '/personal/users/assessment/questions',
        method: 'POST',
      };
    },
  },

  signUp: {
    /**
     * @description Get request settings (Login)
     * @param  {object} options
     * @param  {boolean} options.agreedToTerms - AgreedToTerms
     * @param  {string} options.birthday - Birthday
     * @param  {string} options.email - Email
     * @param  {string} options.firstName - First Name
     * @param  {string} options.genderPronounPreference - Email
     * @param  {string} options.invitationToken - Token for the Invitation
     * @param  {string} options.lastName - Last Name
     * @param  {string} options.password - Password
     * @param  {string} options.passwordConfirmation - Password Confirmation
     * @return {ISettingsResult} Request Settings
     */
    settings({
      agreedToTerms: agreedToTermsOfService,
      birthday,
      password,
      passwordConfirmation,
      invitationToken,
      ...rest
    }: ISignUpProps) : ISettingsResult {
      return {
        endpoint: '/personal/users/create_personal',
        method: 'POST',
        body: {
          user: {
            invitationToken,
            password,
            passwordConfirmation,
            profileAttributes: {
              agreedToTermsOfService,
              birthday: birthday
                ? moment(birthday, APP_DATE_FORMAT).utc().format(API_DATE_FORMAT) : null,
              ...rest,
            },
          },
        },
      };
    },
  },
  forgotPassword: {
    /**
     * @description Get request settings (Forgot Password)
     * @param  {string} email
     * @return {ISettingsResult} Request Settings
     */
    settings(email: string) : ISettingsResult {
      return {
        endpoint: '/personal/users/forgot_password',
        method: 'POST',
        body: { email },
      };
    },
  },

  getForgotPasswordInfo: {
    /**
     * @description Get request settings (Get forgot password info)
     * @param  {string} token
     * @return {ISettingsResult} Request Settings
     */
    settings(token: string) : ISettingsResult {
      return {
        endpoint: `/personal/users/forgot_password_user_info/${token}`,
        method: 'GET',
      };
    },
  },

  resetPassword: {
    /**
     * @description Get request settings (Reset password)
     * @param  {string} token
     * @return {ISettingsResult} Request Settings
     */
    settings(options: IResetPasswordProps) : ISettingsResult {
      const {
        token,
        password,
        passwordConfirmation,
      } = options;

      return {
        endpoint: '/personal/users/reset_password',
        method: 'PUT',
        body: {
          resetPasswordToken: token,
          user: {
            password,
            passwordConfirmation,
          },
        },
      };
    },
  },
};
