import React, { useMemo, Dispatch, FC } from 'react';

import { ISessionAction, ISessionInitialState } from 'app_state/types';
import api from 'api';
import Kit from 'classes/Kit/Kit.class';
import Profile from 'classes/Profile/Profile.class';

import initialState from './initialState.json';
import reducers from './reducers';

interface ISessionContext {
  state: ISessionInitialState,
  dispatch: Dispatch<ISessionAction>,
}

/**
 * @description Converts some objects to class instances
 * @param  {key} object key
 * @param  {any} value
 * @return {any} Result (value or instances)
 */
const reviver = (key: string, value: any) => {
  switch (key) {
    case 'currentUser':
      return new Profile(value);
    case 'kits':
      return value?.map((kit: any) => new Kit(kit));
    default:
      return value;
  }
};

const sessionJSON = localStorage.getItem('session');
const localState = sessionJSON && sessionJSON !== 'undefined' ? JSON.parse(sessionJSON, reviver) : null;

export const SessionContext = React.createContext<ISessionContext>(
  { state: initialState, dispatch: () => null },
);

export const SessionProvider: FC = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducers, localState || initialState);

  useMemo(() => {
    localStorage.setItem('session', JSON.stringify(state));
    api.setSessionContext(state, dispatch);
  }, [state]);

  return (
    <SessionContext.Provider value={{ state, dispatch }}>
      {children}
    </SessionContext.Provider>
  );
};
