import { useContext } from 'react';

import { addNotification, clearNotification } from 'app_state/notifications/actions';
import { NotificationsContext } from 'app_state/notifications/NotificationsProvider';
import Notification from 'classes/Notification/Notification.class';
import notificationTypes from 'app_constants/notificationTypes';

const NOTIFICATION_TIME = 8000;

const useNotification = () : IUseNotification => {
  const { dispatch: notificationDispatch } = useContext(NotificationsContext);

  const handleNotification = (
    type: notificationTypes,
    label: string,
    ...params: string[]
  ) : void => {
    const timeout = setTimeout(() => {
      notificationDispatch(clearNotification());
    }, NOTIFICATION_TIME);

    const notification = new Notification({ label, timeout, type, params });

    notificationDispatch(addNotification(notification));
  };

  return {
    addErrorNotification: (label, ...rest) => handleNotification(
      notificationTypes.error,
      label,
      ...rest,
    ),
    addSuccessNotification: (label, ...rest) => handleNotification(
      notificationTypes.success,
      label,
      ...rest,
    ),
    addWarningNotification: (label, ...rest) => handleNotification(
      notificationTypes.warning,
      label,
      ...rest,
    ),
    clearNotification: () => notificationDispatch(clearNotification()),
  };
};

export interface IUseNotification {
  addErrorNotification: NotificationFunctionType,
  addSuccessNotification: NotificationFunctionType,
  addWarningNotification: NotificationFunctionType,
  clearNotification: () => void,
}

type NotificationFunctionType = (label: string, ...rest: string[]) => void;

export default useNotification;
