import {
  IInvitationProps,
  IProfilesProps,
  ISendInvitationProps,
  ISettingsResult,
  ISignUpInvitationProps,
} from './types';

/**
 * @description Settings for controller
 */
export default {
  getInvitation: {
    /**
     * @description Get request settings (Get Invitation)
     * @param  {object} options
     * @param  {string} options.token - Invitation Token
     * @return {object} Request Settings
     */
    settings({ token }: IInvitationProps) : ISettingsResult {
      return {
        endpoint: `/personal/invites/${token}/information`,
        method: 'GET',
      };
    },
  },

  declineInvitation: {
    /**
     * @description Get request settings (Decline Invitation)
     * @param  {object} options
     * @param  {string} options.token - Invitation Token
     * @return {object} Request Settings
     */
    settings({ token }: IInvitationProps) : ISettingsResult {
      return {
        endpoint: `/personal/invites/${token}/decline`,
        method: 'POST',
      };
    },
  },

  sendInvitation: {
    /**
     * @description Get request settings (Send Manual Invitations)
     * @param  {object} options
     * @param  {string} options.firstName - Invited Person first name
     * @param  {string} options.lastName - Invited Person last name
     * @param  {string} options.email - Invitation Email
     * @return {object} Request Settings
     */
    settings({ firstName, lastName, email }: ISendInvitationProps) : ISettingsResult {
      return {
        endpoint: '/personal/invites/manual_invitation',
        body: {
          inviteMember: {
            email,
            firstName,
            lastName,
          },
        },
        method: 'POST',
      };
    },
  },

  signUpInvitation: {
    /**
     * @description Get request settings (Sign Up Invitations)
     * @param  {object} options
     * @param  {string} options.firstName - Invited Person first name
     * @param  {string} options.lastName - Invited Person last name
     * @param  {string} options.email - Invitation Email
     * @return {object} Request Settings
     */
    settings({ firstName, lastName, email }: ISignUpInvitationProps) : ISettingsResult {
      return {
        endpoint: '/personal/invites/signup_personal_invitation',
        body: {
          inviteMember: {
            email,
            firstName,
            lastName,
          },
        },
        method: 'POST',
      };
    },
  },

  getSentInvites: {
    ignoreNotFound: true,
    /**
     * @description Get Sent Invites
     * @param  {object} options
     * @param  {string} options.page - Results page
     * @return {object} Request Settings
     */
    settings(props?: IProfilesProps) : ISettingsResult {
      const { page } = props ?? {};
      let endpoint = '/personal/invites/sent_invites';

      if (page) {
        endpoint = `${endpoint}/page/${page}`;
      }

      return {
        endpoint,
        method: 'GET',
      };
    },
  },

  deleteInvitation: {
    /**
     * @description Get request settings (Delete Sent Invitation)
     * @param  {number} id - Invitation ID
     * @return {object} Request Settings
     */
    settings(id: number) : ISettingsResult {
      return {
        endpoint: `/personal/invites/invalidate/${id}`,
        method: 'PATCH',
      };
    },
  },
};
