export default class Answer {
  duration?: number;

  id: number;

  selectedWordPairId?: number;

  constructor({ duration, id, selectedWordPairId } : IAnswer) {
    this.duration = duration;
    this.id = id;
    this.selectedWordPairId = selectedWordPairId;
  }
}

export interface IAnswer {
  duration: number,
  id: number,
  selectedWordPairId: number,
}
