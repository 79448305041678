/* eslint-disable global-require */
switch (process.env.NODE_ENV) {
  case 'production':
    module.exports = require('./index.prod');
    break;

  case 'development':
  default:
    module.exports = require('./index.dev');
    break;
}

export default {};
