import { faQuestionSquare } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe'
import DatePickerComponent from 'react-datepicker';
import MaskedInput from 'react-text-mask';
import moment from 'moment';
import propTypes from 'prop-types';
import React from 'react';

import { APP_DATE_FORMAT, APP_DATE_FORMAT_INPUT_MASK } from 'app_constants/dateFormats';
import Tooltip from 'generics/Tooltip/Tooltip';

import styles from './DatePicker.scss';

const DatePicker = ({
  error,
  id,
  label,
  onChange,
  placeholder,
  tooltip,
  value,
}: IDatePickerProps) : JSX.Element => {
  const { t } = useTranslation(['formValidation']);

  const handleChange = (date: Date) => {
    const newDate = moment(date);
    onChange(newDate.isValid() ? newDate.format(APP_DATE_FORMAT) : null);
  };

  const dateObj = moment(value, APP_DATE_FORMAT);

  return (
    <>
      <div
        className={classNames(
          styles.datePicker__wrapper,
          { [styles['has-error']]: !!error },
        )}
      >
        <DatePickerComponent
          className={styles.datePicker__input}
          dateFormat="MM/dd/yyyy"
          onChange={handleChange}
          onSelect={handleChange}
          placeholderText={placeholder || ''}
          selected={dateObj.isValid() ? dateObj.toDate() : null}
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          customInput={(
            <MaskedInput
              pipe={createAutoCorrectedDatePipe('MM/dd/yyyy')}
              mask={APP_DATE_FORMAT_INPUT_MASK}
              keepCharPositions
              guide
            />
          )}
        />

        <label
          className={styles.datePicker__label}
          htmlFor={id}
        >
          {label}
        </label>

        {tooltip && (
          <Tooltip
            className={styles.datePicker__tooltip}
            tooltip={t('formValidation:restrictedAge')}
            alignment="right"
          >
            <FontAwesomeIcon icon={faQuestionSquare} size="lg" />
          </Tooltip>
        )}

      </div>
      {error && (
        <span className="error-text">
          {error}
        </span>
      )}
    </>
  );
};

DatePicker.propTypes = {
  error: propTypes.string,
  id: propTypes.string.isRequired,
  label: propTypes.string.isRequired,
  onChange: propTypes.func.isRequired,
  placeholder: propTypes.string,
  tooltip: propTypes.string,
};

DatePicker.defaultProps = {
  error: null,
  placeholder: APP_DATE_FORMAT,
  tooltip: null,
};

interface ICustomInputProps {
  value: string,
  onClick: (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => void,
}

interface IDatePickerProps {
  error?: string | null,
  id: string,
  label: string,
  onChange: (date: string | null) => void,
  placeholder?: string | null,
  tooltip?: string | null,
  value: string | null,
}

export default DatePicker;
