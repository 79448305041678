/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, RefObject, useState, useEffect } from 'react';

import d3 from 'vendor/d3';
import getCSSFromSVG from 'utils/getCSSFromSVG';
import Profile from 'classes/Profile/Profile.class';

import { IElements, IGeometry, IScales } from './types';
import initGraph from './helpers/initGraph';
import styles from './EnergyMap.scss';
import draw from './helpers/draw';

const useEnergyMap = (profile: Profile) : IUseEnergyMap => {
  const [elements, setElements] = useState<IElements>({});
  const [geometry, setGeometry] = useState<IGeometry | null>(null);
  const [scales, setScales] = useState<IScales | null>(null);

  // References
  const nodeElementRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const svgRef = useRef<SVGSVGElement>(null);
  const mainGRef = useRef<SVGGElement>(null);
  const referencesRef = useRef<SVGGElement>(null);
  const profileRef = useRef<SVGGElement>(null);
  const textRef = useRef<SVGGElement>(null);

  useEffect(() => {
    if (geometry && scales) {
      draw({ geometry, styles, elements, profile, scales });
    }
  }, [scales]);

  useEffect(() => {
    const baseElements = {
      canvas: d3.select(canvasRef?.current),
      mainG: d3.select(mainGRef?.current),
      profile: d3.select(profileRef?.current),
      text: d3.select(textRef?.current),
      references: d3.select(referencesRef?.current),
      svg: d3.select(svgRef?.current),
    };

    const newValues = initGraph(profile.energies, baseElements);
    setElements(newValues.elements);
    setGeometry(newValues.geometry);
    setScales(newValues.scales);
  }, [profile]);

  const outerHTML = (): string => {
    if (elements.svg?.outerHTML) {
      return elements.svg.outerHTML;
    }

    if (svgRef.current) {
      const divElement = document.createElement('div');
      const svgCloneElement = svgRef.current.cloneNode(true);
      divElement.appendChild(svgCloneElement);
      return divElement.innerHTML;
    }

    return '';
  };

  return {
    nodeElementRef,
    canvasRef,
    svgRef,
    mainGRef,
    referencesRef,
    profileRef,
    textRef,
    outerHTML,
    getCSSFromSVG: (): string => (svgRef?.current ? getCSSFromSVG(svgRef.current) : ''),
  };
};

export interface IUseEnergyMap {
  nodeElementRef: RefObject<HTMLDivElement>,
  canvasRef: RefObject<HTMLCanvasElement>,
  svgRef: RefObject<SVGSVGElement>,
  mainGRef: RefObject<SVGGElement>,
  referencesRef: RefObject<SVGGElement>,
  profileRef: RefObject<SVGGElement>,
  textRef: RefObject<SVGGElement>,
  outerHTML: () => string,
  getCSSFromSVG: () => string,
}

export default useEnergyMap;
