/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React from 'react';

import Button from 'generics/Button/Button';
import LoadingAnimation from 'generics/LoadingAnimation/LoadingAnimation';
import PageSection from 'generics/PageSection/PageSection';
import PageTitle from 'generics/PageTitle/PageTitle';
import PageWrapper from 'generics/PageWrapper/PageWrapper';
import URLS from 'app_constants/urls';

import useShareReport from './useShareReport';
import styles from './ShareReport.scss';

const ShareReportPage = () : JSX.Element => {
  const { t } = useTranslation('shareReport');
  const history = useHistory();

  const {
    externalShareReportRequests,
    handleShareRequest,
    isLoading,
  } = useShareReport();

  const renderExternalShareReportRequests = () => (externalShareReportRequests?.length ? (
    <>
      <li>
        <p>
          <strong>
            Organization
          </strong>
        </p>
        <p>
          <strong>
            Report Code
          </strong>
        </p>
        <p>
          <strong>
            Organization Owner
          </strong>
        </p>
      </li>
      {
        externalShareReportRequests?.map((
          { code, organizationName, organizationOwner }:any,
        ) => (
          <li>
            <p>{organizationName}</p>
            <p>{code}</p>
            <p>{organizationOwner}</p>
            <Button
              onClick={() => handleShareRequest(code)}
            >
              {t('form.btn')}
            </Button>
          </li>
        ))
      }
    </>
  ) : (
    <p>No requests found</p>
  ));

  return (
    <>
      <PageWrapper>
        {
          isLoading
            ? (
              <PageSection>
                <LoadingAnimation />
              </PageSection>
            )
            : (
              <div className={styles.layout}>
                <PageSection>
                  <PageTitle
                    title={t('title')}
                    onBackButtonClick={() => history.push(URLS.home)}
                  />
                </PageSection>
                <PageSection className={styles['main-content']}>
                  <p className={styles.terms}>{t('terms')}</p>
                  <ul className={styles.list}>
                    {renderExternalShareReportRequests()}
                  </ul>
                </PageSection>
              </div>
            )
        }
      </PageWrapper>
    </>
  );
};

export default ShareReportPage;
