export const API_NOT_FOUND = 477;
export const APPLICATION_AUTHENTICATE_TOKEN = 470;
export const CAN_NOT_CONNECT = 1000;
export const FRIEND_REQUEST_ALREADY_EXISTS = 608;
export const DELETE_ACCOUNT_CURRENT_PASSWORD = 604;
export const INVALID_USER_OR_TOKEN = 430;
export const NO_RESULTS = 1001;
export const PROFILE_EDIT_DUPLICATED_EMAIL = 494;
export const SESSION_EXPIRED = 1002;
export const SESSION_LINK_ACCOUNT_INVALID_USER = 489;
export const SIGN_UP_INVITATION_FORMAT = 612;
export const STRIPE_CUSTOMER_CARD = 610;
export const USERS_ACCOUNT_BLOCKED = 607;
export const USER_ALREADY_TOOK_ASSESSMENT = 491;
export const USERS_CREATE_DUPLICATED_EMAIL = 490;
export const USERS_UPDATE_PASSWORD_INVALID = 496;
