import { formatRoute } from 'react-router-named-routes';
import { useTranslation } from 'react-i18next';
import React from 'react';

import Avatar from 'generics/Avatar/Avatar';
import Button from 'generics/Button/Button';
import Card from 'generics/Card/Card';
import CardsList from 'generics/CardsList/CardsList';
import Pagination from 'generics/Pagination/Pagination';
import Profile from 'classes/Profile/Profile.class';
import URLS from 'app_constants/urls';

import useFriendRequests from './useFriendRequests';
import styles from '../../Home.scss';

const ReceivedFriendRequests = () : JSX.Element => {
  const { t } = useTranslation('home');
  const {
    receivedFriendRequests,
    onAcceptFriendRequest,
    onDeclineFriendRequest,
  } = useFriendRequests();

  const { results } = receivedFriendRequests;

  const cards = results.map(({ id: requestId, connectionRequestEmisor }) => {
    const { name, avatar, id } = new Profile({ ...connectionRequestEmisor });

    return (
      <Card
        route={formatRoute(URLS.profile, { id })}
        title={name}
        subtitle={t('friendRequestSubtitle')}
        media={<Avatar path={avatar} alt={name} />}
        showBeachBall
      >
        <Button
          className={styles['relationship-cards__button']}
          isSmall
          onClick={() => onAcceptFriendRequest(requestId)}
        >
          {t('addFriend')}
        </Button>
        <Button
          className={styles['relationship-cards__button']}
          isSecondary
          isSmall
          onClick={() => onDeclineFriendRequest(requestId)}
        >
          {t('remove')}
        </Button>
      </Card>
    );
  });

  return (
    <>
      <CardsList
        className={styles['relationship-cards']}
        cards={cards}
      />
      <Pagination
        isVisible={receivedFriendRequests.areMorePages}
        onClick={receivedFriendRequests.onGetMoreResults}
      />
    </>
  );
};

export default ReceivedFriendRequests;
