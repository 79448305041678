import { useTranslation } from 'react-i18next';
import FormValidator from 'classes/FormValidator/FormValidator.class';

/**
 * @description custom hook to add form validations
 */
const useFormValidation = () : IUseFormValidation => {
  const { t } = useTranslation(['formValidation']);
  return {
    validator: new FormValidator(t),
  };
};

interface IUseFormValidation {
  validator: FormValidator,
}

export default useFormValidation;
