import { faUserPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatRoute } from 'react-router-named-routes';
import { useTranslation } from 'react-i18next';
import React, { useContext } from 'react';

import { SessionContext } from 'app_state/session/SessionProvider';
import Avatar from 'generics/Avatar/Avatar';
import Card from 'generics/Card/Card';
import CardsList from 'generics/CardsList/CardsList';
import Eyebrow from 'generics/Eyebrow/Eyebrow';
import KitList from 'components/KitList/List';
import LoadingAnimation from 'generics/LoadingAnimation/LoadingAnimation';
import PageSection from 'generics/PageSection/PageSection';
import PageWrapper from 'generics/PageWrapper/PageWrapper';
import URLS from 'app_constants/urls';

import Friends from './components/Friends/Friends';
import ReceivedFriendRequests from './components/FriendRequests/ReceivedFriendRequests';
import SentFriendRequests from './components/FriendRequests/SentFriendRequests';
import SentInvites from './components/SentInvites/SentInvites';
import useHome from './useHome';
import styles from './Home.scss';

const Home = () : JSX.Element => {
  const { t } = useTranslation('home');
  const { state } = useContext(SessionContext);
  const { currentUser } = state;

  const {
    isLoading,
    isListEmpty,
  } = useHome();

  const relationships = isListEmpty ? (
    <p className={styles['relationship-cards__empty']}>
      {t('relationshipsEmpty')}
    </p>
  ) : (
    <>
      <Friends />
      <ReceivedFriendRequests />
      <SentFriendRequests />
      <SentInvites />
    </>
  );

  return (
    <>
      <PageWrapper>
        <div className={styles.layout}>
          <PageSection>

            <CardsList
              cards={[
                <Card
                  assessmentScores={currentUser?.assessmentScores}
                  media={<Avatar path={currentUser?.avatar} alt={`${currentUser?.name}`} />}
                  route={formatRoute(URLS.profile, { id: currentUser?.id })}
                  showBeachBall
                  subtitle={t('seeMyProfile')}
                  title={currentUser?.name || ''}
                />,
              ]}
            />

          </PageSection>
          <div className={styles.layout__kits__wrapper}>
            <PageSection className={styles.layout__kits}>

              <Eyebrow>
                {t('kits')}
              </Eyebrow>
              <KitList customPayment customs customShareReport />

            </PageSection>
          </div>
          <PageSection>

            <Eyebrow>
              {t('relationships')}
            </Eyebrow>
            <div className={styles['relationship-cards__wrapper']}>
              {
                isLoading ? (
                  <div className={styles['layout__loading-animation']}>
                    <LoadingAnimation />
                  </div>
                ) : relationships
              }
            </div>
          </PageSection>
          <PageSection>

            <CardsList
              cards={[
                <Card
                  route={URLS.sendInvitation}
                  title={t('inviteFriends')}
                  media={<FontAwesomeIcon icon={faUserPlus} />}
                />,
              ]}
            />

          </PageSection>
        </div>
      </PageWrapper>
    </>
  );
};

export default Home;
