import { FixedSizeArray } from 'fixed-size-array';
import { isNil, mapKeys, merge, zipWith } from 'lodash';
import { scaleThreshold } from 'd3-scale';

import DYNAMICS_LABELS from 'app_constants/dynamicLabels';
import ENERGIES, { IEnergy } from 'app_constants/energies';
import INVITATION_TYPES from 'app_constants/invitationTypes';

import {
  assessmentScoresType,
  IAccount,
  IAssessmentScore,
  IAvatar,
  IScore,
  IUser,
} from './types';

function toString(this: number[] | string[]) : string {
  if (this.length > 0) {
    return `(${this.join(', ')})`;
  }
  return '(-, -, -, -)';
}

const hasScores = (assessmentScores: assessmentScoresType) : boolean => assessmentScores
  && !assessmentScores.every((score: number | null) => score === null);

/**
 * Profile
 * @class
 */
class Profile {
  _avatar: IAvatar;

  _dynamics: string[];

  _energies: IEnergy[];

  account: IAccount;

  bio: string | null;

  birthday: string | null;

  createdAt: string | null;

  email: string | null;

  firstName: string | null;

  friendOfFriendsHidden: boolean | null;

  genderPronounPreference: string | null;

  id: number | null;

  isAgreedToTermsOfService: boolean;

  isConnected: boolean;

  isConnectionRequestPending: boolean;

  lastName: string | null;

  notifications: boolean;

  user: IUser;

  constructor(options = {}) {
    this._dynamics = DYNAMICS_LABELS;
    this._energies = ENERGIES;
    this._avatar = { url: null };
    this.account = {
      id: 0,
      isPersonal: false,
      licenseLimit: 0,
      licensesAllocated: 0,
      remainingLicensesCount: 0,
      totalMembersQuantity: 0,
    };
    this.bio = null;
    this.birthday = null;
    this.createdAt = null;
    this.email = null;
    this.firstName = null;
    this.friendOfFriendsHidden = null;
    this.genderPronounPreference = null;
    this.id = null;
    this.isAgreedToTermsOfService = false;
    this.isConnected = false;
    this.isConnectionRequestPending = false;
    this.lastName = null;
    this.notifications = false;
    this.user = {
      assessmentScores: [null, null, null, null],
      hasActiveAssessment: false,
      hasOngoingAssessment: false,
      id: null,
      impersonateToken: null,
      token: null,
      invitationType: INVITATION_TYPES.none,
    };

    const optionsKeysMap : { [key: string]: any } = {
      avatar: '_avatar',
      agreedToTermsOfService: 'isAgreedToTermsOfService',
      connected: 'isConnected',
      pendingConnectionRequestReceiver: 'isConnectionRequestPending',
    };

    const mappedOptions = mapKeys(options, (value, key) => (
      key in optionsKeysMap
        ? optionsKeysMap[key]
        : key
    ));

    merge(this, mappedOptions);

    if (!this.user.assessmentScores) {
      this.user.assessmentScores = [null, null, null, null];
    }
  }

  get accountId() : number | null {
    return this.account?.id;
  }

  get assessmentScores(): FixedSizeArray<4, number | null> {
    return this.user.assessmentScores;
  }

  get assessmentScoresObj() : IAssessmentScore {
    const {
      assessmentScores,
      hasActiveAssessment,
    } = this.user;

    assessmentScores.isNull = !hasScores(<assessmentScoresType> assessmentScores);

    assessmentScores.toString = hasActiveAssessment || assessmentScores.isNull
      ? () => 'Scores are not available'
      : toString;

    return assessmentScores;
  }

  get avatar() : string | null {
    return this._avatar?.url;
  }

  get dynamics() : string[] {
    return this._dynamics;
  }

  get energies() : IEnergy[] {
    const scoresArray = this.assessmentScores;
    const energies = scoresArray.map(
      (score: number | null) : IEnergy => this.getEnergyRangeLabel(score),
    );
    return energies;
  }

  getEnergyRangeLabel(score: number | null) : IEnergy {
    if (isNil(score)) {
      return {
        abbreviation: null,
        text: null,
      };
    }

    const domain = this._energies
      .reduce((result: number[], { threshold }) => {
        if (threshold !== undefined) {
          result.push(threshold);
        }
        return result;
      }, []);

    return scaleThreshold<number, IEnergy>()
      .domain(domain)
      .range(this._energies)(score);
  }

  get name() : string {
    return `${this.firstName} ${this.lastName}`;
  }

  get scores() : IScore[] {
    return zipWith(
      this.assessmentScores,
      this._dynamics,
      this.energies,
      (value, dynamic, { text: energy }) => ({
        dynamic,
        energy,
        value,
      }),
    );
  }
}

export default Profile;
