import { get, has } from 'lodash';
import React, { useEffect, useState, ComponentProps } from 'react';
import ReactHtmlParser from 'react-html-parser';
import propTypes from 'prop-types';
import shortid from 'shortid';
import classNames from 'classnames';
import styles from './Select.scss';

const Select = ({
  className,
  error,
  label,
  placeholder,
  options,
  ...rest
}: ISelectProps) : JSX.Element => {
  const [state, setState] = useState(rest.value || '');
  const [isValid, setValidation] = useState(!!state);

  const handleChange = (e) => {
    if (has(rest, 'onChange')) {
      rest.onChange(e);
    }
    setState(e.currentTarget.value);
  };

  useEffect(() => {
    setValidation(!!state);
  }, [state]);

  return (
    <>
      <div className={classNames(className, [styles.select__wrapper])}>
        <select
          className={classNames([styles.select], { [styles['is-valid']]: isValid })}
          onChange={(e) => handleChange(e)}
          onBlur={(e) => handleChange(e)}
          {...rest}
        >
          {placeholder && (
            <option className={styles.select__placeholder} disabled hidden value="">
              {ReactHtmlParser(placeholder)}
            </option>
          )}
          {options.map(({ value, text }) => (
            <option key={shortid()} value={value}>
              {ReactHtmlParser(text)}
            </option>
          ))}
        </select>
        <label
          className={styles.select__label}
          htmlFor={get(rest, 'id')}
        >
          {label}
        </label>
      </div>
      {error && (
        <span className="error-text">
          {error}
        </span>
      )}
    </>
  );
};

Select.propTypes = {
  className: propTypes.string,
  error: propTypes.string,
  label: propTypes.string.isRequired,
  options: propTypes.arrayOf(propTypes.shape({
    value: propTypes.string.isRequired,
    text: propTypes.string.isRequired,
  })).isRequired,
  placeholder: propTypes.string,
};

Select.defaultProps = {
  className: null,
  error: null,
  placeholder: '',
};

interface ISelectProps extends ComponentProps<'select'> {
  className?: string,
  error?: string | null,
  label: string,
  options: Array<{
    value: string
    text: string
  }>,
  placeholder: string,
}

export default Select;
