import { faTimes, faTrash } from '@fortawesome/pro-light-svg-icons';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IContextMenuOptionProps } from 'generics/ContextMenu/components/MenuOption/MenuOption';
import { SessionContext } from 'app_state/session/SessionProvider';
import api from 'api';
import fetchRelationships from 'app_state/session/actions/fetchRelationships';
import Profile from 'classes/Profile/Profile.class';
import useNotification from 'custom_hooks/useNotification';
import usePagination, { IUsePagination } from 'custom_hooks/usePagination';

const REQUESTS_COLLECTION_NAME = 'connectionRequests';

const useReceivedFriendRequests = () : IUseFriendRequests => {
  const { t } = useTranslation('home');
  const { state, dispatch } = useContext(SessionContext);
  const { relationships } = state;
  const { addErrorNotification } = useNotification();
  const [isContextMenuVisible, setIsContextMenuVisible] = useState(false);
  const [selectedFriendRequest, setSelectedFriendRequest] = useState<Profile | null>(null);
  const [selectedFriendRequestId, setSelectedFriendRequestId] = useState<number| null>(null);
  const handleCloseMenu = () => setIsContextMenuVisible(false);

  const receivedFriendRequests = usePagination(
    {
      onRequest: (props, ...rest) => api.connectionRequests.getFriendRequests(
        { ...props, list: 'received' }, ...rest,
      ),
      collectionName: REQUESTS_COLLECTION_NAME,
      initialCollection: relationships.receivedFriendRequests,
    },
  );

  const sentFriendRequests = usePagination(
    {
      onRequest: (props, ...rest) => api.connectionRequests.getFriendRequests(
        { ...props, list: 'sent' }, ...rest,
      ),
      collectionName: REQUESTS_COLLECTION_NAME,
      initialCollection: relationships.sentFriendRequests,
    },
  );

  const handleAnswerFriendRequest = (id: number, action: string) => {
    const onSuccess = () => {
      fetchRelationships(dispatch);
    };

    const onError = () => {
      addErrorNotification(`${action}FriendRequest`);
    };

    api.connectionRequests.answerFriendRequest({ id, action }, onSuccess, onError);
  };

  const handleShowDeleteFriendRequest = (friendRequest: Profile, requestId: number) => {
    setSelectedFriendRequest(friendRequest);
    setSelectedFriendRequestId(requestId);
    setIsContextMenuVisible(true);
  };

  const handleDeleteFriendRequest = () => {
    const onSuccess = () => {
      handleCloseMenu();
      fetchRelationships(dispatch);
    };

    const onError = () => {
      handleCloseMenu();
      addErrorNotification('deleteFriendRequestError');
    };

    api.connectionRequests.deleteFriendRequest(selectedFriendRequestId, onSuccess, onError);
  };

  const contextMenuOptions = [
    {
      icon: faTrash,
      label: t('deleteFriendRequest'),
      onClick: () => handleDeleteFriendRequest(),
    },
    {
      icon: faTimes,
      label: t('cancel'),
      onClick: handleCloseMenu,
    },
  ];

  return {
    contextMenu: {
      isVisible: isContextMenuVisible,
      onClose: handleCloseMenu,
      options: contextMenuOptions,
    },
    onAcceptFriendRequest: (id: number) => handleAnswerFriendRequest(id, 'accept'),
    onDeclineFriendRequest: (id: number) => handleAnswerFriendRequest(id, 'decline'),
    onDeleteFriendRequest: handleShowDeleteFriendRequest,
    receivedFriendRequests,
    selectedFriendRequest,
    sentFriendRequests,
  };
};

export interface IUseFriendRequests {
  contextMenu: {
    isVisible: boolean,
    onClose: () => void,
    options: IContextMenuOptionProps[],
  },
  onAcceptFriendRequest: (id: number) => void,
  onDeclineFriendRequest: (id: number) => void,
  onDeleteFriendRequest: (friendRequest: Profile, requestId: number) => void,
  receivedFriendRequests: IUsePagination,
  selectedFriendRequest: Profile | null,
  sentFriendRequests: IUsePagination,
}

export default useReceivedFriendRequests;
