import classnames from 'classnames';
import propTypes from 'prop-types';
import React from 'react';

import styles from './EnergyMap.scss';
import { IUseEnergyMap } from './useEnergyMap';

const EnergyMap = (
  { className, energyMapUtils } : IEnergyMapProps,
) : JSX.Element => {
  const {
    canvasRef,
    nodeElementRef,
    svgRef,
    mainGRef,
    referencesRef,
    profileRef,
    textRef,
  } = energyMapUtils;

  return (
    <div
      className={classnames(
        styles['energy-map'],
        className,
      )}
      ref={nodeElementRef}
      role="img"
    >
      <canvas
        className={styles['energy-map__canvas']}
        ref={canvasRef}
      />
      <svg
        className={styles['energy-map__svg']}
        ref={svgRef}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g ref={mainGRef}>
          <g ref={referencesRef} />
          <g ref={profileRef} />
          <g ref={textRef} />
        </g>
      </svg>
    </div>
  );
};

EnergyMap.propTypes = {
  className: propTypes.string,
  energyMapUtils: propTypes.shape({}).isRequired,
};

EnergyMap.defaultProps = {
  className: null,
};

interface IEnergyMapProps {
  className?: string,
  energyMapUtils: IUseEnergyMap,
}

export default EnergyMap;
