import { ILoginProps, ILinkCorporateAccountProps, ISettingsResult } from './types';

/**
 * @description Settings for controller
 */
export default {
  login: {
    // forces to set session timeout after login request
    forceTimeout: true,

    /**
     * @description Get request settings (Login)
     * @param  {object} options
     * @param  {string} options.email - User email
     * @param  {string} options.password - User password
     * @return {ISettingsResult} Request Settings
     */
    settings(options: ILoginProps) : ISettingsResult {
      return {
        endpoint: '/personal/sessions/login',
        body: options,
        method: 'POST',
      };
    },
  },

  logout: {
    clearTimeout: true,
    /**
     * @description Get request settings (Logout)
     * @return {ISettingsResult} Request Settings
     */
    settings() : ISettingsResult {
      return {
        endpoint: '/personal/sessions/logout',
        method: 'DELETE',
      };
    },
  },

  linkCorporate: {
    /**
     * @description Get request settings (Link Corporate Assessment)
     * @param  {object} options
     * @param  {string} options.email - Corporate User email
     * @param  {string} options.password - Corporate User password
     * @param  {number} options.token - Corporate User SSO Token
     * @return {ISettingsResult} Request Settings
     */
    settings(props: ILinkCorporateAccountProps) : ISettingsResult {
      return {
        body: props,
        endpoint: '/personal/sessions/link_corporate',
        method: 'POST',
      };
    },
  },
};
