import { isNil } from 'lodash';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import React from 'react';

import Button from 'generics/Button/Button';
import Eyebrow from 'generics/Eyebrow/Eyebrow';
import Form from 'generics/Form/Form';
import Input from 'generics/Input/Input';
import MenuMessage from 'generics/ContextMenu/components/MenuMessage/MenuMessage';
import MenuOptions from 'generics/ContextMenu/components/MenuOptions/MenuOptions';
import Modal from 'generics/Modal/Modal';
import PageSection from 'generics/PageSection/PageSection';
import PageTitle from 'generics/PageTitle/PageTitle';
import PageWrapper from 'generics/PageWrapper/PageWrapper';
import SearchResults from 'generics/SearchResults/SearchResults';

import useSendInvitation from './useSendInvitation';
import styles from './SendInvitation.scss';

const SendInvitation = () : JSX.Element => {
  const { t } = useTranslation(['sendInvitation']);

  const { search, contextMenu, form, onGoBack } = useSendInvitation();

  const {
    canSubmit,
    emailError,
    errors,
    onChangeField,
    onSubmitForm,
    values,
  } = form;

  const contextMenuMessage = (
    <span>
      <strong>{contextMenu.title}</strong>
      <br />
      {contextMenu.message}
    </span>
  );

  return (
    <>
      <PageWrapper>
        <div className={styles.layout} data-cy="send-invitation">

          <Form onSubmit={onSubmitForm}>
            <PageSection>
              <PageTitle
                title={t('title')}
                onBackButtonClick={onGoBack}
                actions={(
                  <Button isSmall type="submit" disabled={!canSubmit} className={styles['mobile-button']}>
                    {t('form.btn')}
                  </Button>
                )}
              />
            </PageSection>
            <PageSection>

              <Input
                error={isNil(values.firstName) ? null : errors.firstName}
                id="firstName"
                label={t('form.firstName')}
                name="firstName"
                onChange={(e) => onChangeField('firstName', e.currentTarget.value)}
                type="text"
                value={values.firstName || ''}
              />

              <Input
                error={isNil(values.lastName) ? null : errors.lastName}
                id="lastName"
                label={t('form.lastName')}
                name="lastName"
                onChange={(e) => onChangeField('lastName', e.currentTarget.value)}
                type="text"
                value={values.lastName || ''}
              />

              <Input
                error={isNil(values.email) ? null : (errors.email || emailError)}
                id="email"
                label={t('form.email')}
                name="email"
                onChange={(e) => onChangeField('email', e.currentTarget.value)}
                value={values.email || ''}
              />

              <Button type="submit" disabled={!canSubmit} className={styles['desktop-button']}>
                {t('form.btn')}
              </Button>

            </PageSection>
          </Form>
          {search.showResults && (
            <PageSection
              className={classNames(
                styles.results__wrapper,
                styles['is-visible'],
              )}
            >
              <Eyebrow>
                {t('searchResultsTitle')}
              </Eyebrow>
              <SearchResults
                className={styles.results}
                profiles={search.results}
                areMorePages={search.areMorePages}
                onGetMore={search.onGetMoreResults}
              />
            </PageSection>
          )}
        </div>
      </PageWrapper>

      <Modal
        isSmall
        isVisible={contextMenu.isVisible}
        onClose={contextMenu.onClose}
        data-cy="send-invite-modal"
      >
        <MenuMessage color="secondary" message={contextMenuMessage} />
        <MenuOptions color="secondary" options={contextMenu.options} />
      </Modal>
    </>
  );
};

export default SendInvitation;
