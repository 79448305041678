import React, { MouseEvent, ReactNode, useEffect, useRef } from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons';
import { CHILDREN } from 'app_constants/propTypes';
import IconButton from 'generics/IconButton/IconButton';
import styles from './PageTitle.scss';

const defaultTag = 'h2';

const PageTitle = ({
  actions,
  children,
  className,
  onBackButtonClick,
  tag: Tag = defaultTag,
  title,
}: IPageTitleProps) : JSX.Element => {
  const pageTitleStageRef = useRef(null);
  const actionsRef = useRef(null);

  useEffect(() => {
    if (actions) {
      const width = `${actionsRef.current.offsetWidth}px`;
      pageTitleStageRef.current.style.paddingRight = width;
      pageTitleStageRef.current.style.paddingLeft = width;
    }
  });

  return (
    <div
      ref={pageTitleStageRef}
      className={classNames([styles['page-title__stage'], className])}
      data-cy="page-title"
    >
      <div className={styles['page-title__wrapper']}>
        {onBackButtonClick && (
          <div className={styles['page-title__back-button']}>
            <IconButton
              icon={faChevronLeft}
              onClick={onBackButtonClick}
            />
          </div>
        )}
        {title && (
          <Tag className={styles['page-title']}>
            {title}
          </Tag>
        )}
        {children}
        {actions && (
          <div
            ref={actionsRef}
            className={styles['page-title__actions']}
          >
            {actions}
          </div>
        )}
      </div>
    </div>
  );
};

PageTitle.propTypes = {
  actions: CHILDREN,
  children: CHILDREN,
  className: propTypes.string,
  onBackButtonClick: propTypes.func,
  tag: propTypes.string,
  title: CHILDREN,
};

PageTitle.defaultProps = {
  actions: null,
  children: null,
  className: null,
  onBackButtonClick: null,
  tag: defaultTag,
  title: null,
};

interface IPageTitleProps extends Omit<React.HTMLAttributes<HTMLOrSVGElement>, 'title'> {
  actions?: ReactNode,
  className?: string,
  onBackButtonClick?: (event: MouseEvent<HTMLButtonElement>) => void,
  tag?: keyof JSX.IntrinsicElements,
  title: ReactNode,
}

export default PageTitle;
