import { ISettingsResult } from './types';

/**
 * @description Settings for controller
 */
export default {
  getAccount: {
    /**
     * @description Get account
     * @return {object} Request Settings
     */
    settings() : ISettingsResult {
      return {
        endpoint: '/personal/accounts/',
        method: 'GET',
      };
    },
  },
};
