import { Dispatch } from 'react';

import { ApiError } from 'classes/errors';
import { ISessionAction } from 'app_state/types';
import api from 'api';
import sessionActionTypes from 'app_state/session/actionTypes';

const updateAvatarFailure = (error: typeof ApiError) : ISessionAction => ({
  payload: error,
  type: sessionActionTypes.FETCH_UPDATE_AVATAR_FAILURE,
});

const updateAvatarRequest = () : ISessionAction => ({
  payload: null,
  type: sessionActionTypes.FETCH_UPDATE_AVATAR_REQUEST,
});

const updateAvatarSuccess = (payload: string) : ISessionAction => ({
  payload,
  type: sessionActionTypes.FETCH_UPDATE_AVATAR_SUCCESS,
});

export default (
  dispatch: Dispatch<ISessionAction>,
  avatar: File,
  successCallback: () => void,
  errorCallback: () => void,
) : void => {
  dispatch(updateAvatarRequest());

  const onSuccess = ({ profile: updatedProfile } : any) => {
    dispatch(updateAvatarSuccess(updatedProfile.avatar));
    successCallback();
  };

  const onError = (error: typeof ApiError) => {
    dispatch(updateAvatarFailure(error));
    errorCallback();
  };

  api.profiles.updateAvatar(avatar, onSuccess, onError);
};
