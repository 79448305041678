/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';

import { SessionContext } from 'app_state/session/SessionProvider';
import api from 'api';
import Profile from 'classes/Profile/Profile.class';

const useSearch = () : IuseSearchResults => {
  const { state: { currentUser } } = useContext(SessionContext);
  const [query, setQuery] = useState('');
  const [page, setPage] = useState(1);
  const [areMorePages, setMorePages] = useState(false);
  const [results, setResults] = useState<Profile[]>([]);
  const [areResultsVisible, setResultsVisibility] = useState(false);

  const handleClearSearch = () => {
    setQuery('');
    setPage(1);
    setMorePages(false);
  };

  const search = (newPage: number) => {
    if (query) {
      const onSuccess = ({ meta, profiles }: IProfilesProps) => {
        const newProfiles = profiles.map((profile: any) => new Profile({ ...profile }));

        if (newProfiles) {
          const newResults = newPage === 1 ? newProfiles : [...results, ...newProfiles];
          setResults(newResults);
          setResultsVisibility(true);
        }

        setMorePages(meta.morePages);
        setPage(newPage);
      };

      const onError = () => {
        if (DEVELOPMENT) {
          console.log('Error while trying to search profiles');
        }
      };

      const searchOptions = {
        page: newPage,
        search: query,
      };

      api.profiles.getAll(searchOptions, onSuccess, onError);
    }
  };

  // Run after query text changes
  useEffect(() : () => void => {
    let delayDebounceFn: NodeJS.Timeout | null = null;

    if (query) {
      delayDebounceFn = setTimeout(() => {
        search(1);
      }, 300);
    } else {
      setResults([]);
      setResultsVisibility(false);
    }
    return () => delayDebounceFn && clearTimeout(delayDebounceFn);
  }, [query, currentUser]);

  useEffect(() => {
    if (results?.length && !query) {
      setResults([]);
      setResultsVisibility(false);
      setPage(1);
      setMorePages(false);
    }
  }, [results]);

  const handleMoreResults = () => {
    search(page + 1);
  };

  return {
    onClearSearch: handleClearSearch,
    onGetMoreResults: handleMoreResults,
    areMorePages,
    query,
    results,
    areResultsVisible,
    onChangeQuery: setQuery,
  };
};

interface IProfilesProps {
  meta: {
    morePages: boolean,
  },
  profiles: any[],
}

interface IuseSearchResults {
  onClearSearch: () => void,
  onGetMoreResults: () => void,
  areMorePages: boolean,
  query: string,
  results: Profile[],
  areResultsVisible: boolean,
  onChangeQuery: (query: string) => void,
}

export default useSearch;
