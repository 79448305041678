import {
  IGetSavedTipsProps,
  ISaveTipProps,
  ISettingsResult,
} from './types';

/**
 * @description Settings for Tips controller
 */
export default {
  saveTip: {
    /**
     * @description Get request settings - Save a tip (Advice)
     * @param  {object} options
     * @param  {number} options.thirdProfileId - Someone else Profile ID
     * @param  {Advice} options.tip - Tip/Advice to be saved
     * @return {object} Request Settings
     */
    settings(options: ISaveTipProps) : ISettingsResult {
      const {
        thirdProfileId,
        tip,
      } = options;

      const {
        type: contextType,
        key,
        title,
        content,
      } = tip;

      return {
        endpoint: '/personal/tips',
        method: 'POST',
        body: {
          thirdProfileId,
          contextType,
          key,
          title,
          content,
        },
      };
    },
  },

  unsaveTip: {
    /**
     * @description Get request settings - Delete (unsave) tip (Advice)
     * @param  {number} tipId
     * @return {object} Request Settings
     */
    settings(tipId: number) : ISettingsResult {
      return {
        endpoint: `/personal/tips/${tipId}/`,
        method: 'DELETE',
      };
    },
  },

  getSavedTips: {
    ignoreNotFound: true,
    /**
     * @description Get request settings (Get Saved Tips)
     * @param  {object} options
     * @param  {number} options.thirdProfileId - Someone else profile Id
     * @param  {string} options.key - Advice key filter
     * @param  {string} options.type - Type filter (pair or individual)
     * @return {object} Request Settings
     */
    settings({ thirdProfileId, type, key, page }: IGetSavedTipsProps) : ISettingsResult {
      let endpoint = `/personal/tips/third_profile_id/${thirdProfileId}`;

      if (type) {
        endpoint = `${endpoint}/context_type/${type}`;
      }
      if (key) {
        endpoint = `${endpoint}/key/${key}`;
      }
      if (page) {
        endpoint = `${endpoint}/page/${page}`;
      }

      return {
        endpoint,
        method: 'GET',
      };
    },
  },
};
