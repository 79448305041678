import React from 'react';
import ReactDOM from 'react-dom';
import { AppContainer } from 'react-hot-loader';

import App from './components/App';
import './assets/styles/styles.scss';

const rootElement = document.querySelector('#react-root');

ReactDOM.render(
  <AppContainer>
    <App />
  </AppContainer>,
  rootElement,
);

if (module && module.hot) {
  module.hot.accept();

  module.hot.addStatusHandler((status) => {
    if (status === 'prepare') console.clear();
  });
}
