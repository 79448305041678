import { useTranslation } from 'react-i18next';
import PRONOUN_PREFERENCES from 'app_constants/pronounPreferences';

/**
 * @description custom hook to add form validations
 */
const usePronounPreferences = () : OptionsType => {
  const { t } = useTranslation(['generics']);
  return PRONOUN_PREFERENCES.map((value) => ({
    value,
    text: t(`pronouns.${value.toLowerCase()}`),
  }));
};

type OptionsType = Array<{
  value: string
  text: string
}>

export default usePronounPreferences;
