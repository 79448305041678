import { formatRoute } from 'react-router-named-routes';
import { kebabCase } from 'lodash';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import React from 'react';

import Avatar from 'generics/Avatar/Avatar';
import Card from 'generics/Card/Card';
import LoadingAnimation from 'generics/LoadingAnimation/LoadingAnimation';
import PageSection from 'generics/PageSection/PageSection';
import PageTitle from 'generics/PageTitle/PageTitle';
import PageWrapper from 'generics/PageWrapper/PageWrapper';
import URLS from 'app_constants/urls';

import AdviceCard from './components/AdviceCard/AdviceCard';
import useAdvice from './useAdvice';

import styles from './Advice.scss';

const Advice = () : JSX.Element => {
  const history = useHistory();
  const {
    advice,
    currentProfile,
    isLoading,
    items,
    onSaveTip,
    onUnsaveTip,
    savedTips,
  } = useAdvice();

  return (
    <PageWrapper>
      {
        isLoading
          ? (
            <PageSection>
              <LoadingAnimation />
            </PageSection>
          )
          : (
            <div className={styles.advice__layout__wrapper}>
              <PageSection>
                <PageTitle
                  onBackButtonClick={() => history.push(formatRoute(URLS.profile, {
                    id: currentProfile?.id,
                  }))}
                >
                  <Card
                    className={styles['advice__profile-card']}
                    title={currentProfile?.name || ''}
                    subtitle={advice?.title}
                    media={<Avatar path={currentProfile?.avatar} alt={currentProfile?.name || ''} />}
                    assessmentScores={currentProfile?.assessmentScores}
                    showBeachBall
                  />
                </PageTitle>
              </PageSection>
              <div className={styles.advice__layout}>
                {items.map((item, index) => {
                  const savedTip = savedTips.find(
                    ({ title: savedTipTitle }) => savedTipTitle === item.title,
                  );
                  return (
                    <div
                      className={classNames(
                        styles.advice__layout__card,
                        { [styles['advice__layout__card__full-width']]: (items.length === 1) && (index === 0) },
                      )}
                      key={kebabCase(item.title)}
                    >
                      <PageSection>
                        <AdviceCard
                          {...item}
                          adviceCategory={item.adviceCategory}
                          icon={item.icon}
                          id={savedTip?.id}
                          isSaved={!!savedTip}
                          onSave={(onFinish?: () => void) => onSaveTip(item, onFinish)}
                          onUnsave={(onFinish?: () => void) => onUnsaveTip(
                            savedTip?.id || 0,
                            onFinish,
                          )}
                        />
                      </PageSection>
                    </div>
                  );
                })}
              </div>
            </div>
          )
      }
    </PageWrapper>
  );
};

type paramsType = {
  id: string,
  key: string,
}

export default Advice;
