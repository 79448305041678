import React, { ReactNode } from 'react';
import classNames from 'classnames';
import propTypes from 'prop-types';
import { CHILDREN } from 'app_constants/propTypes';
import styles from './PageWrapper.scss';

const PageWrapper = ({
  children,
  className,
}: IPageWrapperProps) : JSX.Element => (
  <main className={classNames(className, [styles['page-wrapper']])}>
    {children}
  </main>
);

PageWrapper.propTypes = {
  children: CHILDREN,
  className: propTypes.string,
};

PageWrapper.defaultProps = {
  children: null,
  className: null,
};

interface IPageWrapperProps {
  children: ReactNode,
  className?: string,
}

export default PageWrapper;
