import { Dispatch } from 'react';

import { ILinkCorporateAccountProps } from 'api/controllers/types';
import { ISessionAction } from 'app_state/types';
import api from 'api';
import ApiError from 'classes/errors/ApiError.class';
import history from 'utils/history';
import sessionActionTypes from 'app_state/session/actionTypes';
import URLS from 'app_constants/urls';

import fetchContent from './fetchContent';

const linkCorporateFailure = (error: ApiError) : ISessionAction => ({
  payload: error,
  type: sessionActionTypes.FETCH_LINK_CORPORATE_FAILURE,
});

const linkCorporateRequest = () : ISessionAction => ({
  payload: null,
  type: sessionActionTypes.FETCH_LINK_CORPORATE_REQUEST,
});

const linkCorporateSuccess = (payload: any) : ISessionAction => ({
  payload,
  type: sessionActionTypes.FETCH_LINK_CORPORATE_SUCCESS,
});

export default (
  dispatch: Dispatch<ISessionAction>,
  values: ILinkCorporateAccountProps,
  successCallback: () => void,
  errorCallback: (error: ApiError) => void,
) : void => {
  dispatch(linkCorporateRequest());

  const onSuccess = ({ assessment } : any) => {
    if (assessment && assessment.finished) {
      dispatch(linkCorporateSuccess(assessment));
      fetchContent(dispatch);
      successCallback();
    } else {
      // assessment from corporate is not completed yet
      history.push(URLS.welcome);
    }
  };

  const onError = (error: ApiError) => {
    dispatch(linkCorporateFailure(error));
    errorCallback(error);
  };

  api.session.linkCorporate(values, onSuccess, onError);
};
