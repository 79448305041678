import getEpicBaseKey from 'utils/getEpicBaseKey';

import {
  ADVICE_DESIGNS,
  keyType,
  DEFAULT_CATEGORY,
  DEFAULT_ICON,
  iconType,
} from 'app_constants/adviceDesigns';

export default class Advice {
  adviceCategory: keyType;

  content: string;

  description: string;

  icon: iconType;

  id?: number;

  key: string;

  title: string;

  type: string;

  constructor(options : IProps) {
    const { contentType, contextType } = options;

    this.adviceCategory = DEFAULT_CATEGORY;
    this.content = options.content ?? '';
    this.description = options.description ?? '';
    this.icon = DEFAULT_ICON;
    this.id = options.id;
    this.key = options.key ?? '';
    this.title = options.title ?? '';
    this.type = contentType || contextType || 'individual';
    this.getDesign();
  }

  getDesign() : void {
    const designKey = getEpicBaseKey(this.key);
    const filteredItems = ADVICE_DESIGNS.filter(({ key }) => key === designKey);

    if (filteredItems.length > 0) {
      this.adviceCategory = filteredItems[0].category;
      this.icon = filteredItems[0].icon;
    }
  }
}

type contextTypes = 'individual' | 'pair';

export interface IProps {
  content?: string,
  contentType?: contextTypes,
  contextType?: contextTypes,
  description?: string,
  id?: number,
  key?: string,
  title?: string,
}
