import { ISessionAction } from 'app_state/types';
import sessionActionTypes from 'app_state/session/actionTypes';
import Profile from 'classes/Profile/Profile.class';

const updateUser = (profile: Profile) : ISessionAction => ({
  payload: profile,
  type: sessionActionTypes.UPDATE_USER,
});

export default updateUser;
