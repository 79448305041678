import {
  IContentProps,
  ISettingsResult,
} from './types';

/**
 * @description Settings for Content Manager controller
 */
export default {
  getContent: {
    ignoreNotFound: true,

    /**
     * @description get settings (get Content endpoint)
     * @param {object} options
     * @param {string} options.contentType
     * @param {number} options.moduleKey
     * @param {number} options.profileId
     * @returns {object} settings: { endpoint, method }
     */
    settings({ contentType, moduleKey, profileId }: IContentProps) : ISettingsResult {
      let endpoint = `/personal/content_manager/content_type/${contentType}`;

      if (moduleKey) {
        endpoint += `/module/${moduleKey}`;
      }

      if (profileId) {
        endpoint += `/profile/${profileId}`;
      }

      return {
        endpoint,
        method: 'GET',
      };
    },
  },
};
