import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React from 'react';
import classNames from 'classnames';

import URLS from 'app_constants/urls';

import Button from 'generics/Button/Button';
import Content from 'generics/Content/Content';
import PageSection from 'generics/PageSection/PageSection';
import PageTitle from 'generics/PageTitle/PageTitle';
import PageWrapper from 'generics/PageWrapper/PageWrapper';

import styles from './PageNotFound.scss';

const PageNotFound = () : JSX.Element => {
  const history = useHistory();
  const { t } = useTranslation(['pageNotFound']);

  return (
    <PageWrapper>
      <div className={classNames(styles.layout, 'u-align--center')}>
        <PageSection>

          <PageTitle
            title={t('heading')}
          />

        </PageSection>
        <PageSection>

          <Content
            className={styles.content}
            content={t('content')}
          />

          <Button onClick={() => history.push(URLS.home)}>
            {t('buttonLabel')}
          </Button>

        </PageSection>
      </div>
    </PageWrapper>
  );
};

export default PageNotFound;
