import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import propTypes from 'prop-types';
import React, { ComponentProps } from 'react';

import URLS from 'app_constants/urls';
import styles from './TermsCheckbox.scss';

const TermsCheckbox = ({ error, id, ...rest }: ITermsCheckboxProps) : JSX.Element => {
  const { t } = useTranslation('generics');
  return (
    <div className={styles.checkbox__wrapper}>
      <input
        id={id}
        type="checkbox"
        {...rest}
      />
      <label
        className={styles.checkbox__label}
        htmlFor={id}
      >
        {t('agreedTo')}
        <Link href={URLS.terms} to={URLS.terms} target="_blank">{t('terms')}</Link>
      </label>
      {error && (
        <span className={styles.checkbox__error}>{error}</span>
      )}
    </div>
  );
};

TermsCheckbox.propTypes = {
  error: propTypes.string,
  id: propTypes.string.isRequired,
};

TermsCheckbox.defaultProps = {
  error: null,
};

interface ITermsCheckboxProps extends ComponentProps<'input'> {
  error?: string | null,
}

export default TermsCheckbox;
