import React from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';

import styles from './MenuMessage.scss';

const MenuMessage = ({ color, message }: IMenuMessageProps) : JSX.Element => (
  <div
    className={classNames(
      styles['context-menu__message'],
      { [styles['context-menu__message__primary']]: color === 'primary' },
      { [styles['context-menu__message__secondary']]: color === 'secondary' },
    )}
    data-cy="menu-message"
  >
    { message }
  </div>
);

MenuMessage.propTypes = {
  color: propTypes.string,
  message: propTypes.node,
};

MenuMessage.defaultProps = {
  color: 'primary',
  message: null,
};

interface IMenuMessageProps {
  color: string,
  message: React.ReactNode,
}

export default MenuMessage;
