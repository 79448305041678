import { Dispatch } from 'react';

import { ApiError } from 'classes/errors';
import { IInvitationProps } from 'api/controllers/types';
import { IInvitesAction } from 'app_state/types';
import api from 'api';
import invitationActionTypes from 'app_state/invites/actionTypes';

export const fetchDeclineInvitationFailure = (error: typeof ApiError) : IInvitesAction => ({
  payload: error,
  type: invitationActionTypes.FETCH_DECLINE_INVITATION_FAILURE,
});

export const fetchDeclineInvitationRequest = () : IInvitesAction => ({
  payload: null,
  type: invitationActionTypes.FETCH_DECLINE_INVITATION_REQUEST,
});

export const fetchDeclineInvitationSuccess = () : IInvitesAction => ({
  payload: null,
  type: invitationActionTypes.FETCH_DECLINE_INVITATION_SUCCESS,
});

export default (
  props: IInvitationProps,
  dispatch: Dispatch<any>,
  errorCallback: () => void,
) : void => {
  dispatch(fetchDeclineInvitationRequest());

  const onSuccess = () => {
    dispatch(fetchDeclineInvitationSuccess());
  };

  const onError = (error: typeof ApiError) => {
    dispatch(fetchDeclineInvitationFailure(error));
    errorCallback();
  };

  api.invites.declineInvitation(props, onSuccess, onError);
};
