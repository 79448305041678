import { kebabCase } from 'lodash';

// TODO: Remove this when epic returns unique keys
// There are not unique keys from Epic results, we need to create one
const KEY_REGEX = /(.+)_\d+/;

const getEpicBaseKey = (key: string) : string => {
  let result = '';
  const matchesResult = key.match(KEY_REGEX);

  if (matchesResult && matchesResult.length > 1) {
    [, result] = matchesResult;
  }

  return kebabCase(result);
};

export default getEpicBaseKey;
