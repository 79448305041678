import propTypes from 'prop-types';
import React from 'react';

import AppFooter from 'components/AppFooter/AppFooter';
import AppHeader from 'components/AppHeader/AppHeader';
import NotificationBar from 'components/NotificationBar/NotificationBar';
import AppVectorFooter from '~/components/AppVectorFooter/AppVectorFooter';

const NoAuthenticatedLayout : React.FC = ({ children }) => (
  <>
    <AppHeader noAuthLayout />
    <NotificationBar />
    { children }
    <AppVectorFooter />
    <AppFooter isNoAuthLayout />
  </>
);

NoAuthenticatedLayout.propTypes = {
  children: propTypes.node.isRequired,
};

export default NoAuthenticatedLayout;
