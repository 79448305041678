import { faBars, faHeadset, faSearch, faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatRoute } from 'react-router-named-routes';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import ClickAwayListener from 'react-click-away-listener';
import propTypes from 'prop-types';
import React, { useContext, useState } from 'react';

import URLS from 'app_constants/urls';

import { SessionContext } from 'app_state/session/SessionProvider';
import fetchLogout from 'app_state/session/actions/fetchLogout';

import Button from 'generics/Button/Button';
import IconButton from 'generics/IconButton/IconButton';
import Menu from 'components/generics/Menu/Menu';
import SearchBar from 'components/SearchBar/SearchBar';

import styles from './AppHeader.scss';
import SvgLogo from './simpli5-relationships-logo.svg';

const AppHeader = ({ isRestrictedLayout, noAuthLayout } : IAppHeader) : JSX.Element => {
  const { t } = useTranslation('generics');
  const { state, dispatch } = useContext(SessionContext);
  const { currentUser, isAuthenticated } = state;
  const routeToProfile = formatRoute(URLS.profile, { id: currentUser?.id });
  const [isMenuVisible, setMenuVisibility] = useState(false);
  const [isSearchBarVisible, setSearchBarVisibility] = useState(false);
  const toggleMenuVisibility = () => setMenuVisibility(!isMenuVisible);

  const handleLogout = () => {
    fetchLogout(dispatch);
  };

  const getLogoWrapper = (children: JSX.Element, className:string) => {
    const logoWrapper = !isRestrictedLayout ? (
      <Link
        className={className}
        href={!isRestrictedLayout && URLS.home}
        to={!isRestrictedLayout && URLS.home}
      >
        {children}
      </Link>
    ) : (
      <span
        className={className}
      >
        {children}
      </span>
    );

    return logoWrapper;
  };

  const getMenuItems = () => [
    ...!isRestrictedLayout ? [
      <Link href={routeToProfile} to={routeToProfile}>
        {t('menu.profile')}
      </Link>,
    ] : [],
    <Button className="u-align--left" isLink isBlock onClick={handleLogout}>
      {t('menu.logout')}
    </Button>,
  ];

  return (
    <header
      className={classNames(
        [styles['app-header']],
        { [styles['no-auth']]: (noAuthLayout && !isAuthenticated) },
      )}
    >

      {getLogoWrapper(
        <img
          className={styles['app-header__logo']}
          src={SvgLogo}
          alt="Simpli5 Relationships"
        />,
        styles['app-header__logo__wrapper'],
      )}

      {isAuthenticated && (
        <div className={styles['app-header__actions']}>
          {!isRestrictedLayout && (
            <ClickAwayListener
              onClickAway={() => isSearchBarVisible && setSearchBarVisibility(false)}
            >
              <IconButton
                className={styles['app-header__search-bar-toggle']}
                icon={faSearch}
                onClick={() => setSearchBarVisibility(!isSearchBarVisible)}
              />
              <SearchBar
                isVisible={isSearchBarVisible}
                onClose={() => setSearchBarVisibility(false)}
              />
            </ClickAwayListener>
          )}
          <Link className={styles['app-header__help-button']} to={{ pathname: 'https://support.personal-life.simpli5.com' }} target="_blank">
            <FontAwesomeIcon icon={faHeadset} />
          </Link>
          <ClickAwayListener onClickAway={() => isMenuVisible && setMenuVisibility(false)}>
            <IconButton
              icon={isMenuVisible ? faTimes : faBars}
              onClick={toggleMenuVisibility}
            />
            <Menu
              className={classNames(
                [styles['app-header__menu']],
                { [styles['is-visible']]: isMenuVisible },
              )}
              items={getMenuItems()}
            />
          </ClickAwayListener>

        </div>
      )}

    </header>
  );
};

AppHeader.propTypes = {
  isRestrictedLayout: propTypes.bool,
  noAuthLayout: propTypes.bool,
};

AppHeader.defaultProps = {
  isRestrictedLayout: false,
  noAuthLayout: false,
};

interface IAppHeader {
  isRestrictedLayout?: boolean,
  noAuthLayout?: boolean,
}

export default AppHeader;
