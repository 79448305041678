import { merge } from 'lodash';

import { IController } from 'api/controllers/types';
import ApiEndpoint from 'classes/api/ApiEndpoint.class';

interface ApiControllerProps {
  api: any,
  controller: IController,
}

type instancesType = {
  [key: string]: ApiEndpoint
};

type endpointsType = {
  [key: string]: ApiEndpoint['request'],
};

/**
 * @class ApiController
 * @description Creates an API Controller with all the endpoints request features
 * */
export default class ApiController {
  instances: instancesType;

  constructor({ api, controller } : ApiControllerProps) {
    const instances: instancesType = {};
    const endpoints: endpointsType = {};

    Object.keys(controller)
      .forEach((endpoint) => {
        instances[endpoint] = new ApiEndpoint({
          api,
          ...controller[endpoint],
        });
        endpoints[endpoint] = function request(options, onSuccess, onError) {
          return instances[endpoint].request(options, onSuccess, onError);
        };
      });

    this.instances = instances;
    merge(this, endpoints);
  }
}
