import { formatRoute } from 'react-router-named-routes';
import { sprintf } from 'sprintf-js';
import { useTranslation } from 'react-i18next';
import React from 'react';

import Avatar from 'generics/Avatar/Avatar';
import Button from 'generics/Button/Button';
import Card from 'generics/Card/Card';
import CardsList from 'generics/CardsList/CardsList';
import MenuMessage from 'generics/ContextMenu/components/MenuMessage/MenuMessage';
import MenuOptions from 'generics/ContextMenu/components/MenuOptions/MenuOptions';
import Modal from 'generics/Modal/Modal';
import Pagination from 'generics/Pagination/Pagination';
import Profile from 'classes/Profile/Profile.class';
import URLS from 'app_constants/urls';

import useFriendRequests from './useFriendRequests';
import styles from '../../Home.scss';

const SentFriendRequests = () : JSX.Element => {
  const { t } = useTranslation('home');
  const {
    contextMenu,
    onDeleteFriendRequest,
    selectedFriendRequest,
    sentFriendRequests,
  } = useFriendRequests();

  const cards = sentFriendRequests.results.map(({ id: requestId, connectionRequestReceiver }) => {
    const profile = new Profile({ ...connectionRequestReceiver });
    const { name, avatar, id } = profile;

    return (
      <Card
        route={formatRoute(URLS.profile, { id })}
        title={name}
        subtitle={t('sentRequestSubtitle')}
        media={<Avatar path={avatar} alt={name} />}
        showBeachBall
      >
        <Button
          className={styles['relationship-cards__button']}
          isSecondary
          isSmall
          onClick={() => onDeleteFriendRequest(profile, requestId)}
          data-cy="delete-friend-request-btn"
        >
          {t('deleteFriendRequest')}
        </Button>
      </Card>
    );
  });

  return (
    <>
      <CardsList
        className={styles['relationship-cards']}
        cards={cards}
      />
      <Pagination
        isVisible={sentFriendRequests.areMorePages}
        onClick={sentFriendRequests.onGetMoreResults}
      />
      <Modal
        isSmall
        isVisible={contextMenu.isVisible}
        onClose={contextMenu.onClose}
      >
        <MenuMessage
          color="secondary"
          message={(
            <p className={styles['relationship-cards__context-menu']}>
              <strong>{t('deleteFriendRequestMenu.title')}</strong>
              <br />
              {sprintf(t('deleteFriendRequestMenu.message'), selectedFriendRequest?.firstName)}
            </p>
          )}
        />
        <MenuOptions color="secondary" options={contextMenu.options} />
      </Modal>
    </>
  );
};

export default SentFriendRequests;
