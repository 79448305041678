import {
  IAnswerFriendRequestProps,
  IFriendRequestsProps,
  ISettingsResult,
} from './types';

/**
 * @description Settings for controller
 */
export default {
  sendConnectionRequest: {
    /**
     * @description Get request settings (Send Friend Request)
     * @param  {number} profileId - Receiver Profile ID
     * @return {object} Request Settings
     */
    settings(profileId: number) : ISettingsResult {
      return {
        endpoint: '/personal/connection_requests/send',
        body: {
          connectionRequest: {
            receiverId: profileId,
          },
        },
        method: 'POST',
      };
    },
  },

  getFriendRequests: {
    /**
     * @description Get Friend Requests (Sent and Received)
     * @param  {object} options
     * @param  {string} options.list - "sent" or "received"
     * @return {object} Request Settings
     */
    settings(props: IFriendRequestsProps) : ISettingsResult {
      const { list, page } = props;
      let endpoint = `/personal/connection_requests/${list}`;

      if (page) {
        endpoint = `${endpoint}/page/${page}`;
      }

      return {
        endpoint,
        method: 'GET',
      };
    },
  },

  answerFriendRequest: {
    /**
     * @description Get request settings (Accept/Decline Friend Request)
     * @param {object} options
     * @param  {number} options.action decline / accept
     * @param  {number} options.id friend request id
     * @return {object} Request Settings
     */
    settings({ action, id } : IAnswerFriendRequestProps) : ISettingsResult {
      return {
        endpoint: `/personal/connection_requests/${action}`,
        body: { id },
        method: 'POST',
      };
    },
  },

  deleteFriendRequest: {
    /**
     * @description Get request settings (Delete Friend Request)
     * @param  {number} id friend request id
     * @return {object} Request Settings
     */
    settings(id: number) : ISettingsResult {
      return {
        endpoint: '/personal/connection_requests/invalidate',
        body: { id },
        method: 'POST',
      };
    },
  },

  removeFriend: {
    /**
     * @description Get request settings (Remove friend)
     * @param  {number} id - friend profile id
     * @return {object} Request Settings
     */
    settings(id: number) : ISettingsResult {
      return {
        endpoint: `/personal/connection_requests/remove_friend/${id}`,
        method: 'DELETE',
      };
    },
  },
};
