/* eslint-disable react-hooks/exhaustive-deps */
import { faEye, faEyeSlash, faUserCheck } from '@fortawesome/pro-solid-svg-icons';
import { faUserTimes } from '@fortawesome/pro-light-svg-icons';
import { formatRoute } from 'react-router-named-routes';
import { sprintf } from 'sprintf-js';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import React from 'react';
import shortid from 'shortid';

import AdviceCard from 'pages/Advice/components/AdviceCard/AdviceCard';
import AdviceIcon from 'pages/Advice/components/AdviceIcon/AdviceIcon';
import Button from 'generics/Button/Button';
import Card from 'generics/Card/Card';
import CardsList from 'generics/CardsList/CardsList';
import ContextMenu from 'generics/ContextMenu/ContextMenu';
import Eyebrow from 'generics/Eyebrow/Eyebrow';
import IconButton from 'generics/IconButton/IconButton';
import KitList from 'components/KitList/List';
import LoadingAnimation from 'generics/LoadingAnimation/LoadingAnimation';
import MenuMessage from 'generics/ContextMenu/components/MenuMessage/MenuMessage';
import MenuOptions from 'generics/ContextMenu/components/MenuOptions/MenuOptions';
import Modal from 'generics/Modal/Modal';
import PageSection from 'generics/PageSection/PageSection';
import PageWrapper from 'generics/PageWrapper/PageWrapper';
import Pagination from 'generics/Pagination/Pagination';
import Tooltip from 'generics/Tooltip/Tooltip';
import SearchResults from 'generics/SearchResults/SearchResults';
import URLS from 'app_constants/urls';

import Hero from './Hero/Hero';
import Overview from './Overview/Overview';
import styles from './Profile.scss';
import useProfile from './useProfile';

const ProfilePage = () : JSX.Element => {
  const { t } = useTranslation('profile');
  const history = useHistory();

  const {
    advices,
    confirmationModal,
    currentProfile,
    friends,
    isConnectedProfile,
    isFriendRequestSent,
    isLoading,
    isMyProfile,
    isNotConnectedProfile,
    menu,
    menuToggleShowFriend,
    onAddFriend,
    onToggleFriendVisibility,
    onRemoveFriend,
    overview,
    savedTips,
  } = useProfile();

  let actions: React.ReactChild | null = null;

  if (isMyProfile) {
    actions = (
      <Button
        role="link"
        type="button"
        onClick={() => history.push(URLS.editProfile)}
        isSecondary
      >
        {t('hero.editButton')}
      </Button>
    );
  } else if (isConnectedProfile) {
    actions = (
      <>
        <ContextMenu
          isVisible={menuToggleShowFriend.isVisible}
          onClose={() => menuToggleShowFriend.setIsVisible(false)}
          options={[{
            icon: !currentProfile?.friendOfFriendsHidden ? faEyeSlash : faEye,
            label: !currentProfile?.friendOfFriendsHidden ? sprintf(t('friendVisibilityOn'), currentProfile?.firstName) : sprintf(t('friendVisibilityOff'), currentProfile?.firstName),
            onClick: onToggleFriendVisibility,
          }]}
        >
          <Tooltip
            tooltip={!currentProfile?.friendOfFriendsHidden ? t('friendVisibilityOnTooltip') : t('friendVisibilityOffTooltip')}
            alignment="right"
            showOnHover
          >
            <IconButton
              icon={!currentProfile?.friendOfFriendsHidden ? faEye : faEyeSlash}
              onClick={() => {
                menu.setIsVisible(false);
                menuToggleShowFriend.setIsVisible(!menuToggleShowFriend.isVisible);
              }}
              data-cy="toggle-friend-visibility-btn"
            />
          </Tooltip>
        </ContextMenu>
        <ContextMenu
          isVisible={menu.isVisible}
          onClose={() => menu.setIsVisible(false)}
          options={[{
            icon: faUserTimes,
            label: `${t('hero.contextMenu.removeFriendPrefix')} ${currentProfile?.name}`,
            onClick: onRemoveFriend,
          }]}
        >
          <IconButton
            icon={faUserCheck}
            onClick={() => {
              menu.setIsVisible(!menu.isVisible);
              menuToggleShowFriend.setIsVisible(false);
            }}
            data-cy="remove-friend-btn"
          />
        </ContextMenu>
      </>
    );
  } else if (isFriendRequestSent) {
    actions = (
      <span className={styles['friend-request-status']}>{t('friendRequestSent')}</span>
    );
  } else {
    actions = (
      <Button
        role="link"
        type="button"
        onClick={onAddFriend}
      >
        {t('hero.addFriendButton')}
      </Button>
    );
  }

  return (
    <PageWrapper>
      <div className={styles.layout}>
        {
          isLoading
            ? (
              <PageSection className={styles['layout__loading-animation']}>
                <LoadingAnimation />
              </PageSection>
            )
            : (
              <>

                <PageSection className={styles.layout__hero}>
                  <Hero
                    isMyProfile={isMyProfile}
                    profile={currentProfile}
                  >
                    {actions}
                  </Hero>
                </PageSection>

                {/* show message for not connected profiles */}
                {isNotConnectedProfile && (
                  <PageSection className={styles['layout__not-connected']}>
                    <p className={styles['not-connected-text']}>
                      {sprintf(t('notConnectedText'), currentProfile?.firstName)}
                    </p>
                  </PageSection>
                )}

                {/* show `saved advice` for connected profiles */}
                {isConnectedProfile && (savedTips.results.length > 0) && (
                  <PageSection>
                    <Eyebrow>
                      {t('savedAdvice')}
                    </Eyebrow>
                    {savedTips.results.map((tip) => (
                      <AdviceCard
                        key={`saved-advice-${tip?.id || shortid()}`}
                        adviceCategory={tip.adviceCategory}
                        content={tip.content}
                        id={tip?.id}
                        icon={tip.icon}
                        isSaved
                        title={tip.title}
                        onUnsave={() => savedTips.onUnsaveTip(tip?.id || 0)}
                      />
                    ))}
                    <Pagination
                      isVisible={savedTips.areMorePages}
                      onClick={savedTips.onGetMore}
                    />
                  </PageSection>
                )}

                {/* show `overview` for my profile and connected profiles */}
                {(isConnectedProfile || isMyProfile) && (currentProfile) && (
                  <PageSection className={styles.layout__overview}>
                    <Overview
                      isMyProfile={isMyProfile}
                      profile={currentProfile}
                      overview={overview}
                    />
                  </PageSection>
                )}

                {/* show `advice` for connected profiles */}
                {isConnectedProfile && (
                  <PageSection className={styles.layout__advice}>
                    <CardsList
                      cards={advices.map((advice) => (
                        <Card
                          route={formatRoute(URLS.advice, {
                            id: currentProfile?.id,
                            key: advice.key,
                          })}
                          title={advice.title}
                          media={(
                            <AdviceIcon
                              adviceCategory={advice.adviceCategory}
                              icon={advice.icon}
                            />
                          )}
                          mediaClassName={classNames(
                            styles.profile__advice__icon,
                            styles[advice.adviceCategory],
                          )}
                        />
                      ))}
                    />
                    {!isMyProfile && (
                      <div className={styles['friends-results__wrapper']}>
                        <Eyebrow>
                          {`${currentProfile?.firstName}${t('friendRelationships')}`}
                        </Eyebrow>
                        <SearchResults
                          className={styles['friends-results']}
                          profiles={friends?.results}
                          areMorePages={friends?.areMorePages}
                          onGetMore={() => undefined}
                        />
                      </div>
                    )}
                  </PageSection>
                )}

                {/* show `kit-list` for my profile */}
                {isMyProfile && (
                  <PageSection className={styles['layout__kit-list']}>
                    <KitList />
                  </PageSection>
                )}

                {/* show remove friend confirmation modal */}
                <Modal
                  isSmall
                  isVisible={confirmationModal.isVisible}
                  onClose={confirmationModal.onClose}
                >
                  <MenuMessage
                    color="secondary"
                    message={(
                      <p>
                        <strong>{t('removeFriendMenu.title')}</strong>
                        <br />
                        {sprintf(t('removeFriendMenu.message'), currentProfile?.firstName)}
                      </p>
                    )}
                  />
                  <MenuOptions color="secondary" options={confirmationModal.options} />
                </Modal>
              </>
            )
        }
      </div>
    </PageWrapper>
  );
};

export default ProfilePage;
