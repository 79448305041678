import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import ClickAwayListener from 'react-click-away-listener';
import propTypes from 'prop-types';
import React, { ReactElement, ReactNode } from 'react';

import { CHILDREN } from 'app_constants/propTypes';

import MenuMessage from './components/MenuMessage/MenuMessage';
import MenuOption, { IContextMenuOptionProps } from './components/MenuOption/MenuOption';
import MenuOptions from './components/MenuOptions/MenuOptions';

import styles from './ContextMenu.scss';

const ContextMenu = ({
  children,
  color,
  isVisible,
  message,
  onClose,
  options,
}: IContextMenuProps) : JSX.Element => {
  const { t } = useTranslation('generics');

  return (
    <ClickAwayListener
      onClickAway={onClose}
      className={styles['context-menu__platform']}
    >
      {children}
      <div
        className={classNames(
          [styles['context-menu__wrapper']],
          { [styles['is-visible']]: isVisible },
        )}
      >

        <button
          onClick={onClose}
          className={styles['context-menu__close-button']}
          type="button"
        >
          <span className={styles['context-menu__close-button__label']}>
            {t('close')}
          </span>
        </button>

        <div className={styles['context-menu']}>
          {message && <MenuMessage color={color} message={message} />}
          <MenuOptions color={color} options={options} />
        </div>

      </div>
    </ClickAwayListener>
  );
};

ContextMenu.propTypes = {
  children: CHILDREN,
  color: propTypes.string,
  isVisible: propTypes.bool,
  message: propTypes.node,
  onClose: propTypes.func.isRequired,
  options: propTypes.arrayOf(propTypes.shape(MenuOption.propTypes)).isRequired,
};

ContextMenu.defaultProps = {
  children: null,
  color: 'primary',
  isVisible: false,
  message: null,
};

interface IContextMenuProps {
  children: ReactElement,
  color: 'primary' | 'secondary',
  isVisible: boolean,
  message: ReactNode,
  onClose: () => void,
  options: IContextMenuOptionProps[],
}

export default ContextMenu;
