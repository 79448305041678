/* eslint-disable react/jsx-one-expression-per-line */
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import propTypes from 'prop-types';
import React from 'react';

import URLS from 'app_constants/urls';
import styles from './AppFooter.scss';

const FULL_YEAR = (new Date()).getFullYear();

const AppFooter = ({ isNoAuthLayout } : IAppFooterProps) : JSX.Element => {
  const { t } = useTranslation('appFooter');

  return (
    <footer className={classNames(
      [styles['app-footer']],
      { [styles['no-auth-footer']]: isNoAuthLayout },
    )}
    >

      <p className="u-align--center">
        &copy; {FULL_YEAR}&nbsp;
        <span className="u-registered">Simpli5</span>&nbsp;
        {t('rights')}
        &nbsp;|&nbsp;
        <Link href={URLS.terms} to={URLS.terms} target="_blank">{t('termsOfUse')}</Link>
        &nbsp;|&nbsp;
        <a href="https://www.simpli5.com/privacy-policy/" target="_blank" rel="noreferrer">{t('privacyPolicy')}</a>
      </p>

    </footer>
  );
};

AppFooter.propTypes = {
  isNoAuthLayout: propTypes.bool,
};

AppFooter.defaultProps = {
  isNoAuthLayout: false,
};

interface IAppFooterProps {
  isNoAuthLayout: boolean,
}

export default AppFooter;
