/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { ISignUpInvitationProps } from 'api/controllers/types';
import { SessionContext } from 'app_state/session/SessionProvider';
import { SIGN_UP_INVITATION_FORMAT } from 'app_constants/apiErrorCodes';
import api from 'api';
import ApiError from 'classes/errors/ApiError.class';
import useForm, { IErrors } from 'custom_hooks/useForm';
import useFormValidation from 'custom_hooks/useFormValidation';
import useNotification from 'custom_hooks/useNotification';
import URLS from 'app_constants/urls';

const useSignUpInvitation = <T>() : IUseSignUpInvitation<T> => {
  const { state } = useContext(SessionContext);
  const { isAuthenticated, loginEmail } = state;
  const { validator } = useFormValidation();
  const { addErrorNotification, addSuccessNotification } = useNotification();
  const history = useHistory();

  const handleSubmit = (values: ISignUpInvitationProps) => {
    const onError = (error: ApiError) => {
      if (error?.errorCode === SIGN_UP_INVITATION_FORMAT) {
        addErrorNotification(error.message.replace(/,/g, ' '));
      } else {
        addErrorNotification('signUpInvitationError');
      }
    };

    const onSuccess = () => {
      addSuccessNotification('signUpInvitationSuccess');
      history.push(URLS.login);
    };

    api.invites.signUpInvitation({ ...values }, onSuccess, onError);
  };

  const handleValidation = ({ firstName, lastName, email } : ISignUpInvitationProps) : IErrors => ({
    firstName: validator.validateName(firstName),
    lastName: validator.validateName(lastName),
    email: validator.validateRequired(email) || validator.validateEmail(email),
  });

  const {
    errors,
    onChangeField,
    onSubmitForm,
    isValid,
    values = { firstName: null, lastName: null, email: null },
  } = useForm<ISignUpInvitationProps>({ onSubmit: handleSubmit, onValidate: handleValidation });

  useEffect(() => {
    if (loginEmail) {
      onChangeField('email', loginEmail);
    }
  }, []);

  return {
    errors,
    isAuthenticated,
    onChangeField,
    onSubmitForm,
    isValid,
    values,
  };
};

export interface IUseSignUpInvitation<T> {
  errors: IErrors,
  isAuthenticated: boolean,
  onChangeField: (name: string, value: string | boolean) => void,
  onSubmitForm: (event?: React.FormEvent<HTMLFormElement>) => void,
  isValid: boolean,
  values: ISignUpInvitationProps | { firstName: null; lastName: null; email: null; },
}

export default useSignUpInvitation;
