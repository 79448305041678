import { isNil } from 'lodash';
import { Redirect, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';

import Button from 'generics/Button/Button';
import DatePicker from 'generics/DatePicker/DatePicker';
import Form from 'generics/Form/Form';
import Input from 'generics/Input/Input';
import PageSection from 'generics/PageSection/PageSection';
import PageTitle from 'generics/PageTitle/PageTitle';
import PageWrapper from 'generics/PageWrapper/PageWrapper';
import Select from 'generics/Select/Select';
import TermsCheckbox from 'generics/TermsCheckbox/TermsCheckbox';
import URLS from 'app_constants/urls';
import usePronounPreferences from 'custom_hooks/usePronounPreferences';

import styles from './SignUp.scss';
import useSignUp from './useSignUp';

const SignUp = () : JSX.Element => {
  const { t } = useTranslation('signUp');
  const pronounOptions = usePronounPreferences();
  const location = useLocation();

  const {
    currentInvitation,
    emailApiError,
    errors,
    isValid,
    languages,
    onChangeField,
    onSubmitForm,
    values,
  } = useSignUp();

  if (!currentInvitation) {
    const path = {
      pathname: URLS.login,
      state: { from: location },
    };

    return <Redirect to={path} />;
  }

  return (
    <PageWrapper>
      <div className={styles.layout}>
        <Form autoComplete="off" onSubmit={onSubmitForm}>
          <PageSection>
            <PageTitle
              title={t('title')}
              actions={(
                <Button isSmall type="submit" disabled={!isValid || !!emailApiError} className={styles['mobile-button']}>
                  {t('form.btn')}
                </Button>
              )}
            />
          </PageSection>
          <PageSection>
            <Input
              error={isNil(values.firstName) ? null : errors.firstName}
              id="firstName"
              label={t('form.firstName')}
              name="firstName"
              onChange={(e) => onChangeField('firstName', e.currentTarget.value)}
              type="text"
              value={values.firstName}
            />
            <Input
              error={isNil(values.lastName) ? null : errors.lastName}
              id="lastName"
              label={t('form.lastName')}
              name="lastName"
              onChange={(e) => onChangeField('lastName', e.currentTarget.value)}
              type="text"
              value={values.lastName}
            />
            <Input
              disabled
              error={isNil(values.email) ? null : (errors.email || emailApiError)}
              id="email"
              label={t('form.email')}
              name="email"
              value={values.email}
            />
            <Select
              label={t('form.pronouns.label')}
              placeholder={t('form.pronouns.placeholder')}
              id="genderPronounPreference"
              name="genderPronounPreference"
              required
              onChange={(e) => onChangeField('genderPronounPreference', e.currentTarget.value)}
              options={pronounOptions}
              value={values.genderPronounPreference || ''}
            />
            <DatePicker
              error={isNil(values.birthday) ? null : errors.birthday}
              id="birthday"
              label={ReactHtmlParser(t('form.birthday'))}
              onChange={(dateStr) => onChangeField('birthday', dateStr || '')}
              tooltip="restrictedAge"
              value={values.birthday || ''}
            />
            <Input
              autoComplete="off"
              error={isNil(values.password) ? null : errors.password}
              id="password"
              label={t('form.password')}
              name="password"
              onChange={(e) => onChangeField('password', e.currentTarget.value)}
              type="password"
              value={values.password || ''}
            />
            <Input
              autoComplete="off"
              error={isNil(values.passwordConfirmation) ? null : errors.passwordConfirmation}
              id="passwordConfirmation"
              label={t('form.passwordConfirmation')}
              name="passwordConfirmation"
              onChange={(e) => onChangeField('passwordConfirmation', e.currentTarget.value)}
              type="password"
              value={values.passwordConfirmation || ''}
            />
            <Select
              label={t('form.language.label')}
              placeholder={t('form.language.placeholder')}
              id="language"
              name="language"
              required
              onChange={(e) => onChangeField('language', e.currentTarget.value)}
              options={languages?.map((language) => ({
                value: language,
                text: language,
              }))}
              value={values.language || ''}
            />
            <TermsCheckbox
              checked={!!values.agreedToTerms}
              error={isNil(values.agreedToTerms) ? null : errors.agreedToTerms}
              id="agreedToTerms"
              name="agreedToTerms"
              onChange={(e) => onChangeField('agreedToTerms', e.currentTarget.checked)}
            />
            <Button type="submit" disabled={!isValid || !!emailApiError} className={styles['desktop-button']}>
              {t('form.btn')}
            </Button>
          </PageSection>
        </Form>
      </div>
    </PageWrapper>
  );
};

export default SignUp;
