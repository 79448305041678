import { Dispatch } from 'react';

import { IAccount } from 'classes/Profile/types';
import { ILoginProps } from 'api/controllers/types';
import { ISessionAction } from 'app_state/types';
import api from 'api';
import ApiError from 'classes/errors/ApiError.class';
import history from 'utils/history';
import Profile from 'classes/Profile/Profile.class';
import sessionActionTypes from 'app_state/session/actionTypes';
import URLS from 'app_constants/urls';

import fetchContent from './fetchContent';

const loginFailure = (error: ApiError) : ISessionAction => ({
  payload: error,
  type: sessionActionTypes.FETCH_LOGIN_FAILURE,
});

const loginRequest = () : ISessionAction => ({
  payload: null,
  type: sessionActionTypes.FETCH_LOGIN_REQUEST,
});

const loginSuccess = (payload: Profile) : ISessionAction => ({
  payload,
  type: sessionActionTypes.FETCH_LOGIN_SUCCESS,
});

export default (
  dispatch: Dispatch<ISessionAction>,
  values: ILoginProps,
  successCallback: () => void,
  errorCallback: (error: ApiError) => void,
) : void => {
  dispatch(loginRequest());

  const onSuccess = ({ user } : any) => {
    const { profiles, ...userDetails } = user;
    const profile = profiles.find(({ account }: IFilterProps) => account.isPersonal);
    const currentUser = new Profile({ ...profile, user: userDetails });

    successCallback();
    dispatch(loginSuccess(currentUser));
    fetchContent(dispatch);

    if (currentUser.assessmentScoresObj.isNull || currentUser.user.hasOngoingAssessment) {
      history.push(URLS.corporateAccount);
    } else {
      history.push(URLS.home);
    }
  };

  const onError = (error: ApiError) => {
    dispatch(loginFailure(error));
    errorCallback(error);
  };

  api.session.login(values, onSuccess, onError);
};

interface IFilterProps {
  account: IAccount,
}
