import { faUnlock, faUserSlash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isNil } from 'lodash';
import { useTranslation } from 'react-i18next';
import React, { useContext } from 'react';

import { SessionContext } from 'app_state/session/SessionProvider';
import Avatar from 'generics/Avatar/Avatar';
import Button from 'generics/Button/Button';
import Card from 'generics/Card/Card';
import CardsList from 'generics/CardsList/CardsList';
import DatePicker from 'components/generics/DatePicker/DatePicker';
import Form from 'generics/Form/Form';
import Input from 'generics/Input/Input';
import MenuMessage from 'generics/ContextMenu/components/MenuMessage/MenuMessage';
import MenuOptions from 'generics/ContextMenu/components/MenuOptions/MenuOptions';
import Modal from 'generics/Modal/Modal';
import PageSection from 'generics/PageSection/PageSection';
import PageTitle from 'generics/PageTitle/PageTitle';
import PageWrapper from 'generics/PageWrapper/PageWrapper';
import Select from 'generics/Select/Select';
import Textarea from 'generics/Textarea/Textarea';
import ToggleSwitch from 'generics/ToggleSwitch/ToggleSwitch';
import usePronounPreferences from 'custom_hooks/usePronounPreferences';

import PhotoEditor from './components/PhotoEditor/PhotoEditor';
import useEditProfile from './useEditProfile';
import styles from './EditProfile.scss';

const EditProfile = () : JSX.Element => {
  const { state } = useContext(SessionContext);
  const { currentUser } = state;
  const { t } = useTranslation('editProfile');
  const pronounOptions = usePronounPreferences();

  const {
    avatar,
    contextMenu,
    emailApiError,
    form,
    onChangePassword,
    onDeleteAccount,
    onGoBack,
  } = useEditProfile();

  const {
    errors,
    isSubmitDisabled,
    onChangeField,
    onSubmitForm,
    values,
  } = form;

  const contextMenuMessage = (
    <span>
      <strong>{contextMenu.title}</strong>
      <br />
      {contextMenu.message}
    </span>
  );

  return (
    <>
      <PageWrapper>
        <div className={styles.layout}>
          <Form onSubmit={onSubmitForm}>
            <PageSection>
              <PageTitle
                title={t('title')}
                onBackButtonClick={onGoBack}
                actions={(
                  <Button isSmall disabled={isSubmitDisabled} type="submit" className={styles['mobile-button']}>
                    {t('form.btn')}
                  </Button>
                )}
              />
            </PageSection>
            <PageSection>
              <PhotoEditor
                avatar={<Avatar path={avatar.path} alt={currentUser?.name || ''} />}
                label={t('form.avatar.label')}
                helperText={t('form.avatar.helperText')}
                onChange={avatar.onChange}
              />
              <Input
                error={isNil(values.firstName) ? null : errors.firstName}
                id="firstName"
                label={t('form.firstName.label')}
                name="firstName"
                onChange={(e) => onChangeField('firstName', e.currentTarget.value)}
                type="text"
                value={values.firstName || ''}
              />
              <Input
                error={isNil(values.lastName) ? null : errors.lastName}
                id="lastName"
                label={t('form.lastName.label')}
                name="lastName"
                onChange={(e) => onChangeField('lastName', e.currentTarget.value)}
                type="text"
                value={values.lastName || ''}
              />
              <Input
                error={isNil(values.email) ? null : (errors.email || emailApiError)}
                id="email"
                label={t('form.email.label')}
                name="email"
                onChange={(e) => onChangeField('email', e.currentTarget.value)}
                value={values.email || ''}
              />
              <Select
                label={t('form.pronouns.label')}
                placeholder={t('form.pronouns.placeholder')}
                id="genderPronounPreference"
                name="genderPronounPreference"
                onChange={(e) => onChangeField('genderPronounPreference', e.currentTarget.value)}
                options={pronounOptions}
                value={values.genderPronounPreference || ''}
              />
              <DatePicker
                error={isNil(values.birthday) ? null : errors.birthday}
                id="birthday"
                label={t('form.birthday.label')}
                onChange={(dateStr) => onChangeField('birthday', dateStr || '')}
                placeholder={t('form.birthday.placeholder')}
                tooltip="restrictedAge"
                value={values.birthday || ''}
              />
              <Textarea
                error={isNil(values.bio) ? null : errors.bio}
                id="bio"
                label={t('form.bio.label')}
                name="bio"
                onChange={(e) => onChangeField('bio', e.currentTarget.value)}
                placeholder={t('form.bio.placeholder')}
                rows={5}
                value={values.bio || ''}
              />
              <ToggleSwitch
                id="notifications"
                label={t('form.socialNotifications.label')}
                name="notifications"
                onChange={(e) => onChangeField('notifications', e.currentTarget.checked)}
                checked={!!values.notifications}
              />
              <Button disabled={isSubmitDisabled} type="submit" className={styles['desktop-button']}>
                {t('form.btn')}
              </Button>
            </PageSection>
          </Form>
          <PageSection>
            <CardsList
              cards={[
                <Card
                  media={<FontAwesomeIcon icon={faUnlock} />}
                  onClick={onChangePassword}
                  title={t('changePassword')}
                />,
                <Card
                  media={<FontAwesomeIcon icon={faUserSlash} />}
                  onClick={onDeleteAccount}
                  title={t('deleteAccount.label')}
                />,
              ]}
            />
          </PageSection>
        </div>
      </PageWrapper>

      <Modal
        isSmall
        isVisible={contextMenu.isVisible}
        onClose={contextMenu.onClose}
      >
        <MenuMessage color="secondary" message={contextMenuMessage} />
        <MenuOptions color="secondary" options={contextMenu.options} />
      </Modal>
    </>
  );
};

export default EditProfile;
