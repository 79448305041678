/* eslint-disable react-hooks/exhaustive-deps */
import { faTimes, faUserTimes } from '@fortawesome/pro-light-svg-icons';
import { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { IContextMenuOptionProps } from 'generics/ContextMenu/components/MenuOption/MenuOption';
import { SessionContext } from 'app_state/session/SessionProvider';
import Advice from 'classes/Advice/Advice.class';
import api from 'api';
import Profile from 'classes/Profile/Profile.class';
import URLS from 'app_constants/urls';
import useAddFriend from 'custom_hooks/useAddFriend';
import useGetProfile from 'custom_hooks/useGetProfile';
import useNotification from 'custom_hooks/useNotification';
import useSavedTips from 'custom_hooks/useSavedTips';

const useProfile = () : IUseProfile => {
  const { t } = useTranslation('profile');
  const { id } = useParams<paramsType>();
  const profileId = parseInt(id, 10);
  const history = useHistory();
  const { state } = useContext(SessionContext);
  const { currentUser, content } = state;
  const isMyProfile = (currentUser?.id === profileId);
  const { onAddFriend } = useAddFriend();
  const { currentProfile, onGetProfile, setCurrentProfile } = useGetProfile();
  const { addErrorNotification, addSuccessNotification, clearNotification } = useNotification();

  const {
    areMorePages,
    onFetchSavedTips,
    onGetMore,
    onUnsaveTip,
    savedTips,
  } = useSavedTips();

  const [isLoading, setIsLoading] = useState(true);
  const [overview, setOverview] = useState<string>('');
  const [advices, setAdvices] = useState<Advice[]>([]);
  const [isFriendRequestSent, setIsFriendRequestSent] = useState(false);
  const [isConfirmationModalVisible, setConfirmationModalVisibility] = useState(false);
  const [isMenuVisible, setMenuVisibility] = useState(false);
  const [isToggleShowFriendMenuVisible, setToggleShowFriendMenuVisibility] = useState(false);
  const [friends, setFriends] = useState<any>({});

  const fetchContent = () => {
    const onSuccess = ({ userOverview = '', dashboardModules = [] } : any) => {
      setOverview(userOverview);

      setAdvices(dashboardModules.map((module: any) => (
        new Advice({
          ...module,
        })
      )));

      setIsLoading(false);
    };

    const onError = () => {
      setIsLoading(false);
      history.push(URLS.home);
    };

    api.contentManager.getContent(
      { contentType: 'individual', profileId: currentProfile?.id },
      onSuccess,
      onError,
    );
  };

  const getFriends = () => {
    const onSuccess = ({ meta, profiles } : IProfilesProps) => {
      setFriends(
        {
          results: profiles.map((profile: any) => new Profile({ ...profile })),
          areMorePages: meta?.morePages,
          meta,
        },
      );
    };

    const onError = (error:any) => {
      console.log(error);
    };

    api.profiles.getFriendsOfFriend({ friendId: profileId }, onSuccess, onError);
  };

  useEffect(() => {
    if (isMyProfile) {
      setCurrentProfile(currentUser);
      setOverview(content.overview);
      setIsLoading(false);
    } else {
      onGetProfile();
      getFriends();
    }

    return () => clearNotification();
  }, [profileId]);

  useEffect(() => {
    if (currentProfile && !isMyProfile) {
      if (currentProfile?.isConnected) {
        fetchContent();
        onFetchSavedTips();
      } else {
        setIsLoading(false);
        setIsFriendRequestSent(currentProfile.isConnectionRequestPending);
      }
    }
  }, [currentProfile]);

  const handleAddFriend = () => {
    const onFinish = () => {
      setIsFriendRequestSent(true);
    };

    if (currentProfile?.id) {
      onAddFriend(currentProfile?.id, onFinish);
    }
  };

  const handleCloseConfirmationModal = () => setConfirmationModalVisibility(false);
  const handleShowConfirmationModal = () => {
    setConfirmationModalVisibility(true);
    setMenuVisibility(false);
  };

  const handleToggleFriendVisibility = () => {
    const friendVisibilityStatusAction = currentProfile?.friendOfFriendsHidden ? 'exclude' : 'include';

    const onSuccess = () => {
      const successNotification = friendVisibilityStatusAction === 'include' ? 'hideFriendSuccess' : 'showFriendSuccess';
      addSuccessNotification(successNotification, currentProfile?.firstName ?? '');
      setToggleShowFriendMenuVisibility(false);
      onGetProfile();
    };

    const onError = (error:any) => {
      console.log(error);
    };

    api.profiles.toggleFriendVisibility({
      friendId: profileId,
      visibilityAction: friendVisibilityStatusAction,
    }, onSuccess, onError);
  };

  const handleRemoveFriend = () => {
    const onSucces = () => {
      addSuccessNotification('removeFriendSuccess', currentProfile?.firstName ?? '');
      history.push(URLS.home);
    };

    const onError = () => {
      handleCloseConfirmationModal();
      addErrorNotification('removeFriendError', currentProfile?.firstName ?? '');
    };

    api.connectionRequests.removeFriend(currentProfile?.id, onSucces, onError);
  };

  const confirmationModalOptions = [
    {
      icon: faUserTimes,
      label: t('removeFriend'),
      onClick: () => handleRemoveFriend(),
    },
    {
      icon: faTimes,
      label: t('cancel'),
      onClick: handleCloseConfirmationModal,
    },
  ];

  const isConnectedProfile = !isMyProfile && !!(currentProfile?.isConnected);
  const isNotConnectedProfile = !isMyProfile && !!(currentProfile && !currentProfile.isConnected);

  return {
    advices,
    confirmationModal: {
      isVisible: isConfirmationModalVisible,
      onClose: handleCloseConfirmationModal,
      options: confirmationModalOptions,
    },
    currentProfile,
    friends,
    isConnectedProfile,
    isFriendRequestSent,
    isLoading,
    isMyProfile,
    isNotConnectedProfile,
    menu: {
      isVisible: isMenuVisible,
      setIsVisible: setMenuVisibility,
    },
    menuToggleShowFriend: {
      isVisible: isToggleShowFriendMenuVisible,
      setIsVisible: setToggleShowFriendMenuVisibility,
    },
    onAddFriend: handleAddFriend,
    onToggleFriendVisibility: handleToggleFriendVisibility,
    onRemoveFriend: handleShowConfirmationModal,
    overview,
    savedTips: {
      areMorePages,
      onGetMore,
      onUnsaveTip,
      results: savedTips,
    },
  };
};

interface IProfilesProps {
  meta: {
    morePages: boolean,
  },
  profiles: any[],
}

export interface IUseProfile {
  advices: Advice[],
  confirmationModal: {
    isVisible: boolean,
    onClose: () => void,
    options: IContextMenuOptionProps[],
  },
  currentProfile: Profile | null,
  friends: {
    results: [],
    areMorePages: boolean,
    meta: {
      totalObjects: number,
      morePages: boolean
    }
  } | null,
  isConnectedProfile: boolean,
  isFriendRequestSent: boolean,
  isLoading: boolean,
  isMyProfile: boolean,
  isNotConnectedProfile: boolean,
  menu: {
    isVisible: boolean,
    setIsVisible: (isVisible: boolean) => void,
  },
  menuToggleShowFriend: {
    isVisible: boolean,
    setIsVisible: (isVisible: boolean) => void,
  },
  onAddFriend: () => void,
  onToggleFriendVisibility: () => void,
  onRemoveFriend: () => void,
  overview: string,
  savedTips: {
    areMorePages: boolean,
    onGetMore: () => void,
    onUnsaveTip: (id: number) => void,
    results: Advice[],
  },
}

type paramsType = {
  id: string,
}

export default useProfile;
