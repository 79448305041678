import { Dispatch } from 'react';

import { IAccount } from 'classes/Profile/types';
import { ISessionAction } from 'app_state/types';
import { ISignUpProps } from 'api/controllers/types';
import api from 'api';
import ApiError from 'classes/errors/ApiError.class';
import history from 'utils/history';
import Profile from 'classes/Profile/Profile.class';
import sessionActionTypes from 'app_state/session/actionTypes';
import URLS from 'app_constants/urls';

const signUpFailure = (error: ApiError) : ISessionAction => ({
  payload: error,
  type: sessionActionTypes.FETCH_SIGN_UP_FAILURE,
});

const signUpRequest = () : ISessionAction => ({
  payload: null,
  type: sessionActionTypes.FETCH_SIGN_UP_REQUEST,
});

const signUpSuccess = (payload: Profile) : ISessionAction => ({
  payload,
  type: sessionActionTypes.FETCH_SIGN_UP_SUCCESS,
});

export default (
  dispatch: Dispatch<any>,
  values: ISignUpProps,
  successCallback: () => void,
  errorCallback: (error: ApiError) => void,
) : void => {
  dispatch(signUpRequest());

  const onSuccess = ({ user } : any) => {
    const { profiles, ...userDetails } = user;
    const profileData = profiles.find(({ account }: IFilterProps) => account.isPersonal);
    successCallback();
    const currentUser = new Profile({ ...profileData, user: userDetails });
    dispatch(signUpSuccess(currentUser));
    if (!currentUser.assessmentScoresObj.isNull && currentUser.user.hasActiveAssessment) {
      history.push(URLS.home);
    } else {
      history.push(URLS.corporateAccount);
    }
  };

  const onError = (error: ApiError) => {
    dispatch(signUpFailure(error));
    errorCallback(error);
  };

  api.users.signUp(values, onSuccess, onError);
};

interface IFilterProps {
  account: IAccount,
}
