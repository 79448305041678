export interface AppErrorProps {
  label?: string,
  message?: string,
}

/**
 * @class AppError
 * @description 5D Personals App Error
 * @extends Error
 * */
export default class AppError extends Error {
  label: string | undefined;

  constructor({ label, message } : AppErrorProps) {
    super(message);
    this.label = label;
  }
}
