/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { InvitesContext } from 'app_state/invites/InvitesProvider';
import { ISignUpProps } from 'api/controllers/types';
import { SessionContext } from 'app_state/session/SessionProvider';
import { USERS_CREATE_DUPLICATED_EMAIL } from 'app_constants/apiErrorCodes';
import api from 'api';
import ApiError from 'classes/errors/ApiError.class';
import fetchSignUp from 'app_state/session/actions/fetchSignUp';
import useForm, { IErrors } from 'custom_hooks/useForm';
import useFormValidation from 'custom_hooks/useFormValidation';
import useNotification from 'custom_hooks/useNotification';

const useSignUp = () : IUseSignUp => {
  const { t } = useTranslation('signUp');
  const { dispatch } = useContext(SessionContext);
  const { state } = useContext(InvitesContext);
  const { currentInvitation } = state;
  const { validator } = useFormValidation();
  const [emailApiError, setEmailApiError] = useState('');
  const [languages, setLanguages] = useState(['']);
  const { addErrorNotification, clearNotification } = useNotification();

  const defaultValues = {
    agreedToTerms: null,
    birthday: null,
    email: currentInvitation?.email,
    firstName: currentInvitation?.firstName,
    genderPronounPreference: null,
    invitationToken: currentInvitation?.token,
    language: languages[0],
    lastName: currentInvitation?.lastName,
    password: null,
    passwordConfirmation: null,
  };

  const handleSubmit = (values: ISignUpProps) => {
    const onError = (error: ApiError) => {
      if (error?.errorCode === USERS_CREATE_DUPLICATED_EMAIL) {
        setEmailApiError(t('emailExists'));
      } else {
        addErrorNotification('signUpApiError');
      }
    };
    const invitationToken = currentInvitation?.token || '';
    fetchSignUp(dispatch, { ...values, invitationToken }, clearNotification, onError);
  };

  const handleValidation = ({
    birthday,
    email,
    firstName,
    language,
    lastName,
    genderPronounPreference,
    password,
    passwordConfirmation,
    agreedToTerms,
  } : ISignUpProps) : IErrors => {
    if (emailApiError) {
      setEmailApiError('');
    }
    return {
      agreedToTerms: validator.validateRequired(agreedToTerms ? 'true' : ''),
      birthday: validator.validateRequired(birthday)
        || validator.validateDate(birthday)
        || validator.validateIsDate18orMoreYearsOld(birthday),
      email: validator.validateRequired(email) || validator.validateEmail(email),
      firstName: validator.validateName(firstName),
      genderPronounPreference: validator.validateRequired(genderPronounPreference),
      language: validator.validateRequired(language),
      lastName: validator.validateName(lastName),
      password: validator.validatePassword(password),
      passwordConfirmation: validator.validatePasswordConfirmation(password, passwordConfirmation),
    };
  };

  const {
    errors,
    onChangeField,
    onSubmitForm,
    isValid,
    values = defaultValues,
  } = useForm<ISignUpProps>({
    defaultValues,
    onSubmit: handleSubmit,
    onValidate: handleValidation,
  });

  const getLanguages = () => {
    const onSuccess = ({ languages: setupLanguages }: ILanguages) => {
      if (setupLanguages) {
        setLanguages(setupLanguages);
        onChangeField('language', setupLanguages[0]);
      }
    };

    const onError = (error: ApiError) => {
      console.log(error);
    };

    api.profiles.getAssessmentLanguages(null, onSuccess, onError);
  };

  useEffect(() => {
    getLanguages();
  }, []);

  return {
    currentInvitation,
    emailApiError,
    errors,
    isValid,
    languages,
    onChangeField,
    onSubmitForm,
    values,
  };
};

interface ILanguages {
  languages: Array<string>,
}

export interface IUseSignUp {
  currentInvitation: any,
  emailApiError: string,
  errors: IErrors,
  isValid: boolean,
  languages: Array<string>,
  onChangeField: (name: string, value: string | boolean) => void,
  onSubmitForm: (event?: React.FormEvent<HTMLFormElement> | undefined) => void,
  values: ISignUpProps |
  {
    agreedToTerms: null;
    birthday: null;
    email: string | undefined;
    firstName: string | undefined;
    genderPronounPreference: null;
    invitationToken: string | undefined;
    language: string;
    lastName: string | undefined;
    password: null;
    passwordConfirmation: null;
  },
}

export default useSignUp;
