import React, { useEffect, Dispatch, FC } from 'react';

import { IAssessmentAction, IAssessmentInitialState } from 'app_state/types';

import initialState from './initialState.json';
import reducers from './reducers';

interface IAssessmentContext {
  state: IAssessmentInitialState,
  dispatch: Dispatch<IAssessmentAction>,
}

const assessmentJSON = localStorage.getItem('assessment');
const localState = assessmentJSON && assessmentJSON !== 'undefined' ? JSON.parse(assessmentJSON) : null;

export const AssessmentContext = React.createContext<IAssessmentContext>(
  { state: initialState, dispatch: () => null },
);

export const AssessmentProvider: FC = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducers, localState || initialState);

  useEffect(() => {
    localStorage.setItem('invites', JSON.stringify(state));
  }, [state]);

  return (
    <AssessmentContext.Provider value={{ state, dispatch }}>
      {children}
    </AssessmentContext.Provider>
  );
};
