import { IAction, IReducers, IState } from 'app_state/types';

export default (reducers: IReducers) => (
  state: IState = {},
  action: IAction,
) : any => {
  const nextState: any = {};
  Object.keys(reducers).forEach(
    (key: string) => {
      nextState[key] = reducers[key](state[key], action);
    },
  );
  return nextState;
};
