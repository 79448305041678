/* eslint-disable no-nested-ternary */
import { faChevronRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatRoute } from 'react-router-named-routes';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import propTypes from 'prop-types';
import React from 'react';

import Kit from 'classes/Kit/Kit.class';
import URLS from 'app_constants/urls';

import styles from './Kit.scss';

const KitComponent = ({ kit }: IKitProps) : JSX.Element => {
  const { key, description, title, imageUrl, custom, customPayment, customShareReport } = kit;
  const { t } = useTranslation('kits');
  const history = useHistory();
  const goToKit = () => history.push(formatRoute(customShareReport ? URLS.shareReport : URLS.kit, { key }));

  return (
    <div
      className={classNames([styles.kit__wrapper], (customPayment || customShareReport) && [styles.kit__custom])}
      role="link"
      tabIndex={0}
      onClick={goToKit}
      onKeyDown={({ keyCode }) => keyCode === 13 && goToKit()}
    >
      <div className={styles.kit}>
        <h3 className={styles.kit__title}>
          {title}
        </h3>
        <p>{description}</p>
        <span className={classNames([styles.kit__link, (customPayment || customShareReport) && styles.kit__customLink])}>
          { custom || customPayment ? t('learnMore')
            : customShareReport ? t('seeShareInformationRequests')
              : t('viewKit')}
          <FontAwesomeIcon
            className={styles.kit__link__icon}
            icon={faChevronRight}
          />
        </span>
      </div>
      {(!customPayment && !customShareReport) && (
        <div
          className={classNames(
            [styles.kit__graphic],
            { [styles.custom]: custom },
          )}
          style={{ backgroundImage: `url(${imageUrl})` }}
          title={title}
        />
      )}
    </div>
  );
};

KitComponent.propTypes = {
  kit: propTypes.instanceOf(Kit).isRequired,
};

export interface IKitProps {
  kit: Kit
}

export default KitComponent;
