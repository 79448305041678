enum INVITATION_STATUS {
  accepted = 'accepted',
  declined = 'declined',
  expired = 'expired',
  none = 'none',
  pending = 'pending',
  preAccepted = 'pre-accepted',
}

export default INVITATION_STATUS;
