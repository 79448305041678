import { useTranslation } from 'react-i18next';
import React, { useContext } from 'react';
import classNames from 'classnames';
import propTypes from 'prop-types';

import { SessionContext } from 'app_state/session/SessionProvider';
import Kit from 'classes/Kit/Kit.class';

import KitComponent from './Kit';
import styles from './List.scss';

const List = ({ className, customPayment, customs, customShareReport }: IListProps) : JSX.Element => {
  const { t } = useTranslation('kits');
  const { state } = useContext(SessionContext);
  const { content: { kits } } = state;

  const populateCustomKits = () => {
    const customArray = [
      new Kit({ custom: true, title: t('the_model_1.title'), description: t('the_model_1.description'), key: t('the_model_1.key') }),
      new Kit({ custom: true, title: t('relationships_1.title'), description: t('relationships_1.description'), key: t('relationships_1.key') }),
      new Kit({ custom: true, title: t('personal_empowerment_1.title'), description: t('personal_empowerment_1.description'), key: t('personal_empowerment_1.key') }),
    ];

    return customArray.map((customKit) => (
      <li>
        <KitComponent kit={customKit} />
      </li>
    ));
  };

  const customPaymentKit = new Kit({ customPayment: true, title: t('customPayment.title'), description: t('customPayment.description'), key: t('customPayment.key') });
  const customShareReportKit = new Kit({ customShareReport: true, title: t('customShareReport.title'), description: t('customShareReport.description'), key: t('customShareReport.key') });

  return (
    <div className={classNames(styles.list__wrapper, className)}>
      <ul className={styles.list}>
        {!customs && kits.map((kit: Kit) => (
          <li key={kit.key}>
            <KitComponent kit={kit} />
          </li>
        ))}
        {customs && (
          populateCustomKits()
        )}
        {customPayment && (
          <li>
            <KitComponent kit={customPaymentKit} />
          </li>
        )}
        {customShareReport && (
          <li>
            <KitComponent kit={customShareReportKit} />
          </li>
        )}
      </ul>
    </div>
  );
};

List.propTypes = {
  className: propTypes.string,
  customs: propTypes.bool,
  customPayment: propTypes.bool,
};

List.defaultProps = {
  className: null,
  customs: false,
  customPayment: false,
};

interface IListProps {
  className?: string,
  customs?: boolean,
  customPayment?: boolean,
}

export default List;
