import { formatRoute } from 'react-router-named-routes';
import React, { useContext } from 'react';

import URLS from 'app_constants/urls';

import { SessionContext } from 'app_state/session/SessionProvider';
import api from 'api';
import Avatar from 'generics/Avatar/Avatar';
import Card from 'generics/Card/Card';
import CardsList from 'generics/CardsList/CardsList';
import Pagination from 'generics/Pagination/Pagination';
import Profile from 'classes/Profile/Profile.class';
import usePagination from 'custom_hooks/usePagination';

import styles from '../../Home.scss';

const Friends = () : JSX.Element => {
  const { state } = useContext(SessionContext);
  const { relationships } = state;

  const friends = usePagination({
    onRequest: api.profiles.getFriends,
    initialCollection: relationships.friends,
  });

  const cards = friends.results.map((profile) => {
    const { name, assessmentScores, avatar, id } = new Profile({ ...profile });

    return (
      <Card
        route={formatRoute(URLS.profile, { id })}
        title={name}
        media={<Avatar path={avatar} alt={name} />}
        assessmentScores={assessmentScores}
        showBeachBall
      />
    );
  });

  return (
    <>
      <CardsList
        className={styles['relationship-cards']}
        cards={cards}
      />
      <Pagination
        isVisible={friends.areMorePages}
        onClick={friends.onGetMoreResults}
      />
    </>
  );
};

export default Friends;
