import {
  ISettingsResult,
  IShareReport,
} from './types';

/**
   * @description Settings for controller
   */
export default {
  getShareReportRequests: {
    /**
       * @description Get request settings (Get All Countries)
       * @return {object} Request Settings
       */
    settings() : ISettingsResult {
      return {
        endpoint: '/personal/tokens/share_apgi_report_list',
        method: 'GET',
      };
    },
  },
  shareReport: {
    /**
       * @description Post request settings (Send Stripe Report Payment)
       * @param  {object} options
       * @param  {string} options.stripeToken - Stripe transaction token
       * @param  {string} options.stripeEmail - Stripe transaction email
       * @return {object} Request Settings
       */
    settings(
      { id } : IShareReport,
    ) : ISettingsResult {
      return {
        endpoint: '/personal/tokens/share_apgi_org',
        body: {
          id,
        },
        method: 'POST',
      };
    },
  },
};
