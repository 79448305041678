import { Dispatch } from 'react';

import { IList, ISessionAction } from 'app_state/types';
import api from 'api';
import ApiError from 'classes/errors/ApiError.class';
import initialState from 'app_state/session/initialState.json';
import sessionActionTypes from 'app_state/session/actionTypes';

const fetchSentInvitesFailure = (error: ApiError) : ISessionAction => ({
  payload: error,
  type: sessionActionTypes.FETCH_SENT_INVITES_FAILURE,
});

const fetchSentInvitesRequest = () : ISessionAction => ({
  payload: null,
  type: sessionActionTypes.FETCH_SENT_INVITES_REQUEST,
});

const fetchSentInvitesSuccess = (payload: IList) : ISessionAction => ({
  payload,
  type: sessionActionTypes.FETCH_SENT_INVITES_SUCCESS,
});

/**
 * @description: Gets the first page of Sent Invitations
 */
export default (
  dispatch: Dispatch<ISessionAction>,
) : void => {
  dispatch(fetchSentInvitesRequest());

  const onError = (error: ApiError) => {
    dispatch(fetchSentInvitesFailure(error));
  };

  const onSuccess = (response: any) => {
    const meta = response.meta ?? initialState.relationships.sentInvites.meta;
    const collection = response.invites ?? initialState.relationships.sentInvites.collection;

    dispatch(fetchSentInvitesSuccess({ meta, collection }));
  };

  api.invites.getSentInvites({ page: 1 }, onSuccess, onError);
};
