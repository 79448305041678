import combineReducers from 'utils/combineReducers';
import friends from './friends';
import receivedFriendRequests from './receivedFriendRequests';
import sentFriendRequests from './sentFriendRequests';
import sentInvites from './sentInvites';

export default combineReducers({
  friends,
  receivedFriendRequests,
  sentFriendRequests,
  sentInvites,
});
