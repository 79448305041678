import React, { useEffect, Dispatch, useReducer, FC } from 'react';

import { INotificationsAction, INotificationsInitialState } from 'app_state/types';
import api from 'api';

import initialState from './initialState.json';
import notificationsReducer from './reducers';

interface INotificationsContext {
  state: INotificationsInitialState,
  dispatch: Dispatch<INotificationsAction>,
}

const notificationsJSON = localStorage.getItem('notifications');
const localState = notificationsJSON && notificationsJSON !== 'undefined' ? JSON.parse(notificationsJSON) : null;

export const NotificationsContext = React.createContext<INotificationsContext>(
  { state: initialState, dispatch: () => null },
);

export const NotificationsProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(notificationsReducer, localState || initialState);

  useEffect(() => {
    localStorage.setItem('notifications', JSON.stringify(state));
    api.notificationDispatch = dispatch;
  }, [state]);

  return (
    <NotificationsContext.Provider value={{ state, dispatch }}>
      {children}
    </NotificationsContext.Provider>
  );
};
