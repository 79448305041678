import { isNil } from 'lodash';
import { useTranslation } from 'react-i18next';
import propTypes from 'prop-types';
import React from 'react';

import Button from 'generics/Button/Button';
import Form from 'generics/Form/Form';
import Input from 'generics/Input/Input';

import styles from './TradeshowForm.scss';
import useTradeshowForm from './useTradeshowForm';

const TradeshowForm = ({ onFinish } : ITradeshowFormProps) : JSX.Element => {
  const { t } = useTranslation('tradeshow');

  const {
    errors,
    isValid,
    onChangeField,
    onSubmitForm,
    values,
  } = useTradeshowForm(onFinish);

  return (
    <>
      <div className={styles.intro}>
        <strong>{t('form.description')}</strong>
      </div>
      <Form onSubmit={onSubmitForm}>
        <Input
          error={isNil(values.tradeshowCode) ? null : errors.tradeshowCode}
          id="tradeshowCode"
          label={t('form.tradeshowCode')}
          name="tradeshowCode"
          onChange={(e) => onChangeField('tradeshowCode', e.currentTarget.value)}
          type="text"
          value={values.tradeshowCode || ''}
        />
        <div className={styles['download-button__wrapper']}>
          <Button disabled={!isValid} type="submit">{t('downloadButton')}</Button>
        </div>
      </Form>
    </>
  );
};

interface ITradeshowFormProps {
  onFinish?: (validCode: string | undefined) => void | null,
}

TradeshowForm.propTypes = {
  onFinish: propTypes.func,
};

TradeshowForm.defaultProps = {
  onFinish: null,
};

export default TradeshowForm;
