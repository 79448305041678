enum actionTypes {
  ACCEPT_INVITATION = 'INVITES/ACCEPT_INVITATION',
  FETCH_DECLINE_INVITATION_FAILURE = 'INVITES/FETCH_DECLINE_INVITATION_FAILURE',
  FETCH_DECLINE_INVITATION_REQUEST = 'INVITES/FETCH_DECLINE_INVITATION_REQUEST',
  FETCH_DECLINE_INVITATION_SUCCESS = 'INVITES/FETCH_DECLINE_INVITATION_SUCCESS',
  FETCH_INVITATION_FAILURE = 'INVITES/FETCH_INVITATION_FAILURE',
  FETCH_INVITATION_REQUEST = 'INVITES/FETCH_INVITATION_REQUEST',
  FETCH_INVITATION_SUCCESS = 'INVITES/FETCH_INVITATION_SUCCESS',
}

export default actionTypes;
