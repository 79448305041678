import { Dispatch } from 'react';

import { ApiError } from 'classes/errors';
import { IInvitationProps } from 'api/controllers/types';
import { IInvitesAction } from 'app_state/types';
import api from 'api';
import history from 'utils/history';
import Invitation from 'classes/Invitation/Invitation.class';
import sessionActionTypes from 'app_state/invites/actionTypes';
import URLS from 'app_constants/urls';

const fetchInvitationFailure = (error: typeof ApiError) : IInvitesAction => ({
  payload: error,
  type: sessionActionTypes.FETCH_INVITATION_FAILURE,
});

const fetchInvitationRequest = () : IInvitesAction => ({
  payload: null,
  type: sessionActionTypes.FETCH_INVITATION_REQUEST,
});

const fetchInvitationSuccess = (payload: Invitation) : IInvitesAction => ({
  payload,
  type: sessionActionTypes.FETCH_INVITATION_SUCCESS,
});

export default (
  dispatch: Dispatch<any>,
  values: IInvitationProps,
  errorCallback: () => void,
) : void => {
  const { token } = values;

  dispatch(fetchInvitationRequest());

  const onSuccess = ({ invite } : any) => {
    if (!invite.isInvitationValid) {
      history.push(URLS.login);
      errorCallback();
    } else {
      dispatch(fetchInvitationSuccess({ ...invite, token }));
      history.push(URLS.acceptInvitation);
    }
  };

  const onError = (error: typeof ApiError) => {
    dispatch(fetchInvitationFailure(error));
    errorCallback();
    history.push(URLS.login);
  };

  api.invites.getInvitation(values, onSuccess, onError);
};
