import { SessionContext } from 'app_state/session/SessionProvider';
import { useContext } from 'react';

import { FRIEND_REQUEST_ALREADY_EXISTS } from 'app_constants/apiErrorCodes';
import api from 'api';
import ApiError from 'classes/errors/ApiError.class';
import fetchRelationships from 'app_state/session/actions/fetchRelationships';
import useNotification from 'custom_hooks/useNotification';

const useAddFriend = () : IUseAddFriend => {
  const { dispatch: sessionDispatch } = useContext(SessionContext);
  const { addErrorNotification } = useNotification();

  const handleAddFriend = (profileId: number, onFinish?: () => void) => {
    const onSuccess = () => {
      if (onFinish) {
        onFinish();
      }
      fetchRelationships(sessionDispatch);
    };

    const onError = (error: ApiError) => {
      if (error.errorCode === FRIEND_REQUEST_ALREADY_EXISTS) {
        onSuccess();
      } else {
        addErrorNotification('generalErrorFriendRequest');
      }
    };

    api.connectionRequests.sendConnectionRequest(profileId, onSuccess, onError);
  };

  return {
    onAddFriend: handleAddFriend,
  };
};

export interface IUseAddFriend {
  onAddFriend: (profileId: number, onFinish? : () => void) => void
}

export default useAddFriend;
