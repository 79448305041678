import ENERGY_LABELS from './energyLabels';

const ENERGIES: IEnergy[] = [
  {
    abbreviation: 'R',
    text: ENERGY_LABELS[0],
  },
  {
    abbreviation: 'R',
    text: ENERGY_LABELS[0],
    threshold: 0,
  },
  {
    abbreviation: 'D',
    text: ENERGY_LABELS[1],
    threshold: 2,
  },
  {
    abbreviation: 'D+',
    text: `${ENERGY_LABELS[1]}+`,
    threshold: 5,
  },
  {
    abbreviation: 'E',
    text: ENERGY_LABELS[2],
    threshold: 9,
  },
  {
    abbreviation: 'E+',
    text: `${ENERGY_LABELS[2]}+`,
    threshold: 12,
  },
  {
    abbreviation: 'A',
    text: ENERGY_LABELS[3],
    threshold: 14,
  },
];

export default ENERGIES;

export interface IEnergy {
  abbreviation: string | null,
  text: string | null,
  threshold?: number,
}
