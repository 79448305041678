import { isNil } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import React from 'react';

import Button from 'generics/Button/Button';
import Form from 'generics/Form/Form';
import Input from 'generics/Input/Input';
import PageSection from 'generics/PageSection/PageSection';
import PageWrapper from 'generics/PageWrapper/PageWrapper';
import saveLoginEmail from 'app_state/session/actions/saveLoginEmail';
import URLS from 'app_constants/urls';

import useLogin from './useLogin';
import styles from './Login.scss';

const Login = () : JSX.Element => {
  const { t } = useTranslation('login');
  const history = useHistory();

  const {
    dispatch,
    errors,
    isAuthenticated,
    isValid,
    onChangeField,
    onSubmitForm,
    values,
  } = useLogin();

  if (isAuthenticated) {
    history.push(URLS.home);
  }

  const handleSignUpInvitationRedirect = () => {
    saveLoginEmail(dispatch, values.email || '');
  };

  return (
    <PageWrapper>
      <div
        className={classNames(
          [styles.layout],
          [styles['no-auth']],
        )}
      >
        <PageSection>

          <div
            className={classNames(
              [styles.intro],
              [styles['no-auth']],
            )}
          >
            <h1>{t('title')}</h1>
            <p>{t('description')}</p>
          </div>

          <Form onSubmit={onSubmitForm}>

            <Input
              error={isNil(values.email) ? null : errors.email}
              id="email"
              isBlockLabel
              label={t('form.email')}
              name="email"
              onChange={(e) => onChangeField('email', e.currentTarget.value)}
              value={values.email || ''}
            />

            <Input
              error={isNil(values.password) ? null : errors.password}
              id="password"
              isBlockLabel
              label={t('form.password')}
              name="password"
              onChange={(e) => onChangeField('password', e.currentTarget.value)}
              type="password"
              value={values.password || ''}
            />

            <Button
              className={styles.submit}
              disabled={!isValid}
              type="submit"
              isBlockAtTablet
              isRounded
            >
              {t('form.btn')}
            </Button>

            <Button
              className={styles.callToAction}
              isBlockAtTablet
              isRounded
              onClick={() => handleSignUpInvitationRedirect()}
            >
              {t('callToActionBtn')}
            </Button>
          </Form>

          <p className={styles['forgot-password']}>
            <Button
              isLink
              onClick={() => history.push(URLS.forgotPassword)}
            >
              {t('forgotPassword')}
            </Button>
          </p>

        </PageSection>
      </div>
    </PageWrapper>
  );
};

export default Login;
