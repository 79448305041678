import { Dispatch } from 'react';

import { ApiError } from 'classes/errors';
import { IAccount } from 'classes/Profile/types';
import { ISessionAction } from 'app_state/types';
import api from 'api';
import sessionActionTypes from 'app_state/session/actionTypes';

const fetchAccountFailure = (error: typeof ApiError) : ISessionAction => ({
  payload: error,
  type: sessionActionTypes.FETCH_ACCOUNT_FAILURE,
});

const fetchAccountRequest = () : ISessionAction => ({
  payload: null,
  type: sessionActionTypes.FETCH_ACCOUNT_REQUEST,
});

const fetchAccountSuccess = (payload: IAccount) : ISessionAction => ({
  payload,
  type: sessionActionTypes.FETCH_ACCOUNT_SUCCESS,
});

export default (
  dispatch: Dispatch<ISessionAction>,
) : void => {
  dispatch(fetchAccountRequest());

  const onSuccess = ({ account }: any) => {
    dispatch(fetchAccountSuccess(account));
  };

  const onError = (error: typeof ApiError) => {
    dispatch(fetchAccountFailure(error));
  };

  api.accounts.getAccount(null, onSuccess, onError);
};
