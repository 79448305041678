import React, { ReactNode, useState } from 'react';
import classNames from 'classnames';
import ClickAwayListener from 'react-click-away-listener';
import propTypes from 'prop-types';
import Content from 'generics/Content/Content';
import { CHILDREN } from 'app_constants/propTypes';

import styles from './Tooltip.scss';

const defaultAlignment = 'center';

const Tooltip = ({
  alignment = defaultAlignment,
  children,
  className,
  showOnHover,
  tooltip,
}: ITooltipProps) : JSX.Element => {
  const alignmentClass = ((a: string) => {
    switch (a) {
      case 'right':
        return styles['is-aligned-right'];
        break;
      case 'left':
        return styles['is-aligned-left'];
        break;
      case 'center':
      default:
        return styles['is-aligned-center'];
    }
  })(alignment);
  const [isVisible, setVisibility] = useState(false);

  return (
    <ClickAwayListener
      className={classNames(
        alignmentClass,
        className,
        styles.tooltip__wrapper,
        { [styles['is-visible']]: isVisible },
      )}
      onClickAway={() => setVisibility(false)}
    >
      <span
        onClick={!showOnHover ? () => setVisibility(!isVisible) : undefined}
        onKeyDown={({ keyCode }) => keyCode === 13 && setVisibility(!isVisible)}
        tabIndex={0}
        role="button"
      >
        {children}
      </span>
      <Content
        className={styles.tooltip}
        content={tooltip}
        onClick={() => setVisibility(false)}
        onKeyDown={({ keyCode }: {keyCode: number}) => keyCode === 13 && setVisibility(false)}
        tabIndex={-1}
      />
    </ClickAwayListener>
  );
};

Tooltip.propTypes = {
  alignment: propTypes.oneOf(['center', 'left', 'right']),
  children: CHILDREN,
  className: propTypes.string,
  showOnHover: propTypes.bool,
  tooltip: propTypes.string,
};

Tooltip.defaultProps = {
  alignment: defaultAlignment,
  children: null,
  className: null,
  showOnHover: false,
  tooltip: null,
};

interface ITooltipProps {
  alignment: 'center' | 'left' | 'right',
  children: ReactNode,
  className: string,
  showOnHover: boolean,
  tooltip: string,
}

export default Tooltip;
