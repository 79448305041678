import { merge } from 'lodash';
import INVITATION_STATUS from 'app_constants/invitationStatus';
import INVITATION_TYPES from 'app_constants/invitationTypes';

export default class Invitation {
  id: number;

  accountId: number;

  createdAt: string;

  email: string;

  firstName: string;

  isInvitationValid: boolean;

  lastName: string;

  status: INVITATION_STATUS;

  token?: string;

  types: INVITATION_TYPES;

  errors?: string[];

  constructor(options = {}) {
    this.accountId = 0;
    this.createdAt = '';
    this.email = '';
    this.firstName = '';
    this.id = 0;
    this.isInvitationValid = false;
    this.lastName = '';
    this.status = INVITATION_STATUS.none;
    this.types = INVITATION_TYPES.none;

    merge(this, options);
  }

  get name() : string {
    return `${this.firstName} ${this.lastName}`;
  }
}
