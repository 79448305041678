/* eslint-disable react-hooks/exhaustive-deps */
import { faCheck, faDownload } from '@fortawesome/pro-light-svg-icons';
import { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { IContextMenuOptionProps } from 'generics/ContextMenu/components/MenuOption/MenuOption';
import { SessionContext } from 'app_state/session/SessionProvider';
import api from 'api';
import Kit from 'classes/Kit/Kit.class';
import Profile from 'classes/Profile/Profile.class';
import saveFile from 'utils/saveFile';
import useEnergyMap, { IUseEnergyMap } from 'generics/EnergyMap/useEnergyMap';
import useNotification from 'custom_hooks/useNotification';
import URLS from 'app_constants/urls';

const useKit = () : IUseKit => {
  const { clearNotification } = useNotification();
  const { key } = useParams<IParams>();
  const { state } = useContext(SessionContext);
  const { currentUser } = state;
  const { t } = useTranslation('kits');
  const energyMapUtils = useEnergyMap(currentUser || new Profile());
  const history = useHistory();
  const { outerHTML, getCSSFromSVG } = energyMapUtils;
  const [blobForDownload, setBlobForDownload] = useState({ data: {}, fileName: '' });
  const [isConfirmationDownloadModalVisible, setIsConfirmationDownloadModalVisible] = useState(false);
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);
  const [isDownloadReportOptionsVisible, setIsDownloadReportOptionsVisible] = useState(false);
  const [isLoadingReportStatus, setIsLoadingReportStatus] = useState(true);
  const [isPaymentModalVisible, setIsPaymentModalVisible] = useState(false);
  const [isReportPaid, setIsReportPaid] = useState(false);
  const [isTradeshowModalVisible, setIsTradeshowModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [downloadedOnce, setDownloadedOnce] = useState(false);
  const [kit, setKit] = useState<Kit | null>(null);

  /* Custom Kits keys and routes utilities */
  const { PUBLIC_PATH, NODE_ENV } = process.env;
  const imagesRoot = `${window.location.origin}${PUBLIC_PATH}/kit-images/`;
  const reportKey = NODE_ENV === 'test' ? 'report_1' : t('customPayment.key');
  const relationshipsKey = NODE_ENV === 'test' ? 'relation_1' : t('relationships_1.key');

  const populateRelationshipsKitContent = (content:any) => {
    const decodersImg = `<figure style="background-image: url('${imagesRoot}/decoders.webp'); margin-left: 32.5%"></figure>`;
    const cluesImg = `<figure style="background-image: url('${imagesRoot}/clues.webp')"></figure>`;
    let newContent = content.replace('<figure decoders></figure>', decodersImg);
    newContent = newContent.replace('<figure clues></figure>', cluesImg);

    return newContent;
  };
  /* Custom Kits utilities end */

  const getReportPaymentStatus = () => {
    const onSuccess = (response:any) => {
      if (response) {
        setIsReportPaid(response.apgiReportPaid);
      }
      setIsLoadingReportStatus(false);
    };

    const onError = (error:any) => {
      console.log(error);
      setIsLoadingReportStatus(false);
    };

    api.charges.payAPGIReport(null, onSuccess, onError);
  };

  const onSuccessDownload = (data: typeof Object, fileName: string) => {
    clearNotification();
    setBlobForDownload({ data, fileName });
    setIsConfirmationDownloadModalVisible(true);
    setIsDownloadLoading(false);
  };

  const onConfirmDownload = (data:any, fileName: string) => {
    saveFile(data, fileName);
    setDownloadedOnce(true);
  };

  const contextMenuOptions = [
    {
      icon: downloadedOnce ? faCheck : faDownload,
      label: downloadedOnce ? 'Done' : 'Download',
      onClick: downloadedOnce ? () => history.push(URLS.login)
        : () => onConfirmDownload(blobForDownload.data, blobForDownload.fileName),
    },
  ];

  const handleDownloadReport = (validCode = '') => {
    setIsDownloadLoading(true);

    const css = getCSSFromSVG();

    api.pdfs.downloadOverviewPDF({
      css,
      id: currentUser?.id,
      name: currentUser?.name,
      svg: outerHTML(),
      validCode,
    }, onSuccessDownload);
  };

  const onSuccess = ({ documents }: any) => {
    if (documents) {
      const responseKit = documents[0];

      const content = responseKit.matches.reduce(
        (result: string, { content: newContent, title } : any) : string => {
          const newTitle = title ? `<h3>${title}</h3>` : '';
          return result + newTitle + newContent;
        }, '');

      setKit(
        new Kit({
          key,
          title: responseKit.title,
          content,
        }),
      );
    } else {
      history.push(URLS.home);
    }
    setIsLoading(false);
  };

  const onError = () => {
    setIsLoading(false);
    history.push(URLS.home);
  };

  useEffect(() => {
    setIsLoading(true);
    if (key === reportKey) {
      getReportPaymentStatus();
      setKit(
        new Kit({
          key: t('customPayment.key'),
          title: t('customPayment.title'),
          content: t('customPayment.content'),
          customPayment: true,
        }),
      );
      setIsLoading(false);
    } else if (t(`${key}.key`) === key) {
      let content = t(`${key}.content`);
      if (key === relationshipsKey) {
        content = populateRelationshipsKitContent(content);
      }
      setKit(
        new Kit({
          key: t(`${key}.key`),
          title: t(`${key}.title`),
          content,
        }),
      );
      setIsLoading(false);
    } else {
      api.contentManager.getContent({ contentType: 'individual', moduleKey: key }, onSuccess, onError);
    }

    return () => clearNotification();
  }, [key]);

  return {
    contextMenu: {
      isVisible: isConfirmationDownloadModalVisible,
      message: !downloadedOnce ? t('download.confirmDownload') : t('download.successDownload'),
      onClose: () => setIsConfirmationDownloadModalVisible(false),
      options: contextMenuOptions,
      title: !downloadedOnce ? t('download.confirmTitle') : t('download.successTitle'),
    },
    energyMapUtils,
    handleDownloadReport,
    isConfirmationDownloadModalVisible,
    isDownloadLoading,
    isDownloadReportOptionsVisible,
    isLoading,
    isLoadingReportStatus,
    isPaymentModalVisible,
    isReportPaid,
    isTradeshowModalVisible,
    kit,
    populateRelationshipsKitContent,
    setIsConfirmationDownloadModalVisible,
    setIsPaymentModalVisible,
    setIsDownloadLoading,
    setIsDownloadReportOptionsVisible,
    setIsTradeshowModalVisible,
  };
};

export interface IContextMenu {
  isVisible: boolean,
  message: string,
  onClose: () => void,
  options: IContextMenuOptionProps[],
  title: string,
}

interface IUseKit {
  contextMenu: IContextMenu,
  energyMapUtils: IUseEnergyMap,
  handleDownloadReport: (validCode?: string | undefined) => void,
  isConfirmationDownloadModalVisible: boolean,
  isDownloadLoading: boolean,
  isDownloadReportOptionsVisible: boolean,
  isLoading: boolean,
  isLoadingReportStatus: boolean,
  isPaymentModalVisible: boolean,
  isReportPaid: boolean,
  isTradeshowModalVisible: boolean,
  kit: Kit | null,
  populateRelationshipsKitContent: (content: string) => string | undefined,
  setIsConfirmationDownloadModalVisible: (visible: boolean) => void,
  setIsPaymentModalVisible: (visible: boolean) => void,
  setIsDownloadLoading: (visible: boolean) => void,
  setIsDownloadReportOptionsVisible: (visible: boolean) => void,
  setIsTradeshowModalVisible: (visible: boolean) => void,
}

export default useKit;

interface IParams {
  key: string,
  custom: string
}
