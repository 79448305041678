import { saveAs } from 'file-saver';

/**
 * @description Saves a file
 * @param { object } data
 * @param { string } fileName
 * @param { function } onFinish
 */
const saveFile = (
  data: typeof Object, fileName: string,
): void => {
  saveAs(data, fileName);
};

export default saveFile;
