import { sprintf } from 'sprintf-js';
import { useTranslation } from 'react-i18next';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';

import Button from 'generics/Button/Button';
import LoadingAnimation from 'generics/LoadingAnimation/LoadingAnimation';
import Modal from 'generics/Modal/Modal';

import styles from './Assessment.scss';
import WordPair from './components/WordPair';
import useAssessment from './useAssesment';

const AssessmentPage = () : JSX.Element => {
  const { t } = useTranslation('assessment');
  const {
    currentQuestion,
    currentUser,
    isCompleted,
    isLoading,
    onAnswer,
    onFinish,
  } = useAssessment();

  return (
    <div className={styles.assessment__layout}>
      <div className={styles.assessment__legal}>
        {ReactHtmlParser(sprintf(t('copyRight'), (new Date()).getFullYear()))}
      </div>
      <main>
        <div>
          <p>
            {sprintf(t('wordPairDescription1'), currentUser?.firstName)}
          </p>
          <p>
            {t('wordPairDescription2')}
          </p>
          <p>
            {t('wordPairDescription3')}
          </p>
          {
            !isCompleted && !!currentQuestion && !isLoading && (
              <WordPair
                onClick={onAnswer}
                question={currentQuestion}
              />
            )
          }
          {
            isLoading && (
              <div className={styles['layout__loading-animation']}>
                <LoadingAnimation isLight />
              </div>
            )
          }
        </div>
      </main>
      <Modal
        fullCover
        isVisible={isCompleted}
        onClose={onFinish}
      >
        <div className={styles.assessment__modal}>
          <p>{ReactHtmlParser(sprintf(t('modal.description'), currentUser?.firstName))}</p>
          <p>{t('modal.description2')}</p>
          <Button onClick={onFinish}>
            {t('modal.continue')}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default AssessmentPage;
