/* eslint-disable react-hooks/exhaustive-deps */
import { faTimes, faUserSlash } from '@fortawesome/pro-light-svg-icons';
import { formatRoute } from 'react-router-named-routes';
import { isEmpty, isNil } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import diff from 'object-diff';
import moment from 'moment';

import { API_DATE_FORMAT, APP_DATE_FORMAT } from 'app_constants/dateFormats';
import { PROFILE_EDIT_DUPLICATED_EMAIL } from 'app_constants/apiErrorCodes';
import { SessionContext } from 'app_state/session/SessionProvider';
import ApiError from 'classes/errors/ApiError.class';
import fetchDeleteAccount from 'app_state/session/actions/fetchDeleteAccount';
import fetchEditProfile from 'app_state/session/actions/fetchEditProfile';
import fetchUpdateAvatar from 'app_state/session/actions/fetchUpdateAvatar';
import URLS from 'app_constants/urls';
import useForm, { IErrors } from 'custom_hooks/useForm';
import useFormContextMenu from 'custom_hooks/useFormContextMenu';
import useFormValidation from 'custom_hooks/useFormValidation';
import useNotification from 'custom_hooks/useNotification';

import { IEditProfileForm, IUseEditProfileResults } from './types';

const BIO_MAX_LENGTH = 500;

const useEditProfile = () : IUseEditProfileResults => {
  const history = useHistory();
  const { state, dispatch } = useContext(SessionContext);
  const { currentUser } = state;
  const { t } = useTranslation('editProfile');
  const profileRoute = formatRoute(URLS.profile, { id: currentUser?.id });
  const [avatar, setAvatar] = useState<File | null>(null);
  const [avatarPath, setAvatarPath] = useState(currentUser?.avatar);
  const { validator } = useFormValidation();
  const { addErrorNotification, addSuccessNotification } = useNotification();
  const [emailApiError, setEmailApiError] = useState('');
  const [isDeleteModalVisible, setDeleteModalVisibility] = useState(false);

  const handleCloseDeleteModal = () => setDeleteModalVisibility(false);

  const defaultValues = {
    bio: currentUser?.bio,
    birthday: currentUser?.birthday
      ? moment(currentUser?.birthday, API_DATE_FORMAT).utc().format(APP_DATE_FORMAT) : null,
    email: currentUser?.email,
    firstName: currentUser?.firstName,
    genderPronounPreference: currentUser?.genderPronounPreference,
    lastName: currentUser?.lastName,
    notifications: currentUser?.notifications,
  };

  useEffect(() => {
    let avatarUrl = currentUser?.avatar;

    if (avatar) {
      avatarUrl = URL.createObjectURL(avatar);
    }

    setAvatarPath(avatarUrl);
  }, [avatar]);

  const handleSubmit = (values: IEditProfileForm, nextRoute?: string) => {
    const onError = (error?: ApiError) => {
      if (error?.errorCode === PROFILE_EDIT_DUPLICATED_EMAIL) {
        setEmailApiError(t('emailApiError'));
      } else {
        handleCloseDeleteModal();
        addErrorNotification('editProfileApiError');
      }
    };

    const onFinish = () => {
      history.push(nextRoute ?? profileRoute);
    };

    const onContinue = () => {
      setAvatar(null);
      const updatedProfile = diff(defaultValues, values);

      if (!isEmpty(updatedProfile)) {
        fetchEditProfile(
          dispatch,
          {
            id: currentUser?.id || 0,
            ...updatedProfile,
          },
          onFinish,
          onError,
        );
      } else {
        onFinish();
      }
    };

    if (!isNil(avatar)) {
      fetchUpdateAvatar(dispatch, avatar, onContinue, onError);
    } else {
      onContinue();
    }
  };

  const handleValidate = (values : IEditProfileForm) : IErrors => {
    const {
      bio,
      email,
      firstName,
      genderPronounPreference,
      lastName,
      birthday,
    } = values;

    if (emailApiError) {
      setEmailApiError('');
    }

    return {
      bio: validator.validateTextLength(bio || '', 1, BIO_MAX_LENGTH),
      birthday: validator.validateRequired(birthday || '')
        || validator.validateDate(birthday || '')
        || validator.validateIsDate18orMoreYearsOld(birthday || ''),
      email: validator.validateRequired(email || '') || validator.validateEmail(email || ''),
      firstName: validator.validateName(firstName || ''),
      genderPronounPreference: validator.validateRequired(genderPronounPreference || ''),
      lastName: validator.validateName(lastName || ''),
    };
  };

  const {
    errors,
    onChangeField,
    onSubmitForm,
    isUpdated,
    isValid,
    values = defaultValues,
  } = useForm<IEditProfileForm>({
    defaultValues,
    onSubmit: handleSubmit,
    onValidate: handleValidate,
  });

  const contextMenu = useFormContextMenu({
    onSubmitForm: (nextRoute) => handleSubmit(values, nextRoute),
  });

  const canSave = (isValid && isUpdated) || (!!avatar && !isUpdated);

  const handleDeleteAccount = () => {
    const onSuccess = () => {
      addSuccessNotification('deleteAccountSuccess');
    };

    const onError = () => {
      handleCloseDeleteModal();
      addErrorNotification('deleteAccountError');
    };

    fetchDeleteAccount(dispatch, onSuccess, onError);
  };

  const deleteModalOptions = [
    {
      icon: faUserSlash,
      label: t('deleteAccount.label'),
      onClick: () => handleDeleteAccount(),
    },
    {
      icon: faTimes,
      label: t('cancel'),
      onClick: handleCloseDeleteModal,
    },
  ];

  const handleShowDeleteModal = (e?: MouseEvent) => {
    if (e) {
      e.preventDefault();
    }
    setDeleteModalVisibility(true);
  };

  const handleChangeRoute = (route: string, e?: MouseEvent) => {
    if (e) {
      e.preventDefault();
    }

    if (isUpdated || !!avatar) {
      contextMenu.onShow(route, canSave);
    } else {
      history.push(route);
    }
  };

  return {
    avatar: {
      path: avatarPath,
      onChange: (image: any) => setAvatar(image),
    },
    contextMenu: {
      isVisible: contextMenu.isVisible || isDeleteModalVisible,
      message: isDeleteModalVisible ? t('deleteAccount.message') : t('contextMenu.message'),
      onClose: isDeleteModalVisible ? handleCloseDeleteModal : contextMenu.onClose,
      options: isDeleteModalVisible ? deleteModalOptions : contextMenu.options,
      title: isDeleteModalVisible ? t('deleteAccount.title') : t('contextMenu.title'),
    },
    emailApiError,
    form: {
      errors,
      isSubmitDisabled: !canSave || !!emailApiError,
      onChangeField,
      onSubmitForm,
      values,
    },
    onChangePassword: (e?: MouseEvent) => handleChangeRoute(URLS.changePassword, e),
    onDeleteAccount: (e?: MouseEvent) => handleShowDeleteModal(e),
    onGoBack: () => handleChangeRoute(profileRoute),
  };
};

export default useEditProfile;
