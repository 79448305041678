import moment from 'moment';

import { CONTENT_TYPES } from 'app_constants/contentTypes';
import {
  API_DATE_FORMAT,
  APP_DATE_FORMAT,
} from 'app_constants/dateFormats';

import {
  IEditProfileProps,
  IProfilesProps,
  ISettingsResult,
  IFriendsOfFriendProps,
  IToggleFriendVisibility,
} from './types';

/**
 * @description Settings for controller
 */
export default {
  getAll: {
    /**
     * @description Get all profiles
     * @param  {object} options
     * @param  {string} options.page - Results page
     * @param  {string} options.search - Search filter text
     * @return {object} Request Settings
     */
    settings(props?: IProfilesProps) : ISettingsResult {
      const { page, search } = props ?? {};
      let endpoint = '/personal/profiles/all';

      if (page) {
        endpoint = `${endpoint}/page/${page}`;
      }

      if (search) {
        endpoint = `${endpoint}/search/${search}`;
      }

      return {
        endpoint,
        method: 'GET',
      };
    },
  },

  editProfile: {
    /**
     * @description Get request settings (Edit Profile)
     * @param  {object} options
     * @param  {string} options.bio - bio
     * @param  {string} options.birthday - birthday
     * @param  {string} options.email -  email
     * @param  {stirng} options.firstName - first name
     * @param  {object} options.genderPronounPreference - pronoun preference
     * @param  {object} options.id - Profile ID
     * @param  {object} options.lastName - last name
     * @param  {object} options.notifications - notifications
     * @return {ISettingsResult} Request Settings
     */
    settings(props: IEditProfileProps) : ISettingsResult {
      const {
        birthday,
        id,
        ...rest
      } = props;

      return {
        endpoint: `/personal/profiles/${id}`,
        method: 'PUT',
        body: {
          birthday: birthday
            ? moment(birthday, APP_DATE_FORMAT).utc().format(API_DATE_FORMAT) : undefined,
          ...rest,
        },
      };
    },
  },

  updateAvatar: {
    /**
     * Get request settings (Update Avatar endpoint)
     * @param {object} avatar
     * @return {object} Request Settings
     */
    settings(avatar?: File) : ISettingsResult {
      const form = new FormData();

      if (avatar) {
        form.append('avatar', avatar);
      }

      return {
        endpoint: '/personal/profiles/set_avatar',
        body: form,
        headers: {
          enctype: CONTENT_TYPES.form,
        },
        method: 'PATCH',
      };
    },
  },

  getFriends: {
    /**
     * @description Get Friends (My Community)
     * @param  {object} options
     * @param  {string} options.page - Results page
     * @param  {string} options.search - Search filter text
     * @return {object} Request Settings
     */
    settings(props?: IProfilesProps) : ISettingsResult {
      const { page, search } = props ?? {};
      let endpoint = '/personal/profiles/my_community';

      if (page) {
        endpoint = `${endpoint}/page/${page}`;
      }

      if (search) {
        endpoint = `${endpoint}/search/${search}`;
      }

      return {
        endpoint,
        method: 'GET',
      };
    },
  },

  getFriendsOfFriend: {
    /**
     * @description Get Friends of Friend (His/Her Community)
     * @param  {object} options
     * @param  {number} options.friendId - Friend ID
     * @param  {string} options.page - Results page
     * @param  {string} options.search - Search filter text
     * @return {object} Request Settings
     */
    settings(props?: IFriendsOfFriendProps) : ISettingsResult {
      const { page, search, friendId } = props ?? {};
      let endpoint = `/personal/profiles/${friendId}/friends_of_friend`;

      if (page) {
        endpoint = `${endpoint}/page/${page}`;
      }

      if (search) {
        endpoint = `${endpoint}/search/${search}`;
      }

      return {
        endpoint,
        method: 'GET',
      };
    },
  },

  getProfile: {
    /**
     * @description Get a profile
     * @param  {number} profileId
     * @return {object} Request Settings
     */
    settings(profileId: number) : ISettingsResult {
      return {
        endpoint: `/personal/profiles/${profileId}`,
        method: 'GET',
      };
    },
  },

  deleteAccount: {
    /**
     * @description Delete self account
     * @return {object} Request Settings
     */
    settings() : ISettingsResult {
      return {
        endpoint: '/personal/profiles/destroy',
        method: 'DELETE',
      };
    },
  },

  toggleFriendVisibility: {
    /**
     * @description Delete self account
     * @return {object} Request Settings
     */
    settings(props?: IToggleFriendVisibility) : ISettingsResult {
      const { friendId, visibilityAction } = props ?? {};
      return {
        endpoint: `/personal/profiles/${friendId}/friends_of_friend/${visibilityAction}`,
        method: 'PATCH',
      };
    },
  },

  getAssessmentLanguages: {
    /**
     * @description Get AssessmentLanguages (Setup Profile)
     * @param  {object} options
     * @return {object} Request Settings
     */
    settings() : ISettingsResult {
      return {
        endpoint: '/profiles/languages',
        method: 'GET',
      };
    },
  },
};
