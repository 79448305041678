import { IAction } from 'app_state/types';
import Answer from 'classes/assessment/Answer.class';

import actionTypes from '../actionTypes';
import initialState from '../initialState.json';

const answers = (
  state: Answer[] = initialState.answers,
  action: IAction,
) : Answer[] => {
  switch (action.type) {
    case actionTypes.ADD_ANSWER:
      return [...state, action.payload];

    case actionTypes.FETCH_ASSESSMENT_REQUEST:
    case actionTypes.FETCH_UPDATE_ASSESSMENT_SUCCESS:
      return initialState.answers;

    default:
      return state;
  }
};

export default answers;
