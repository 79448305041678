/* eslint-disable react-hooks/exhaustive-deps */
import { debounce, forEach, isArray, map } from 'lodash';
import { faCertificate, faThumbsUp, faCommentAltExclamation } from '@fortawesome/pro-solid-svg-icons';
import { faSmileBeam } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { sprintf } from 'sprintf-js';
import { tns, TinySliderInstance } from 'tiny-slider/src/tiny-slider';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import React, { useContext, useEffect, useRef, useState } from 'react';
import shortid from 'shortid';

import { AssessmentContext } from 'app_state/assessment/AssessmentProvider';
import { breakpoints } from 'app_constants/breakpoints.json';
import { SessionContext } from 'app_state/session/SessionProvider';
import Assessment from 'classes/assessment/Assessment.class';
import Button from 'generics/Button/Button';
import Content from 'generics/Content/Content';
import fetchAssessment from 'app_state/assessment/actions/fetchAssessment';
import fetchContent from 'app_state/session/actions/fetchContent';
import PageSection from 'generics/PageSection/PageSection';
import PageWrapper from 'generics/PageWrapper/PageWrapper';
import Profile from 'classes/Profile/Profile.class';
import updateUser from 'app_state/session/actions/updateUser';
import URLS from 'app_constants/urls';

import Slide from './components/Slide/Slide';
import styles from './Welcome.scss';
import SvgExample from './example.inline.svg';
import SvgHeads from './heads.inline.svg';
import SvgRelax from './relax.inline.svg';

const setSlideItemsHeights = (slider: TinySliderInstance) => {
  const { slideItems } = slider.getInfo();
  const mq = window.matchMedia(`(min-width: ${breakpoints.mobile})`);

  if (mq.matches) {
    const heights = map(slideItems, (item) => item.clientHeight);
    const maxHeight = Math.max(...heights);
    forEach(slideItems, (item) => item.setAttribute('style', `height: ${maxHeight}px`));
  } else {
    forEach(slideItems, (item) => item.style.removeProperty('height'));
  }
};

const Welcome = () : JSX.Element => {
  const { state } = useContext(SessionContext);
  const { currentUser } = state;
  const { t } = useTranslation(['welcome', 'generics']);
  const { dispatch: sessionDispatch } = useContext(SessionContext);
  const history = useHistory();
  const sliderRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [slider, setSlider] = useState<TinySliderInstance | null>(null);

  const {
    dispatch: assessmentDispatch,
  } = useContext(AssessmentContext);

  const checkAssessmentStatus = () => {
    const onSuccess = (assessment: Assessment) => {
      const { assessmentScores, finished } = assessment;
      if (finished) {
        const profile = new Profile({
          ...currentUser,
          user: {
            ...currentUser?.user,
            assessmentScores,
            hasOngoingAssessment: false,
          },
        });

        sessionDispatch(updateUser(profile));
        fetchContent(sessionDispatch);
        history.push(URLS.home);
      }
    };

    const onError = (error:any) => {
      console.log(error);
    };

    fetchAssessment(assessmentDispatch, true, onError, onSuccess);
  };

  useEffect(() => {
    checkAssessmentStatus();
  }, []);

  const goToSlide = (direction: string) => {
    const mq = window.matchMedia(`(max-width: ${breakpoints.mobile})`);

    if (mq.matches) {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }

    slider.goTo(direction);
  };

  const warningSlideButtonLabel = (label: string | string[]) => (
    isArray(label)
      ? label.map((text) => (
        <span className={styles['warning__button-text']} aria-hidden="true" key={shortid()}>
          {text}
        </span>
      ))
      : label
  );

  useEffect(() => {
    if (!slider && sliderRef?.current) {
      const s = tns({
        container: sliderRef.current,
        controls: false,
        nav: false,
        loop: false,
      });
      setSlideItemsHeights(s);
      setSlider(s);
      setIsLoading(false);

      window.addEventListener('resize', debounce(() => {
        setSlideItemsHeights(s);
      }, 500));
    }
  }, [slider, sliderRef]);

  return (
    <PageWrapper>
      <div className={styles.layout}>
        <PageSection tag="div" className={classNames(styles.slider__wrapper, { [styles['is-loading']]: isLoading })}>
          <div className={styles.slider}>
            <div ref={sliderRef}>

              <Slide
                titleIcon={(
                  <FontAwesomeIcon
                    className={styles['icon-welcome']}
                    icon={faSmileBeam}
                    size="2x"
                  />
                )}
                title={sprintf(t('welcomeSlide.title'), currentUser?.firstName)}
                content={(
                  <>
                    <Content
                      className={styles.slider__content}
                      content={t('welcomeSlide.description')}
                    />
                    <div className={styles.welcome__graphic__wrapper}>
                      <SvgHeads className={styles.welcome__graphic} />
                    </div>
                  </>
                )}
                controls={[
                  <Button isBlock onClick={() => goToSlide('next')} data-cy="continue-assesment-btn">
                    {t('welcomeSlide.nextButtonLabel')}
                    &nbsp;&rarr;
                  </Button>,
                ]}
              />

              <Slide
                titleIcon={(
                  <FontAwesomeIcon
                    className={styles['icon-warning']}
                    icon={faCommentAltExclamation}
                    size="2x"
                  />
                )}
                title={t('warningSlide.title') || ''}
                content={(
                  <div className={styles.warning__graphic__stage}>
                    <div className={styles.warning__graphic__wrapper}>
                      <SvgRelax className={styles.warning__graphic} />
                    </div>
                    <Content
                      className={styles.slider__content}
                      content={t('warningSlide.description')}
                    />
                  </div>
                )}
                controlsTitle={t('warningSlide.controlsTitle')}
                controls={[
                  <Button
                    isBlock
                    onClick={() => goToSlide('prev')}
                    title={t('warningSlide.prevButtonLabel', { joinArrays: ' ' })}
                  >
                    {warningSlideButtonLabel(t('warningSlide.prevButtonLabel', { returnObjects: true }))}
                  </Button>,
                  <Button
                    isBlock
                    onClick={() => goToSlide('next')}
                    title={t('warningSlide.nextButtonLabel', { joinArrays: ' ' })}
                  >
                    {warningSlideButtonLabel(t('warningSlide.nextButtonLabel', { returnObjects: true }))}
                  </Button>,
                ]}
              />

              <Slide
                titleIcon={(
                  <span className={classNames(styles['icon-instructions'], 'fa-stack')}>
                    <FontAwesomeIcon className="fa-stack-2x" icon={faCertificate} />
                    <FontAwesomeIcon className="fa-stack-1x" icon={faThumbsUp} inverse />
                  </span>
                )}
                title={t('instructionsSlide.title') || ''}
                content={(
                  <>
                    <Content
                      className={styles.slider__content}
                      content={t('instructionsSlide.description')}
                    />
                    <div className={styles.instructions__graphic__wrapper}>
                      <SvgExample className={styles.instructions__graphic} />
                    </div>
                    <Content
                      className={styles.slider__content}
                      content={t('instructionsSlide.hint')}
                    />
                  </>
                )}
                controlsTitle={t('instructionsSlide.controlsTitle')}
                controls={[
                  <Button isBlock onClick={() => goToSlide('prev')}>
                    &larr;&nbsp;
                    {t('instructionsSlide.prevButtonLabel')}
                  </Button>,
                  <Button isBlock onClick={() => history.push(URLS.assessment)}>
                    {t('instructionsSlide.nextButtonLabel')}
                    &nbsp;&rarr;
                  </Button>,
                ]}
              />

            </div>
          </div>
        </PageSection>
      </div>
    </PageWrapper>
  );
};

export default Welcome;
