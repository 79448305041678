import { clone } from 'lodash';
import d3 from 'vendor/d3';

import ENERGY_LABELS from 'app_constants/energyLabels';

import { IBandSide, IElements, IGeometry, IStyles } from '../../types';

const RIGHT_ANGLE = Math.PI / 2;

const isEvenBand = (d: any, i: number) => i % 2 === 0;
// we reverse the bands for DOM ordering
const isOuterBand = (d: any, i: number) => i === 0;

const drawBands = ({ geometry, styles, elements }: IDrawBandsParams) : void => {
  if (geometry && styles && elements) {
    const { cornerRadiusRatio } = geometry;
    if (elements.references) {
      const band = elements.references.selectAll(`.${styles.band}`)
        .data(clone(ENERGY_LABELS).reverse());

      band.exit().remove();

      band.enter()
        .append('path')
        .merge(band)
        .attr('d', (d: keyof IBandSide) => {
          const halfSide = geometry.bandHalfSide[d];
          const cornerRadius = cornerRadiusRatio * halfSide;
          const preArcHalfSide = halfSide - cornerRadius;

          const pathGenerator = d3.path();
          pathGenerator.moveTo(halfSide, preArcHalfSide);
          pathGenerator.arc(
            preArcHalfSide, preArcHalfSide,
            cornerRadius,
            0, RIGHT_ANGLE,
          );
          pathGenerator.lineTo(-preArcHalfSide, halfSide);
          pathGenerator.arc(
            -preArcHalfSide, preArcHalfSide,
            cornerRadius,
            RIGHT_ANGLE, Math.PI,
          );
          pathGenerator.lineTo(-halfSide, -preArcHalfSide);
          pathGenerator.arc(
            -preArcHalfSide, -preArcHalfSide,
            cornerRadius,
            Math.PI, 3 * RIGHT_ANGLE,
          );
          pathGenerator.lineTo(preArcHalfSide, -halfSide);
          pathGenerator.arc(
            preArcHalfSide, -preArcHalfSide,
            cornerRadius,
            3 * RIGHT_ANGLE, 2 * Math.PI,
          );
          pathGenerator.closePath();
          return pathGenerator.toString();
        })
        .attr('class', styles.band)
        .classed(styles.solid, isEvenBand)
        .classed(styles.outer, isOuterBand);
    }
  }
};

interface IDrawBandsParams {
  elements: IElements,
  geometry: IGeometry,
  styles: IStyles,
}

export default drawBands;
