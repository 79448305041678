import {
  IList,
  IAction,
} from 'app_state/types';
import initialState from 'app_state/session/initialState.json';
import sessionActionTypes from 'app_state/session/actionTypes';

const sentFriendRequests = (
  state: IList = initialState.relationships.sentFriendRequests,
  action: IAction,
): IList => {
  switch (action.type) {
    case sessionActionTypes.FETCH_SENT_FRIEND_REQUESTS_REQUEST:
      return {
        ...initialState.relationships.sentFriendRequests,
        isLoading: true,
      };

    case sessionActionTypes.FETCH_SENT_FRIEND_REQUESTS_SUCCESS:
      return action.payload;

    case sessionActionTypes.RESET_STATE:
    case sessionActionTypes.FETCH_SENT_FRIEND_REQUESTS_FAILURE:
      return initialState.relationships.sentFriendRequests;

    default:
      return state;
  }
};

export default sentFriendRequests;
