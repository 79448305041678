/* eslint-disable react-hooks/exhaustive-deps */
import { uniqBy } from 'lodash';
import { useEffect, useState } from 'react';
import { IList } from 'app_state/types';

const DEFAULT_COLLECTION_NAME = 'profiles';

const usePagination = ({
  initialCollection,
  onRequest,
  collectionName = DEFAULT_COLLECTION_NAME,
} : IUsePaginationProps) : IUsePagination => {
  const [page, setPage] = useState(1);
  const [areMorePages, setMorePages] = useState(false);
  const [results, setResults] = useState<any[]>([]);

  useEffect(() => {
    if (initialCollection) {
      const { meta: { morePages }, collection } = initialCollection;
      setPage(1);
      setMorePages(morePages);
      setResults(collection);
    }
  }, [initialCollection]);

  const getResults = (newPage: number) => {
    const onSuccess = (props : any) => {
      const { meta, [collectionName]: collection } = props;

      if (collection) {
        const newResults = newPage === 1 ? collection : uniqBy([...results, ...collection], 'id');
        setResults(newResults);
      }

      setMorePages(meta.morePages);
      setPage(newPage);
    };

    const onError = () => {
      if (DEVELOPMENT) {
        console.error(`Error while getting ${collectionName} page: ${newPage}`);
      }
    };

    onRequest({ page: newPage }, onSuccess, onError);
  };

  const handleMoreResults = () => {
    getResults(page + 1);
  };

  return {
    onGetResults: () => getResults(1),
    onGetMoreResults: handleMoreResults,
    areMorePages,
    results,
  };
};

interface ISearchOptions {
  page: number,
  search?: string,
}

interface IUsePaginationProps {
  onRequest: (
    searchOptions: ISearchOptions,
    onSuccess: (props: any) => void,
    onError: () => void
  ) => void,
  collectionName?: string,
  initialCollection?: IList,
}

export interface IUsePagination {
  onGetResults: () => void,
  onGetMoreResults: () => void,
  areMorePages: boolean,
  results: any[],
}

export default usePagination;
