import { isNil } from 'lodash';
import { useTranslation } from 'react-i18next';
import React from 'react';

import Button from 'generics/Button/Button';
import Form from 'generics/Form/Form';
import Input from 'generics/Input/Input';
import MenuMessage from 'generics/ContextMenu/components/MenuMessage/MenuMessage';
import MenuOptions from 'generics/ContextMenu/components/MenuOptions/MenuOptions';
import Modal from 'generics/Modal/Modal';
import PageSection from 'generics/PageSection/PageSection';
import PageTitle from 'generics/PageTitle/PageTitle';
import PageWrapper from 'generics/PageWrapper/PageWrapper';

import useChangePassword from './useChangePassword';

import styles from './ChangePassword.scss';

const ChangePassword = () : JSX.Element => {
  const { t } = useTranslation(['changePassword']);

  const {
    contextMenu,
    form,
    onGoBack,
  } = useChangePassword();

  const {
    canSubmit,
    currentPasswordApiError,
    errors,
    values,
    onChangeField,
    onSubmitForm,
  } = form;

  const currentPasswordError = currentPasswordApiError || errors.currentPassword;
  const isDisabled = !!currentPasswordApiError || !canSubmit;

  return (
    <>
      <PageWrapper>
        <div className={styles.layout}>
          <Form onSubmit={onSubmitForm}>
            <PageSection>
              <PageTitle
                title={t('title')}
                onBackButtonClick={onGoBack}
                actions={(
                  <Button isSmall type="submit" disabled={isDisabled} className={styles['mobile-button']}>
                    {t('form.btn')}
                  </Button>
                )}
              />
            </PageSection>
            <PageSection>
              <Input
                autoComplete="off"
                error={isNil(values.currentPassword) ? null : currentPasswordError}
                id="currentPassword"
                label={t('form.currentPassword')}
                name="currentPassword"
                onChange={(e) => onChangeField('currentPassword', e.currentTarget.value)}
                type="password"
                value={values.currentPassword || ''}
              />
              <Input
                autoComplete="off"
                error={isNil(values.password) ? null : errors.password}
                id="password"
                label={t('form.password')}
                name="password"
                onChange={(e) => onChangeField('password', e.currentTarget.value)}
                type="password"
                value={values.password || ''}
              />
              <Input
                autoComplete="off"
                error={isNil(values.passwordConfirmation) ? null : errors.passwordConfirmation}
                id="passwordConfirmation"
                label={t('form.passwordConfirmation')}
                name="passwordConfirmation"
                onChange={(e) => onChangeField('passwordConfirmation', e.currentTarget.value)}
                type="password"
                value={values.passwordConfirmation || ''}
              />
              <Button type="submit" disabled={isDisabled} className={styles['desktop-button']}>
                {t('form.btn')}
              </Button>
            </PageSection>
          </Form>
        </div>
      </PageWrapper>

      <Modal
        isSmall
        isVisible={contextMenu.isVisible}
        onClose={contextMenu.onClose}
      >
        <MenuMessage
          color="secondary"
          message={(
            <p>
              <strong>{t('contextMenu.title')}</strong>
              <br />
              {t('contextMenu.message')}
            </p>
          )}
        />
        <MenuOptions color="secondary" options={contextMenu.options} />
      </Modal>
    </>
  );
};

export default ChangePassword;
