import { Dispatch } from 'react';

import { ISessionAction } from 'app_state/types';

import fetchFriends from './fetchFriends';
import fetchReceivedFriendRequests from './fetchReceivedFriendRequests';
import fetchSendFriendRequests from './fetchSentFriendRequests';
import fetchSentInvites from './fetchSentInvites';

/**
 * @description: Gets the first page for: Friends, Friend Requests and Invites lists
 * @param { Dispatch } dispatch React Dispatch
 */
export default (
  dispatch: Dispatch<ISessionAction>,
) : void => {
  fetchReceivedFriendRequests(dispatch);
  fetchSendFriendRequests(dispatch);
  fetchFriends(dispatch);
  fetchSentInvites(dispatch);
};
