import terms from 'raw-loader!./terms.md';
import acceptInvitation from './acceptInvitation.json';
import appFooter from './appFooter.json';
import assessment from './assessment.json';
import changePassword from './changePassword.json';
import corporateAccount from './corporateAccount.json';
import editProfile from './editProfile.json';
import forgotPassword from './forgotPassword.json';
import formValidation from './formValidation.json';
import generics from './generics.json';
import home from './home.json';
import kits from './kits.json';
import login from './login.json';
import noPrint from './noPrint.json';
import notifications from './notifications.json';
import pageNotFound from './pageNotFound.json';
import payment from './payment.json';
import profile from './profile.json';
import resetPassword from './resetPassword.json';
import search from './search.json';
import sendInvitation from './sendInvitation.json';
import shareReport from './shareReport.json';
import signUp from './signUp.json';
import signUpInvitation from './signUpInvitation.json';
import welcome from './welcome.json';

export default {
  acceptInvitation,
  appFooter,
  assessment,
  changePassword,
  corporateAccount,
  editProfile,
  forgotPassword,
  formValidation,
  generics,
  home,
  kits,
  login,
  noPrint,
  notifications,
  pageNotFound,
  payment,
  profile,
  resetPassword,
  search,
  sendInvitation,
  shareReport,
  signUp,
  signUpInvitation,
  terms: { terms },
  welcome,
};
