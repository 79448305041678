import { IAction } from 'app_state/types';
import Kit from 'classes/Kit/Kit.class';
import initialState from 'app_state/session/initialState.json';
import sessionActionTypes from 'app_state/session/actionTypes';

const kits = (
  state: Kit[] = initialState.content.kits,
  action: IAction,
): Kit[] => {
  switch (action.type) {
    case sessionActionTypes.FETCH_CONTENT_SUCCESS:
      return action.payload?.dashboardModules?.filter((kit: any) => (kit.categoryKey !== 'meetings_8')).map((kit: any) => new Kit(kit));

    case sessionActionTypes.RESET_STATE:
    case sessionActionTypes.FETCH_CONTENT_REQUEST:
    case sessionActionTypes.FETCH_CONTENT_FAILURE:
      return initialState.content.kits;

    default:
      return state;
  }
};

export default kits;
