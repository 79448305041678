/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import styles from './AppVectorFooter.scss';

import SvgVector from './simpli5-relationships-footer-vector.svg';

const AppVectorFooter = () : JSX.Element => (
  <footer className={styles['app-vector-footer']}>
    <figure>
      <img
        src={SvgVector}
        alt="Simpli5 Relationships Footer Vector"
      />
    </figure>
  </footer>
);

export default AppVectorFooter;
