/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from 'react';

import { SessionContext } from 'app_state/session/SessionProvider';
import fetchRelationships from 'app_state/session/actions/fetchRelationships';

const useHome = () : IUseHome => {
  const { state, dispatch } = useContext(SessionContext);
  const { relationships } = state;

  const isLoading = relationships.friends.isLoading
    || relationships.receivedFriendRequests.isLoading
    || relationships.sentFriendRequests.isLoading
    || relationships.sentInvites.isLoading
    || false;

  const isListEmpty = relationships.friends.collection.length === 0
    && relationships.receivedFriendRequests.collection.length === 0
    && relationships.sentFriendRequests.collection.length === 0
    && relationships.sentInvites.collection.length === 0;

  useEffect(() => {
    fetchRelationships(dispatch);
  }, []);

  return {
    isListEmpty,
    isLoading,
  };
};

interface IUseHome {
  isListEmpty: boolean,
  isLoading: boolean,
}

export default useHome;
