import { Dispatch } from 'react';

import { IAssessmentAction } from 'app_state/types';
import api from 'api';
import ApiError from 'classes/errors/ApiError.class';
import Assessment from 'classes/assessment/Assessment.class';
import Question from 'classes/assessment/Question.class';

import actionTypes from '../actionTypes';

const fetchAssessmentFailure = (error: ApiError) : IAssessmentAction => ({
  payload: error,
  type: actionTypes.FETCH_ASSESSMENT_FAILURE,
});

const fetchAssessmentRequest = () : IAssessmentAction => ({
  payload: null,
  type: actionTypes.FETCH_ASSESSMENT_REQUEST,
});

const fetchAssessmentSuccess = (questions: Question[]) : IAssessmentAction => ({
  payload: questions,
  type: actionTypes.FETCH_ASSESSMENT_SUCCESS,
});

export default (
  dispatch: Dispatch<any>,
  isInProgress: boolean,
  errorCallback: (error?: any) => void,
  checkStatusCallback?: (assessment: Assessment) => void,
) : void => {
  dispatch(fetchAssessmentRequest());

  const onSuccess = ({ assessment: apiAssessment } : any) => {
    const assessment = new Assessment(apiAssessment);

    if (!assessment || assessment.questions.length === 0) {
      if (DEVELOPMENT) {
        console.log('API Assessment has wrong format');
      }
      errorCallback();
    } else {
      if (checkStatusCallback) {
        checkStatusCallback(assessment);
        return;
      }
      dispatch(fetchAssessmentSuccess(assessment.questions));
    }
  };

  const onError = (error: ApiError) => {
    dispatch(fetchAssessmentFailure(error));
    errorCallback(error);
  };

  api.users.getAssessment(isInProgress, onSuccess, onError);
};
