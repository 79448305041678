import { Elements } from '@stripe/react-stripe-js';
import propTypes from 'prop-types';
import React from 'react';

import loadStripe from 'utils/loadStripe';

import CheckoutForm from './components/CheckoutForm/CheckoutForm';

const stripePromise = loadStripe;

const Payment = ({ onFinish } : IPaymentProps) : JSX.Element => (
  <Elements stripe={stripePromise}>
    <CheckoutForm onFinish={onFinish} />
  </Elements>
);

Payment.propTypes = {
  onFinish: propTypes.func,
};

Payment.defaultProps = {
  onFinish: null,
};

interface IPaymentProps {
  onFinish?: () => void | null,
}

export default Payment;
