import { sprintf } from 'sprintf-js';
import { useTranslation } from 'react-i18next';
import React, { useContext, ReactNode } from 'react';

import { CHILDREN } from 'app_constants/propTypes';
import { SessionContext } from 'app_state/session/SessionProvider';
import Content from 'generics/Content/Content';

import SvgLogo from 'components/AppHeader/simpli5-relationships-logo.svg';
import styles from './NoPrint.scss';

const NoPrint = ({ children }: INoPrintProps) : JSX.Element => {
  const { t } = useTranslation('noPrint');
  const { state } = useContext(SessionContext);
  const { isAuthenticated } = state;

  return (
    <>
      <div id={styles.noprint} className={isAuthenticated ? styles.auth : styles['no-auth']}>
        {children}
      </div>
      <div id={styles.print}>
        <img
          src={SvgLogo}
          alt="Simpli5 Relationships"
        />
        <Content content={sprintf(t('noPrint'), window.location.toString())} />
      </div>
    </>
  );
};

NoPrint.propTypes = {
  children: CHILDREN,
};

NoPrint.defaultProps = {
  children: null,
};

interface INoPrintProps {
  children?: ReactNode,
}

export default NoPrint;
