import combineReducers from 'utils/combineReducers';
import content from './content';
import currentUser from './currentUser';
import isAuthenticated from './isAuthenticated';
import relationships from './relationships';
import loginEmail from './loginEmail';

export default combineReducers({
  content,
  currentUser,
  isAuthenticated,
  loginEmail,
  relationships,
});
