import React, { ReactNode } from 'react';
import classNames from 'classnames';
import propTypes from 'prop-types';
import shortid from 'shortid';
import styles from './CardsList.scss';

const CardsList = ({
  cards,
  className,
}: ICardsListProps) : JSX.Element => (
  <ul className={classNames(className, [styles['cards-list']])}>
    {cards.map((card) => (
      <li key={shortid()}>
        {card}
      </li>
    ))}
  </ul>
);

CardsList.propTypes = {
  cards: propTypes.arrayOf(propTypes.element).isRequired,
  className: propTypes.string,
};

CardsList.defaultProps = {
  className: null,
};

export interface ICardsListProps {
  cards: Array<ReactNode>,
  className?: string,
}

export default CardsList;
