import classNames from 'classnames';
import propTypes from 'prop-types';
import React, { HTMLAttributes } from 'react';
import ReactHtmlParser from 'react-html-parser';
import ReactMarkdown from 'react-markdown';
import breaks from 'remark-breaks';
import gfm from 'remark-gfm';

import styles from './Content.scss';

const Content = ({ className, content, useHtmlParser, ...rest }: IContentProps) : JSX.Element => (
  <div
    className={classNames(styles.content, className)}
    {...rest}
  >
    {
      useHtmlParser
        ? ReactHtmlParser(content)
        : <ReactMarkdown plugins={[breaks, gfm]}>{content}</ReactMarkdown>
    }
  </div>
);

Content.propTypes = {
  className: propTypes.string,
  content: propTypes.string.isRequired,
  useHtmlParser: propTypes.bool,
};

Content.defaultProps = {
  className: null,
  useHtmlParser: false,
};

interface IContentProps extends HTMLAttributes<HTMLDivElement> {
  content: string,
  useHtmlParser: boolean,
}

export default Content;
