import { IAssessmentAction } from 'app_state/types';
import Answer from 'classes/assessment/Answer.class';

import actionTypes from '../actionTypes';

const addAnswer = (answer: Answer) : IAssessmentAction => ({
  payload: answer,
  type: actionTypes.ADD_ANSWER,
});

export default addAnswer;
