export enum CONTENT_TYPES {
  form = 'multipart/form-data',
  json = 'application/json',
  pdf = 'application/pdf',
  textPlain = 'text/plain',
  vtt = 'text/vtt',
}

export const APPLICATION_JSON_REGEX = /application\/([a-z].+)?json/;
export const TEXT_PLAIN_REGEX = /text\/([a-z].+)?/;
