import React, { ReactNode, ComponentProps } from 'react';
import propTypes from 'prop-types';
import { CHILDREN } from 'app_constants/propTypes';

const Form = ({ children, className, onSubmit }: IFormProps) : JSX.Element => (
  <form
    className={className}
    onSubmit={onSubmit}
  >
    {children}
  </form>
);

Form.propTypes = {
  children: CHILDREN,
  className: propTypes.string,
  onSubmit: propTypes.func,
};

Form.defaultProps = {
  children: null,
  className: null,
  onSubmit: undefined,
};

interface IFormProps extends ComponentProps<'form'> {
  children: ReactNode,
  className: string,
}

export default Form;
