import { faQuestionSquare, faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import React, { ComponentProps, useEffect, useState } from 'react';
import classNames from 'classnames';
import propTypes from 'prop-types';
import Tooltip from 'generics/Tooltip/Tooltip';
import styles from './Input.scss';

const inputTypes = ['text', 'email', 'tel', 'password', 'url', 'search', 'number', 'range', 'file'];
const defaultInputType = 'text';

const Input = ({
  className,
  error,
  id,
  isBlockLabel,
  label,
  onClearButtonClick,
  type = defaultInputType,
  value,
  ...rest
}: IInputProps) : JSX.Element => {
  const { t } = useTranslation(['generics', 'formValidation']);
  const [isClearButtonVisible, setClearButtonVisibility] = useState(false);

  useEffect(() => {
    if (onClearButtonClick) {
      setClearButtonVisibility(!!value);
    }
  }, [onClearButtonClick, value]);

  return (
    <>
      <div
        className={classNames(
          styles.input__wrapper,
          { [styles.wrap]: isBlockLabel },
          { [styles['has-error']]: !!error },
        )}
      >

        <input
          className={classNames(
            className,
            styles.input,
            { [styles.isBlock]: isBlockLabel },
            { [styles.noLabelSpacing]: !label },
          )}
          id={id}
          type={type}
          value={value}
          {...rest}
        />

        {isClearButtonVisible && (
          <button
            className={styles['input__clear-button']}
            onClick={onClearButtonClick}
            title={t('clear')}
            type="button"
          >
            <FontAwesomeIcon icon={faTimesCircle} />
          </button>
        )}

        {label && (
          <label
            className={classNames(
              styles.input__label,
              { [styles.isBlock]: isBlockLabel },
            )}
            htmlFor={id}
          >
            {label}
          </label>
        )}

        {type === 'password' && (
          <Tooltip
            className={classNames(
              styles['input__password-tooltip'],
              { [styles.isBlockTooltip]: isBlockLabel },
            )}
            tooltip={t('formValidation:passwordTooltip')}
            alignment="right"
          >
            <FontAwesomeIcon icon={faQuestionSquare} size="lg" />
          </Tooltip>
        )}

      </div>
      {error && (
        <span className="error-text">
          {error}
        </span>
      )}
    </>
  );
};

Input.propTypes = {
  error: propTypes.string,
  id: propTypes.string.isRequired,
  isBlockLabel: propTypes.bool,
  label: propTypes.string,
  onClearButtonClick: propTypes.func,
  type: propTypes.oneOf(inputTypes),
};

Input.defaultProps = {
  className: '',
  error: null,
  isBlockLabel: false,
  label: '',
  onClearButtonClick: null,
  type: defaultInputType,
};

interface IInputProps extends ComponentProps<'input'> {
  className?: string,
  onClearButtonClick?: () => void,
  error?: string | null,
  isBlockLabel?: boolean,
  label?: string,
}

export default Input;
