import getEpicBaseKey from 'utils/getEpicBaseKey';

import {
  DEFAULT_KIT_IMAGE,
  KIT_IMAGES,
} from 'app_constants/kitImages';

export default class Kit {
  content?: string;

  key: string;

  title: string;

  description: string;

  imageUrl?: string;

  custom?: boolean;

  customPayment?: boolean;

  customShareReport?: boolean;

  constructor(options : IProps) {
    this.key = options.key ?? '';
    this.content = options.content ?? '';
    this.title = options.title ?? '';
    this.description = options.description ?? '';
    this.imageUrl = DEFAULT_KIT_IMAGE;
    this.custom = options.custom ?? false;
    this.customPayment = options.customPayment ?? false;
    this.customShareReport = options.customShareReport ?? false;
    this.getKitImage();
  }

  getKitImage(): void {
    const kitKey = getEpicBaseKey(this.key);
    this.imageUrl = KIT_IMAGES.find(({ key }) => key === kitKey)?.imageUrl || DEFAULT_KIT_IMAGE;
  }
}

export interface IProps {
  content?: string,
  custom?: boolean,
  customPayment?: boolean,
  customShareReport?: boolean,
  description?: string,
  key?: string,
  title?: string,
}
