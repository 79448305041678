import { INotificationsAction } from 'app_state/types';
import Notification from 'classes/Notification/Notification.class';

import notificationTypes from './actionTypes';

export const addNotification = (notification: Notification) : INotificationsAction => ({
  type: notificationTypes.ADD_NOTIFICATION,
  payload: notification,
});

export const clearNotification = () : INotificationsAction => ({
  type: notificationTypes.CLEAR_NOTIFICATION,
  payload: null,
});
