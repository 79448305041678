import { useTranslation } from 'react-i18next';
import React from 'react';

import Content from 'generics/Content/Content';
import PageSection from 'generics/PageSection/PageSection';
import PageWrapper from 'generics/PageWrapper/PageWrapper';

import styles from './Terms.scss';

const Terms = () : JSX.Element => {
  const { t } = useTranslation(['terms']);

  return (
    <PageWrapper>
      <div className={styles.layout}>
        <PageSection>

          <Content
            className={styles.content}
            content={t('terms')}
          />

        </PageSection>
      </div>
    </PageWrapper>
  );
};

export default Terms;
