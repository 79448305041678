import {
  IList,
  IAction,
} from 'app_state/types';
import initialState from 'app_state/session/initialState.json';
import sessionActionTypes from 'app_state/session/actionTypes';

const sentInvites = (
  state: IList = initialState.relationships.sentInvites,
  action: IAction,
): IList => {
  switch (action.type) {
    case sessionActionTypes.FETCH_SENT_INVITES_REQUEST:
      return {
        ...initialState.relationships.sentInvites,
        isLoading: true,
      };

    case sessionActionTypes.FETCH_SENT_INVITES_SUCCESS:
      return action.payload;

    case sessionActionTypes.RESET_STATE:
    case sessionActionTypes.FETCH_SENT_INVITES_FAILURE:
      return initialState.relationships.sentInvites;

    default:
      return state;
  }
};

export default sentInvites;
