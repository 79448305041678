/**
 * Advice colors and icons based on keys
 */

const { PUBLIC_PATH } = process.env;
const root = `${window.location.origin}${PUBLIC_PATH}/kit-images/`;

export const KIT_IMAGES : IKitImage[] = [
  {
    key: 'customizing-your-exercise',
    imageUrl: `${root}customize-your-exercise.webp`,
  },
  {
    key: 'unlocking-a-growth-mindset',
    imageUrl: `${root}growth-mindset.webp`,
  },
  {
    key: 'managing-stress',
    imageUrl: `${root}dealing-with-stress.webp`,
  },
  {
    key: 'advanced-professional-growth-insights',
    imageUrl: 'https://images.pexels.com/photos/2404385/pexels-photo-2404385.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
  },
  {
    key: 'the-model',
    imageUrl: `${root}the-model.webp`,
  },
  {
    key: 'relationships',
    imageUrl: `${root}relationships.webp`,
  },
  {
    key: 'personal-empowerment',
    imageUrl: `${root}personal-empowerment.webp`,
  },
];

export const DEFAULT_KIT_IMAGE = 'https://placehold.it/288x176';

interface IKitImage {
  key: string,
  imageUrl: string,
}
