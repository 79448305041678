enum INVITATION_TYPES {
  corporate = 'corporate',
  none = 'none',
  personal = 'personal',
  personalCorporate = 'personal_corporate',
  personalSignup = 'personal_signup',
  personalSSO = 'personal_sso',
}

export default INVITATION_TYPES;
