import { Dispatch } from 'react';

import { addNotification, clearNotification } from 'app_state/notifications/actions';
import Notification from 'classes/Notification/Notification.class';
import notificationTypes from 'app_constants/notificationTypes';

import resetState from './resetState';
import { INotificationsAction, ISessionAction } from '~/app_state/types';

const NOTIFICATION_TIME = 4000;

const expireSession = (
  sessionDispatch: Dispatch<ISessionAction>,
  notificationDispatch: Dispatch<INotificationsAction>,
  label: string,
) : void => {
  const timeout = setTimeout(() => {
    notificationDispatch(clearNotification());
  }, NOTIFICATION_TIME);

  const notification = new Notification({ label, timeout, type: notificationTypes.warning });

  sessionDispatch(resetState());
  notificationDispatch(addNotification(notification));
};

export default expireSession;
