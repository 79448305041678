export default class Question {
  id: number;

  leftWordPair?: IWordPair;

  rightWordPair?: IWordPair;

  constructor({ id, leftWordPair, rightWordPair } : IQuestion) {
    this.id = id;
    this.leftWordPair = leftWordPair;
    this.rightWordPair = rightWordPair;
  }
}

export interface IQuestion {
  id: number,
  leftWordPair: IWordPair,
  rightWordPair: IWordPair,
}

export interface IWordPair {
  id: number,
  text: string,
}
