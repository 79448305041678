import { IAction } from 'app_state/types';
import initialState from 'app_state/invites/initialState.json';
import invitationActionTypes from 'app_state/invites/actionTypes';
import INVITATION_STATUS from 'app_constants/invitationStatus';
import Invitation from 'classes/Invitation/Invitation.class';

const currentInvitation = (
  state: Invitation | null = initialState.currentInvitation,
  action: IAction,
): Invitation | null => {
  switch (action.type) {
    case invitationActionTypes.FETCH_INVITATION_SUCCESS:
      return action.payload;

    case invitationActionTypes.FETCH_INVITATION_REQUEST:
      return initialState.currentInvitation;

    case invitationActionTypes.ACCEPT_INVITATION:
      return new Invitation({ ...state, status: INVITATION_STATUS.accepted });

    case invitationActionTypes.FETCH_DECLINE_INVITATION_SUCCESS:
      return new Invitation({ ...state, status: INVITATION_STATUS.declined });

    default:
      return state;
  }
};

export default currentInvitation;
