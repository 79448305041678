/* eslint-disable react-hooks/exhaustive-deps */
import { Switch } from 'react-router-dom';
import React, { useContext, useEffect, useState } from 'react';
import shortid from 'shortid';

import { SessionContext } from 'app_state/session/SessionProvider';
import PageNotFound from 'pages/PageNotFound/PageNotFound';
import Route from 'components/Route/Route';
import routes from 'app_constants/routes';

const Routes = (): JSX.Element => {
  /*
    canNavigate Flag:
    If the user has already linked the Corporate scores or
    completed succesfully the Personals assessment.
  */
  const [canNavigate, setCanNavigate] = useState(true);

  const { state } = useContext(SessionContext);
  const { currentUser, isAuthenticated } = state;
  const { user, assessmentScoresObj } = currentUser || {};

  const verifyUserScores = () => {
    if (isAuthenticated && (assessmentScoresObj?.isNull || user?.hasOngoingAssessment)) {
      setCanNavigate(false);
    } else {
      setCanNavigate(true);
    }
  };

  useEffect(() => {
    verifyUserScores();
  }, [assessmentScoresObj, isAuthenticated, user]);

  return (
    <Switch>
      {
        routes.map((allProps) => (
          <Route
            canNavigate={canNavigate}
            isAuthenticated={isAuthenticated}
            key={`route-${shortid.generate}`}
            {...allProps}
          />
        ))
      }
      <Route component={PageNotFound} />
    </Switch>
  );
};

export default Routes;
