import { Dispatch } from 'react';

import { IAssessmentAction } from 'app_state/types';
import api from 'api';
import ApiError from 'classes/errors/ApiError.class';
import Question from 'classes/assessment/Question.class';

import actionTypes from '../actionTypes';

const TOTAL_QUESTIONS = 18;

const fetchUpdateAssessmentFailure = (error: ApiError) : IAssessmentAction => ({
  payload: error,
  type: actionTypes.FETCH_UPDATE_ASSESSMENT_FAILURE,
});

const fetchUpdateAssessmentRequest = () : IAssessmentAction => ({
  payload: null,
  type: actionTypes.FETCH_UPDATE_ASSESSMENT_REQUEST,
});

const fetchUpdateAssessmentSuccess = () : IAssessmentAction => ({
  payload: null,
  type: actionTypes.FETCH_UPDATE_ASSESSMENT_SUCCESS,
});

export const setTiebreak = (questions: Question[]) : IAssessmentAction => ({
  payload: questions,
  type: actionTypes.SET_TIEBREAK,
});

export default (
  assessmentDispatch: Dispatch<IAssessmentAction>,
  answers: Question[],
  errorCallback: () => void,
  successCallback: (assessmentScores: number[]) => void,
) : void => {
  assessmentDispatch(fetchUpdateAssessmentRequest());

  const onError = (error: ApiError) => {
    assessmentDispatch(fetchUpdateAssessmentFailure(error));
    errorCallback();
  };

  const onSuccess = ({ assessment: apiAssessment } : any) => {
    try {
      const {
        assessmentScores,
        finished,
        questions,
        tiebreak,
      } = apiAssessment;

      if (tiebreak) {
        const remainingQuestions = questions.slice(TOTAL_QUESTIONS, questions.length);
        if (remainingQuestions.length === 0) {
          errorCallback();
          if (DEVELOPMENT) {
            console.log('API is returning wrong format for tiebreak when updating the assessment');
          }
        }
        assessmentDispatch(setTiebreak(remainingQuestions));
      }

      if (finished) {
        assessmentDispatch(fetchUpdateAssessmentSuccess());
        successCallback(assessmentScores);
      }
    } catch (error) {
      onError(error);
    }
  };

  return api.users.updateAssessment(answers, onSuccess, onError);
};
