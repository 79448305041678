import Profile from 'classes/Profile/Profile.class';

import { IElements, IGeometry, IScales, IStyles } from '../../types';
import drawBands from './drawBands';
import drawDot from './drawDot';
import drawProfile from './drawProfile';
import drawQuadrantsLabels from './drawQuadrantsLabels';

const draw = (props: IDrawParams): void => {
  // References
  drawBands(props);
  drawDot(props);
  drawProfile(props);

  // Labels
  drawQuadrantsLabels(props);
};

interface IDrawParams {
  elements: IElements,
  geometry: IGeometry,
  profile: Profile,
  scales: IScales,
  styles: IStyles,
}

export default draw;
