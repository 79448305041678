import classnames from 'classnames';

import DYNAMICS_LABELS from 'app_constants/dynamicLabels';
import Profile from 'classes/Profile/Profile.class';

import { IGeometry, IStyles, IElements } from '../../types';

const isRight = (d: any, i: number) => i === 2 || i === 3;
const isBottom = (d: any, i: number) => i === 0 || i === 3;

const drawQuadrantsLabels = (
  { geometry, profile, styles, elements }: IDrawQuadrantsLabelsParams,
): void => {
  if (geometry && profile && styles && elements) {
    const {
      labels,
      quadrantsSigns,
      side,
    } = geometry;

    const {
      dynamicNameFontSize,
      energyNameFontSize,
      energyNameLineHeight,
      margin,
    } = labels;

    if (elements.text) {
      const quadrantsTextData : IQuadrantTextData[] = DYNAMICS_LABELS
        .map((dynamicText: string, dynamicIndex: number) => ({
          dynamic: `${dynamicIndex + 1}. ${dynamicText.toUpperCase()}`,
          dynamicIndex,
          dynamicText,
          user: {
            get energy() { return profile.energies[dynamicIndex].text; },
          },
        }));

      let quadrantsText = elements.text
        .selectAll('text')
        .data(quadrantsTextData, (d: IQuadrantTextData) => d.dynamic);

      quadrantsText
        .exit()
        .remove();

      const quadrantsTextEnter = quadrantsText
        .enter()
        .append('text')
        .attr('class', styles['quadrant-text'])
        .classed(styles.right, isRight);

      quadrantsTextEnter
        .append('tspan')
        .attr('class', styles['dynamic-name']);

      quadrantsTextEnter
        .append('tspan')
        .attr('x', 0)
        .attr('class', ({ dynamicIndex }: { dynamicIndex: number}) => classnames(
          styles['energy-label'],
          styles[`energy-label--dynamic-${dynamicIndex}`],
        ));

      quadrantsText = quadrantsText.merge(quadrantsTextEnter);

      quadrantsText
        .select('tspan:nth-child(1)')
        .text((d: IQuadrantTextData) => d.dynamic);

      quadrantsText
        .select('tspan:nth-child(2)')
        .text((d: IQuadrantTextData) => d.user.energy)
        .attr('font-size', 12);

      quadrantsText
        .attr('transform', (d: any, i: number) => {
          const quadrantSigns = quadrantsSigns[i];

          const offset = isBottom(d, i)
            ? margin.bottom + dynamicNameFontSize
            : margin.top + energyNameLineHeight;

          return `translate(${quadrantSigns.x * side}, ${quadrantSigns.y * (side + offset)})`;
        });

      quadrantsText
        .select('tspan:nth-child(1)')
        .attr('font-size', dynamicNameFontSize);

      quadrantsText
        .select('tspan:nth-child(2)')
        .attr('font-size', energyNameFontSize)
        .attr('dy', energyNameLineHeight);
    }
  }
};

interface IDrawQuadrantsLabelsParams {
  profile: Profile,
  styles: IStyles,
  elements: IElements,
  geometry: IGeometry,
}

interface IQuadrantTextData {
  dynamic: string;
  dynamicIndex: number;
  dynamicText: string;
  user: {
      readonly energy: string | null;
  };
}

export default drawQuadrantsLabels;
