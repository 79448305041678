/* eslint-disable react-hooks/exhaustive-deps */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import Answer from 'classes/assessment/Answer.class';
import Question from 'classes/assessment/Question.class';

import styles from '../Assessment.scss';

const WordPair = (props: IWordPairProps) : JSX.Element => {
  const [startTime, setStartTime] = useState<number>(0);
  const { onClick, question } = props;
  const { leftWordPair, rightWordPair, id } = question;
  const isMediumButton = leftWordPair?.text?.length > 13 || rightWordPair?.text?.length > 13;
  const isLargeButton = leftWordPair?.text?.length > 16 || rightWordPair?.text?.length > 16;
  const isExtraLargeButton = leftWordPair?.text?.length > 28 || rightWordPair?.text?.length > 28;
  const isSuperExtraLargeButton = leftWordPair?.text?.length > 38
    || rightWordPair?.text?.length > 38;

  useEffect(() => {
    setStartTime(new Date().getTime());
  });

  const handleClick = (response: IQuestionAnswer) => {
    const duration = new Date().getTime() - startTime;
    onClick(new Answer({ ...response, duration }));
  };

  return (
    <div className={styles['assessment__word-pair']}>
      <button
        className={classNames(
          { [styles.medium]: isMediumButton },
          { [styles.large]: isLargeButton },
          { [styles['extra-large']]: isExtraLargeButton },
          { [styles['super-extra-large']]: isSuperExtraLargeButton },
        )}
        onClick={() => {
          handleClick({ selectedWordPairId: leftWordPair?.id || 0, id });
        }}
        type="button"
      >
        {leftWordPair?.text}
      </button>
      <button
        className={classNames(
          { [styles.medium]: isMediumButton },
          { [styles.large]: isLargeButton },
          { [styles['extra-large']]: isExtraLargeButton },
          { [styles['super-extra-large']]: isSuperExtraLargeButton },
        )}
        onClick={() => {
          handleClick({ selectedWordPairId: rightWordPair?.id || 0, id });
        }}
        type="button"
      >
        {rightWordPair?.text}
      </button>
    </div>
  );
};

WordPair.propTypes = {
  onClick: PropTypes.func.isRequired,
  question: PropTypes.instanceOf(Question).isRequired,
};

export interface IQuestionAnswer {
  id: number,
  selectedWordPairId: number,
  duration?: number,
}

interface IWordPairProps {
  onClick: (answer: Answer) => void,
  question: Question,
}

export default WordPair;
