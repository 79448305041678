import { kebabCase } from 'lodash';
import classNames from 'classnames';
import propTypes from 'prop-types';
import React, { ReactNode } from 'react';

import { CHILDREN } from 'app_constants/propTypes';
import BeachBall, { IBeachBallProps } from 'generics/BeachBall/BeachBall';

import CardWrapper from './CardWrapper';
import styles from './Card.scss';

const Card = ({
  assessmentScores,
  children,
  className,
  media,
  mediaClassName,
  onClick,
  route,
  showBeachBall,
  subtitle,
  title,
}: ICardProps) : JSX.Element => {
  const key = kebabCase(title);
  return (
    <div className={styles.card__stage} data-cy={`card-${key}`}>

      <CardWrapper route={route} onClick={onClick}>
        <span className={classNames(className, [styles.card])}>

          <span className={styles.card__title__stage}>
            <strong className={styles.card__title__wrapper}>
              <span
                className={styles.card__title}
                title={title}
                data-cy={`card-title-${key}`}
              >
                {title}
              </span>
            </strong>
            {subtitle && (
              <span
                className={styles.card__subtitle}
                title={subtitle}
                data-cy={`card-subtitle-${key}`}
              >
                {subtitle}
              </span>
            )}
          </span>

          <span className={styles.card__media__wrapper}>
            <span className={classNames(mediaClassName, styles.card__media)}>
              {media}
            </span>
            {showBeachBall && (
              <span className={styles['card__beach-ball']}>
                <BeachBall assessmentScores={assessmentScores} />
              </span>
            )}
          </span>

        </span>
      </CardWrapper>

      {children && (
        <div className={styles.card__actions}>
          {children}
        </div>
      )}

    </div>
  );
};

Card.propTypes = {
  assessmentScores: BeachBall.propTypes.assessmentScores,
  children: CHILDREN,
  className: propTypes.string,
  media: propTypes.element,
  mediaClassName: propTypes.string,
  onClick: propTypes.func,
  route: CardWrapper.propTypes.route,
  showBeachBall: propTypes.bool,
  subtitle: propTypes.string,
  title: propTypes.string.isRequired,
};

Card.defaultProps = {
  assessmentScores: BeachBall.defaultProps.assessmentScores,
  children: null,
  className: null,
  media: null,
  mediaClassName: null,
  onClick: null,
  showBeachBall: false,
  subtitle: null,
  route: CardWrapper.defaultProps.route,
};

interface ICardProps extends IBeachBallProps {
  children?: ReactNode,
  className?: string,
  media?: ReactNode,
  mediaClassName?: string,
  onClick?: () => void,
  route?: string,
  showBeachBall?: boolean,
  subtitle?: string,
  title: string,
}

export default Card;
