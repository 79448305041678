import { Dispatch } from 'react';
import { mapValues, merge } from 'lodash';

import { ISessionInitialState } from 'app_state/types';
import controllers from 'api/controllers';
import expireSession from 'app_state/session/actions/expireSession';

import ApiCrypto from './ApiCrypto.class';
import ApiController from './ApiController.class';

const { API_ENCRYPTION_PASSPHRASE } = process.env;

const EXPIRATION_TIME = 1800000;

/**
 * @class Api5D
 * @description Basic API for Backend Requests
 * */
export default class Api5D {
  accounts?: any;

  charges?: any;

  connectionRequests?: any;

  contentManager?: any;

  crypto: ApiCrypto;

  notificationDispatch: Dispatch<any> | null;

  expirationTimeout: number | null;

  invites?: any;

  pdfs?: any;

  profiles?: any;

  session?: any;

  sessionDispatch: Dispatch<any> | null;

  sessionState?: ISessionInitialState;

  tips?: any;

  tokens?: any;

  users?: any;

  constructor() {
    this.crypto = new ApiCrypto(API_ENCRYPTION_PASSPHRASE || '');
    this.expirationTimeout = null;
    this.notificationDispatch = null;
    this.sessionDispatch = null;
    this.sessionState = undefined;

    const apiControllers = mapValues(controllers, (controller) => (
      new ApiController({
        api: this,
        controller,
      })));

    merge(this, apiControllers);
  }

  /**
   * @description Sets context state and dispatch
   */
  setSessionContext(state: ISessionInitialState, dispatch: Dispatch<any>) : void {
    this.sessionState = state;
    this.sessionDispatch = dispatch;
  }

  /**
   * @description Clears session expiration timeout
   */
  clearTimeout(): void {
    if (this.expirationTimeout) {
      clearTimeout(this.expirationTimeout);
      this.expirationTimeout = null;
    }
  }

  /**
   * @description Sets session expirtaiton timeout
   */
  setTimeout() : void {
    this.clearTimeout();
    this.expirationTimeout = window.setTimeout(
      this.dispatchExpireSession.bind(this),
      EXPIRATION_TIME,
    );
  }

  /**
   * @description Dispatches expire session action
   */
  dispatchExpireSession() : void {
    this.clearTimeout();
    if (this.sessionDispatch && this.notificationDispatch) {
      expireSession(this.sessionDispatch, this.notificationDispatch, 'sessionExpired');
    }
  }
}
