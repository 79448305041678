import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import classNames from 'classnames';
import propTypes from 'prop-types';
import React, { MouseEvent } from 'react';

import { ICON } from 'app_constants/propTypes';

import styles from './MenuOption.scss';

const MenuOption = ({ color, icon, label, onClick }: IContextMenuOptionProps) : JSX.Element => (
  <button
    className={
      classNames(
        styles['context-menu__option'],
        styles[color === 'secondary' ? 'context-menu__option__secondary' : 'context-menu__option__primary'],
      )
    }
    type="button"
    onClick={onClick}
  >
    <span className={styles['context-menu__option__icon']}>
      <FontAwesomeIcon icon={icon} />
    </span>
    <span className={styles['context-menu__option__label']}>
      {label}
    </span>
  </button>
);

MenuOption.propTypes = {
  color: propTypes.string,
  icon: ICON.isRequired,
  label: propTypes.string.isRequired,
  onClick: propTypes.func.isRequired,
};

MenuOption.defaultProps = {
  color: 'primary',
};
export interface IContextMenuOptionProps {
  color?: 'primary' | 'secondary',
  icon: IconDefinition,
  label: string,
  onClick: (event?: MouseEvent<HTMLButtonElement>) => void,
}

export default MenuOption;
