import { Dispatch } from 'react';

import { IInvitesAction } from 'app_state/types';
import history from 'utils/history';
import InvitationActionTypes from 'app_state/invites/actionTypes';
import URLS from 'app_constants/urls';

export const acceptInvitationAction = () : IInvitesAction => ({
  payload: null,
  type: InvitationActionTypes.ACCEPT_INVITATION,
});

export default (
  dispatch: Dispatch<any>,
) : void => {
  dispatch(acceptInvitationAction());
  history.push(URLS.signUp);
};
