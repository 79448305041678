import notificationTypes from 'app_constants/notificationTypes';

export default class Notification {
  label: string;

  params: string[];

  timeout?: NodeJS.Timeout;

  type: notificationTypes;

  constructor({ label, params = [], timeout, type } : INotification) {
    this.label = label;
    this.params = params;
    this.timeout = timeout;
    this.type = type;
  }
}

export interface INotification {
  label: string,
  params?: string[],
  timeout?: NodeJS.Timeout,
  type: notificationTypes,
}
