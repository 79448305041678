import { isNil } from 'lodash';
import classnames from 'classnames';

import d3 from 'vendor/d3';
import DYNAMICS_LABELS from 'app_constants/dynamicLabels';
import Profile from 'classes/Profile/Profile.class';

import { IElements, IGeometry, IProfileDraw, IScales, IStyles } from '../../types';

const RADIUS = 0.5;

const drawProfile = ({
  geometry,
  styles,
  scales,
  elements,
  profile,
}: IDrawProfileParams) : void => {
  if (geometry && styles && scales && elements && profile) {
    const {
      offset,
      plusRadiusRatio,
      quadrantsAngle,
    } = geometry;

    const data: IProfileDraw[] = [];

    const { scores } = profile;

    if (scores) {
      DYNAMICS_LABELS
        .forEach((dynamicName, dynamicIndex) => {
          const {
            energy,
            value,
          } = scores[dynamicIndex];

          let radius;
          let plusRadius;
          let hasPlus = false;

          if (!isNil(value)) {
            const balloonSize = scales.getBalloonSize ? scales.getBalloonSize(value) : 0;
            radius = RADIUS * scales.balloonDiameter(balloonSize);
            plusRadius = plusRadiusRatio * radius;
            hasPlus = energy?.endsWith('+') || false;
          }

          data.push({
            angle: quadrantsAngle[dynamicIndex],
            dynamicIndex,
            dynamicName,
            hasPlus,
            isThicker: false,
            plusRadius,
            radius,
          });
        });
    }

    if (elements.profile) {
      let profileElement = elements.profile
        .selectAll(`.${styles.profile}`)
        .data(data, (d: IProfileDraw) => d.dynamicName);

      profileElement
        .exit()
        .remove();

      const profileEnter = profileElement
        .enter()
        .append('g')
        .attr('class', (d: IProfileDraw) => {
          const { dynamicIndex } = d;

          return classnames(
            styles.profile,
            styles[`profile--dynamic-${dynamicIndex}`],
          );
        });

      profileEnter
        .append('path')
        .attr('class', 'balloon');

      const plusEnter = profileEnter
        .append('g')
        .attr('class', styles.plus);

      plusEnter.append('line');
      plusEnter.append('line');

      profileElement = profileElement.merge(profileEnter)
        .attr('transform', (d: IProfileDraw) => `rotate(${d.angle}) translate(${offset},${offset})`);

      profileElement
        .select('.balloon')
        .attr('d', (d: IProfileDraw) => {
          if (d.radius === undefined) {
            return null;
          }

          const pathGenerator = d3.path();

          pathGenerator.moveTo(0, 0);
          pathGenerator.lineTo(d.radius, 0);
          pathGenerator.arc(d.radius, d.radius, d.radius, -RADIUS * Math.PI, Math.PI);
          pathGenerator.closePath();
          return pathGenerator.toString();
        });

      profileElement
        .select(`.${styles.plus}`)
        .attr('transform', ({ radius }: IProfileDraw) => (
          radius
            ? `translate(${radius},${radius})`
            : undefined))
        .classed('hide', (d: IProfileDraw) => !d.hasPlus);

      profileElement
        .select(`.${styles.plus} line`)
        .attr('y1', ({ plusRadius }: IProfileDraw) => (
          plusRadius
            ? -plusRadius
            : undefined))
        .attr('y2', ({ plusRadius }: IProfileDraw) => plusRadius);

      profileElement
        .select(`.${styles.plus} line:nth-child(2)`)
        .attr('x1', ({ plusRadius }: IProfileDraw) => (
          plusRadius
            ? -plusRadius
            : undefined))
        .attr('x2', ({ plusRadius }: IProfileDraw) => plusRadius);
    }
  }
};

interface IDrawProfileParams {
  elements: IElements,
  geometry: IGeometry,
  profile: Profile,
  scales: IScales,
  styles: IStyles,
}

export default drawProfile;
