import { Dispatch, useContext } from 'react';

import { clearNotification } from 'app_state/notifications/actions';
import { ILoginProps } from 'api/controllers/types';
import { ISessionAction } from 'app_state/types';
import { SessionContext } from 'app_state/session/SessionProvider';
import { USERS_ACCOUNT_BLOCKED } from 'app_constants/apiErrorCodes';
import ApiError from 'classes/errors/ApiError.class';
import fetchLogin from 'app_state/session/actions/fetchLogin';
import useForm, { IErrors } from 'custom_hooks/useForm';
import useFormValidation from 'custom_hooks/useFormValidation';
import useNotification from 'custom_hooks/useNotification';

const useLogin = () : IUseLogin => {
  const { state, dispatch } = useContext(SessionContext);
  const { isAuthenticated } = state;
  const { validator } = useFormValidation();
  const { addErrorNotification, addWarningNotification } = useNotification();

  const handleSubmit = (values: ILoginProps) => {
    const onError = (error: ApiError) => {
      if (error?.errorCode === USERS_ACCOUNT_BLOCKED) {
        addWarningNotification('loginBlocked');
      } else {
        addErrorNotification('loginApiError');
      }
    };

    fetchLogin(dispatch, values, clearNotification, onError);
  };

  const handleValidation = ({ email, password } : ILoginProps) : IErrors => ({
    email: validator.validateRequired(email) || validator.validateEmail(email),
    password: validator.validateRequired(password),
  });

  const {
    errors,
    onChangeField,
    onSubmitForm,
    isValid,
    values = { email: null, password: null },
  } = useForm<ILoginProps>({ onSubmit: handleSubmit, onValidate: handleValidation });

  return {
    dispatch,
    errors,
    isAuthenticated,
    isValid,
    onChangeField,
    onSubmitForm,
    values,
  };
};

export interface IUseLogin {
  dispatch: Dispatch<ISessionAction>,
  errors: IErrors,
  isAuthenticated: boolean,
  isValid: boolean,
  onChangeField: (name: string, value: string | boolean) => void,
  onSubmitForm: (event?: React.FormEvent<HTMLFormElement> | undefined) => void,
  values: ILoginProps | {
    email: null;
    password: null;
  },
}

export default useLogin;
