import AcceptInvitation from 'pages/AcceptInvitation/AcceptInvitation';
import AdvicePage from 'pages/Advice/Advice';
import AssessmentPage from 'pages/Assessment/Assessment';
import ChangePassword from 'pages/ChangePassword/ChangePassword';
import CorporteAccount from 'pages/CorporateAccount/CorporateAccount';
import EditProfile from 'pages/EditProfile/EditProfile';
import ForgotPassword from 'pages/ForgotPassword/ForgotPassword';
import Home from 'pages/Home/Home';
import Invitation from 'pages/Invitation/Invitation';
import Kit from 'pages/Kit/Kit';
import LinkCorporateProfile from 'pages/LinkCorporateProfile/LinkCorporateProfile';
import Login from 'pages/Login/Login';
import Profile from 'pages/Profile/Profile';
import SignUpInvitation from 'components/pages/SignUpInvitation/SignUpInvitation';
import ResetPassword from 'components/pages/ResetPassword/ResetPassword';
import SendInvitation from 'pages/SendInvitation/SendInvitation';
import ShareReport from 'pages/ShareReport/ShareReport';
import SignUp from 'pages/SignUp/SignUp';
import Terms from 'pages/Terms/Terms';
import URLS from 'app_constants/urls';
import Welcome from 'pages/Welcome/Welcome';

const ROUTES = [
  {
    component: Home,
    exact: true,
    isPrivatePath: true,
    path: URLS.home,
  },
  {
    component: AssessmentPage,
    exact: true,
    ignoreLayout: true,
    isPrivatePath: true,
    isRestrictedLayout: true,
    path: URLS.assessment,
  },
  {
    component: AdvicePage,
    exact: true,
    isPrivatePath: true,
    path: URLS.advice,
  },
  {
    component: ChangePassword,
    exact: true,
    isPrivatePath: true,
    path: URLS.changePassword,
  },
  {
    component: Login,
    exact: true,
    isPrivatePath: false,
    noAuthLayout: true,
    path: URLS.login,
  },
  {
    component: Kit,
    exact: true,
    isPrivatePath: true,
    path: URLS.kit,
  },
  {
    component: Profile,
    exact: true,
    isPrivatePath: true,
    path: URLS.profile,
  },
  {
    component: EditProfile,
    exact: true,
    isPrivatePath: true,
    path: URLS.editProfile,
  },
  {
    component: Invitation,
    exact: true,
    isPrivatePath: false,
    path: URLS.invitation,
  },
  {
    component: SendInvitation,
    exact: true,
    isPrivatePath: true,
    path: URLS.sendInvitation,
  },
  {
    component: AcceptInvitation,
    exact: true,
    isPrivatePath: false,
    path: URLS.acceptInvitation,
  },
  {
    component: SignUp,
    exact: true,
    isPrivatePath: false,
    path: URLS.signUp,
  },
  {
    component: Welcome,
    exact: true,
    isPrivatePath: true,
    isRestrictedLayout: true,
    path: URLS.welcome,
  },
  {
    component: CorporteAccount,
    exact: true,
    isPrivatePath: true,
    isRestrictedLayout: true,
    path: URLS.corporateAccount,
  },
  {
    component: LinkCorporateProfile,
    exact: true,
    isPrivatePath: true,
    isRestrictedLayout: true,
    path: URLS.linkCorporateProfile,
  },
  {
    component: Terms,
    exact: true,
    isPrivatePath: false,
    path: URLS.terms,
  },
  {
    component: ForgotPassword,
    exact: true,
    isPrivatePath: false,
    noAuthLayout: true,
    path: URLS.forgotPassword,
  },
  {
    component: ResetPassword,
    exact: true,
    isPrivatePath: false,
    path: URLS.resetPassword,
  },
  {
    component: SignUpInvitation,
    exact: true,
    isPrivatePath: false,
    noAuthLayout: true,
    path: URLS.signUpInvitation,
  },
  {
    component: ShareReport,
    exact: true,
    isPrivatePath: true,
    path: URLS.shareReport,
  },
];

export default ROUTES;
