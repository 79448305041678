import React, { ReactElement } from 'react';
import shortid from 'shortid';
import { CHILDREN } from 'app_constants/propTypes';
import Content from 'generics/Content/Content';
import styles from './Slide.scss';

const Slide = ({
  titleIcon,
  title,
  content,
  controls,
  controlsTitle,
}: ISlide) : JSX.Element => (
  <div>
    <section className={styles.slide__stage}>
      <div className={styles.slide__wrapper}>
        <div className={styles.slide}>
          <h2 className={styles.slide__title__wrapper}>
            <strong className={styles.slide__title}>
              {title}
            </strong>
            <span
              className={styles.slide__title__icon}
              aria-hidden="true"
            >
              {titleIcon}
            </span>
          </h2>
          <div className={styles.slide__content}>
            {content}
          </div>
        </div>
        <div className={styles.controls__wrapper}>
          {controlsTitle && (
            <Content
              className={styles.controls__title}
              content={controlsTitle}
            />
          )}
          <div className={styles.controls}>
            {controls.map((control) => (
              <div className={styles.control} key={shortid()}>
                {control}
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  </div>
);

Slide.propTypes = {
  titleIcon: CHILDREN.isRequired,
  title: CHILDREN.isRequired,
  content: CHILDREN.isRequired,
  controls: CHILDREN.isRequired,
  controlsTitle: CHILDREN,
};

Slide.defaultProps = {
  controlsTitle: null,
};

interface ISlide {
  titleIcon: ReactElement,
  title: ReactElement,
  content: ReactElement,
  controls: ReactElement,
  controlsTitle: ReactElement,
}

export default Slide;
