import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import propTypes from 'prop-types';
import React, { useRef, useState } from 'react';

import { CHILDREN } from 'app_constants/propTypes';
import Button from 'generics/Button/Button';
import styles from './PhotoEditor.scss';

const IMAGE_MAX_SIZE = 1000000;
const IMAGE_TYPES = ['image/jpeg', 'image/png'];

const PhotoEditor = ({
  avatar,
  label,
  helperText,
  onChange,
}: IPhotoEditor) : JSX.Element => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [error, setError] = useState('');
  const { t } = useTranslation('generics');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const image = event.target.files ? event.target.files[0] : null;

    if (image) {
      const invalidType = !(IMAGE_TYPES.includes(image.type));
      const invalidSize = image.size > IMAGE_MAX_SIZE;
      const isValidImage = !invalidType && !invalidSize;

      if (isValidImage) {
        onChange(image);
        setError('');
      } else {
        setError(invalidType ? t('errors.file.invalid') : t('errors.file.size'));
      }
    }
  };

  return (
    <div className={styles['photo-editor']}>

      <div className={styles['photo-editor__avatar']}>
        {avatar}
      </div>

      <div className={styles['photo-editor__file-input__wrapper']}>
        <Button isSecondary isSmall onClick={() => fileInputRef?.current?.click()}>
          {label}
        </Button>
        <input
          ref={fileInputRef}
          className={styles['photo-editor__file-input']}
          id="avatar"
          name="avatar"
          type="file"
          accept="image/png, image/jpeg"
          onChange={handleChange}
        />
      </div>

      <p className={styles['photo-editor__helper-text']}>
        {helperText}
      </p>

      <span className={classnames(styles['photo-editor__error'], 'error-text')}>
        {error}
      </span>

    </div>
  );
};

PhotoEditor.propTypes = {
  avatar: CHILDREN.isRequired,
  helperText: propTypes.string.isRequired,
  label: propTypes.string.isRequired,
  onChange: propTypes.func.isRequired,
};

interface IPhotoEditor {
  avatar: JSX.Element,
  helperText: string,
  label: string,
  onChange: (img: any) => void,
}

export default PhotoEditor;
