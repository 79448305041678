import { pick, chain } from 'lodash';

const getElementGeometry = (
  elem: HTMLElement, additionalProps?: string[],
) : any => {
  let inspectedProps = ['width', 'height'];

  if (additionalProps) {
    inspectedProps = inspectedProps.concat(additionalProps);
  }

  if (elem) {
    const cs = getComputedStyle(elem);
    const size = chain(pick<CSSStyleDeclaration>(cs, inspectedProps))
      ?.mapValues((pxValue: string) => parseFloat(pxValue))
      ?.value();

    return size;
  }

  return {};
};

export default getElementGeometry;
