/* eslint-disable react-hooks/exhaustive-deps */
import { useParams } from 'react-router-dom';
import { useState } from 'react';

import api from 'api';
import Advice from 'classes/Advice/Advice.class';
import useNotification from 'custom_hooks/useNotification';

const useSavedTips = () : IUseSavedTips => {
  const { id, key: moduleKey } = useParams<paramsType>();
  const profileId = parseInt(id, 10);
  const [savedTips, setSavedTips] = useState<Advice[]>([]);
  const { addErrorNotification } = useNotification();
  const [page, setPage] = useState(1);
  const [areMorePages, setMorePages] = useState(false);

  const handleFetch = (newPage = 1) => {
    const onSuccess = ({ tips = [], meta }: any) => {
      const morePages = meta?.morePages ?? false;
      const newTips = tips.map((tip: any) => new Advice({ ...tip }));
      setSavedTips(newPage === 1 ? newTips : [...savedTips, ...newTips]);
      setPage(newPage);
      setMorePages(morePages);
    };

    const onError = () => {
      addErrorNotification('getSavedTips');
    };

    api.tips.getSavedTips(
      { thirdProfileId: id, key: moduleKey, page: newPage, type: moduleKey ? 'pair' : undefined },
      onSuccess,
      onError,
    );
  };

  const handleGetMore = () => {
    handleFetch(page + 1);
  };

  const onSaveError = () => {
    addErrorNotification('notCompleted');
  };

  const addSavedTip = (tipId: number, tip: Advice) => {
    const newTip = new Advice({ ...tip, id: tipId });
    setSavedTips([...savedTips, newTip]);
  };

  const removeSavedTip = (tipId: number) => {
    const newTips = savedTips.reduce(
      (result, tip) => (tip.id === tipId ? result : [...result, tip]),
      <Advice[]>[],
    );
    setSavedTips(newTips);
  };

  const handleSaveTip = (tip: Advice, onFinish?: () => void) => {
    const onSuccess = ({ tip: { id: tipId } } : any) => {
      addSavedTip(tipId, tip);
      if (onFinish) {
        onFinish();
      }
    };

    api.tips.saveTip({
      thirdProfileId: profileId,
      tip,
    }, onSuccess, onSaveError);
  };

  const handleUnsaveTip = (tipId: number, onFinish?: () => void) => {
    const onSuccess = () => {
      removeSavedTip(tipId);
      if (onFinish) {
        onFinish();
      }
    };

    api.tips.unsaveTip(tipId, onSuccess, onSaveError);
  };

  return {
    areMorePages,
    onFetchSavedTips: handleFetch,
    onGetMore: handleGetMore,
    onSaveTip: handleSaveTip,
    onUnsaveTip: handleUnsaveTip,
    savedTips,
  };
};

export interface IUseSavedTips {
  savedTips: Advice[],
  onSaveTip: (tip: Advice, onFinish?: () => void) => void,
  onUnsaveTip: (tipId: number, onFinish?: () => void) => void,
  onFetchSavedTips: (page?: number) => void,
  areMorePages: boolean,
  onGetMore: () => void,
}

type paramsType = {
  id: string,
  key?: string,
}

export default useSavedTips;
