import { useState } from 'react';

import api from 'api';
import useForm, { IErrors, IUseForm } from 'custom_hooks/useForm';
import useFormValidation from 'custom_hooks/useFormValidation';

const useForgotPassword = () : IUseForgotPassword => {
  const [isComplete, setIsComplete] = useState(false);
  const { validator } = useFormValidation();

  const handleSubmit = ({ email }: { email: string }) => {
    const onFinish = () => {
      setIsComplete(true);
    };
    // we are not handling error for security reasons
    api.users.forgotPassword(email, onFinish, onFinish);
  };

  const handleValidate = ({ email } : { email: string }) : IErrors => ({
    email: validator.validateRequired(email) || validator.validateEmail(email),
  });

  const form = useForm<{ email: string }>({ onSubmit: handleSubmit, onValidate: handleValidate });

  return {
    form,
    isComplete,
  };
};

export interface IUseForgotPassword {
  form: IUseForm<{ email: string }>,
  isComplete: boolean,
}

export default useForgotPassword;
