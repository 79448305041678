enum URLS {
  acceptInvitation = '/accept-invitation',
  advice = '/profile/:id/advice/:key',
  assessment = '/assessment',
  changePassword = '/change-password',
  corporateAccount = '/corporate-account',
  deleteAccount = '/delete-account',
  editProfile = '/edit-profile',
  forgotPassword = '/forgot-password',
  home = '/',
  invitation = '/invitation/:token',
  kit = '/kit/:key',
  linkCorporateProfile = '/link-corporate-profile',
  login = '/login',
  profile = '/profile/:id',
  resetPassword = '/reset-password/:token',
  sendInvitation = '/invite',
  shareReport = '/shareReport',
  signUp = '/sign-up',
  signUpInvitation = '/signUpInvitation',
  terms = '/terms',
  welcome = '/welcome',
}

export default URLS;
