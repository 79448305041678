import { IElements, IGeometry, IStyles } from '../../types';

const drawDot = ({ geometry, styles, elements }: IDrawDotParams): any => {
  if (geometry && styles && elements) {
    if (elements.references) {
      const dot = elements.references.selectAll(`.${styles.dot}`)
        .data([{ radius: geometry.dotRadius }]);

      dot.exit().remove();

      dot.enter()
        .append('circle')
        .attr('class', styles.dot)
        .merge(dot)
        .attr('r', (d:any) => d.radius);
    }
  }
};

interface IDrawDotParams {
  elements: IElements,
  geometry: IGeometry,
  styles: IStyles,
}

export default drawDot;
