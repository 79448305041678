/* eslint-disable react-hooks/exhaustive-deps */
import { useParams } from 'react-router-dom';
import React, { useContext, useEffect } from 'react';

import { InvitesContext } from 'app_state/invites/InvitesProvider';
import { SessionContext } from 'app_state/session/SessionProvider';
import fetchInvitation from 'app_state/invites/actions/fetchInvitation';
import resetState from 'app_state/session/actions/resetState';
import useNotification from 'custom_hooks/useNotification';

const Invitation = () : JSX.Element => {
  const { dispatch } = useContext(InvitesContext);
  const { dispatch: sessionDispatch } = useContext(SessionContext);
  const { token } = useParams<{ token: string }>();
  const { addErrorNotification } = useNotification();

  const onError = () => {
    addErrorNotification('invalidInvitation');
  };

  useEffect(() => {
    sessionDispatch(resetState());
    fetchInvitation(dispatch, { token }, onError);
  }, [token]);

  return <div>Invalid Invitation</div>;
};

export default Invitation;
