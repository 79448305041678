import moment from 'moment';

import { CONTENT_TYPES } from 'app_constants/contentTypes';

import { IDownloadOverviewReport, IFormDataParams, ISettingsResult } from './types';

const BLOB_OPTIONS = { type: CONTENT_TYPES.pdf };

const DATE_FORMAT = 'MMDDYYYY';

const FETCH_OPTIONS = {
  headers: {
    enctype: CONTENT_TYPES.form,
  },
  method: 'POST',
  responseType: 'blob',
};

const formData = (data: IFormDataParams, validCode: string | undefined) => {
  const blob = new Blob([JSON.stringify(data)], BLOB_OPTIONS);
  const form = new FormData();

  form.append('report_data', blob);

  if (validCode) {
    form.append('apgi_code', validCode);
  }

  return form;
};

/**
 * @description Settings for controller
 * @module API controller / Pdfs
 */
export default {

  downloadOverviewPDF: {
    fileName: '',

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    afterResponse(originalResponse: any, onFinish: any) : void {
      onFinish(originalResponse, this.fileName);
    },

    /**
   * @description Request settings (Download Overview PDF)
   * @param  {object} options
   * @param  {string} options.css - The CSS selectors applied in the 'EnergyMap' component.
   * @param  {number} options.id - The profile id
   * @param  {string} options.name - User Profile name
   * @param  {string} options.svg - svg HTML (from Energy Map chart)
   * @returns {object} - Request settings.
   */
    settings({ css, id, name, svg, validCode } : IDownloadOverviewReport) : ISettingsResult {
      this.fileName = `${name}_Overview_${moment().format(DATE_FORMAT)}.pdf`;

      return {
        ...FETCH_OPTIONS,
        endpoint: '/personal/pdfs/overview-report',
        body: formData({ css, profiles: [{ id, svg }] }, validCode),
      };
    },
  },
};
