import API from 'classes/api/Api.class';

declare global {
  interface Window {
    personalsApi5D: API;
  }
}

if (!window.personalsApi5D) {
  window.personalsApi5D = new API();
}

export default window.personalsApi5D;
