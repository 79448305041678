import { FixedSizeArray } from 'fixed-size-array';
import classNames from 'classnames';
import propTypes from 'prop-types';
import React from 'react';

import { balloonShape } from 'app_constants/charts';
import Svg from 'generics/Svg/Svg';

import styles from './BeachBall.scss';

const defaultDeliberateMin = 5;
const defaultDeliberateMax = 8;
const defaultThreshold = 9;
const defaultAssessmentScores: FixedSizeArray<4, number> = [0, 0, 0, 0];

const BALLOON_LIST: Array<{ key: string, transform?: string }> = [
  {
    key: 'e1',
    transform: 'rotate(90)',
  },
  {
    key: 'e2',
    transform: 'rotate(180)',
  },
  {
    key: 'e3',
    transform: 'rotate(270)',
  },
  {
    key: 'e4',
  },
];

const BeachBall = ({
  assessmentScores = defaultAssessmentScores,
  threshold = defaultThreshold,
}: IBeachBallProps) : JSX.Element => {
  const isFullDeliberate = assessmentScores?.every(
    (score) => Number(score) >= defaultDeliberateMin && Number(score) <= defaultDeliberateMax
  );
  const balloonList = BALLOON_LIST
    .map(({ key, transform }, index) => {
      const hasColoredBalloon = ((assessmentScores[index] || 0) >= threshold) || isFullDeliberate;
      const transformGroup = transform
        ? `${transform} translate(0.238, 0.238)`
        : 'translate(0.238, 0.238)';

      return (
        <g
          key={key}
          transform={transformGroup}
          className={classNames(
            [styles['beach-ball__balloon']],
            { [styles['is-filled']]: hasColoredBalloon },
          )}
        >
          <path
            d={balloonShape(2.3805)}
          />
        </g>
      );
    });

  return (
    <Svg
      className={styles['beach-ball']}
      viewBox="0 0 10 10"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="translate(5, 5)">
        {balloonList}
      </g>
    </Svg>
  );
};

BeachBall.propTypes = {
  assessmentScores: propTypes.arrayOf(propTypes.number),
  threshold: propTypes.number,
};

BeachBall.defaultProps = {
  assessmentScores: defaultAssessmentScores,
  threshold: defaultThreshold,
};

export interface IBeachBallProps {
  assessmentScores?: FixedSizeArray<4, number | null>,
  threshold?: number,
}

export default BeachBall;
