import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React, { useContext, useState } from 'react';

import { InvitesContext } from 'app_state/invites/InvitesProvider';
import acceptInvitation from 'app_state/invites/actions/acceptInvitation';
import Button from 'generics/Button/Button';
import declineInvitation from 'app_state/invites/actions/fetchDeclineInvitation';
import INVITATION_STATUS from 'app_constants/invitationStatus';
import Modal from 'generics/Modal/Modal';
import PageWrapper from 'generics/PageWrapper/PageWrapper';
import URLS from 'app_constants/urls';
import useNotification from 'custom_hooks/useNotification';

const AcceptInvitation = () : JSX.Element => {
  const { state, dispatch } = useContext(InvitesContext);
  const { t } = useTranslation('acceptInvitation');
  const history = useHistory();
  const location = useLocation();
  const { addSuccessNotification } = useNotification();

  const { currentInvitation } = state;
  const { status, token = '' } = currentInvitation || {};

  const [error, setError] = useState('');
  const onError = () => setError(t('error'));

  const path = {
    pathname: URLS.login,
    state: { from: location },
  };

  if (!currentInvitation) {
    return <Redirect to={path} />;
  }

  if (status === INVITATION_STATUS.declined) {
    addSuccessNotification('invitationDeclined');
    return <Redirect to={path} />;
  }

  const content = (
    <Modal
      isVisible
      onClose={() => history.push(URLS.login)}
    >
      <div className="u-align--center">
        <p>
          <strong>
            {t('joinText')}
          </strong>
        </p>
        <br />
        <Button onClick={() => acceptInvitation(dispatch)}>
          {t('accept')}
        </Button>
        &nbsp;
        <Button isSecondary onClick={() => declineInvitation({ token }, dispatch, onError)}>
          {t('decline')}
        </Button>
      </div>
    </Modal>
  );

  return (
    <PageWrapper>
      { content }
      {
        error && (
          <p>{error}</p>
        )
      }
    </PageWrapper>
  );
};

export default AcceptInvitation;
