import { has } from 'lodash';

/**
 * Get all CSS rules applied to a node element
 * @param  {HTMLElement} nodeElement - HTML DOM Element
 * @return {string} All styles definitions applied to an element
 */
const getMatchedCSSRules = (nodeElement: HTMLElement | any) : string => {
  if (nodeElement) {
    const element = nodeElement;

    element.matches = nodeElement.matches || nodeElement.webkitMatchesSelector
      || nodeElement.mozMatchesSelector || nodeElement.msMatchesSelector
      || nodeElement.oMatchesSelector;

    return [].slice.call(document.styleSheets)
      .reduce((sheetAccumulator, sheet: CSSStyleSheet) => (
        sheetAccumulator + [].slice.call(has(sheet, 'rules') ? sheet.rules : sheet.cssRules)
          .reduce((ruleAccumulator: string, rule: CSSStyleRule) => {
            // some inherit selectors could be invalid for the current browser
            try {
              if (element.matches(rule.selectorText)) {
                return ruleAccumulator + rule.cssText;
              }
            } catch (e) { return ruleAccumulator; }

            return ruleAccumulator;
          }, '')
      ), '');
  }
  return '';
};

export default getMatchedCSSRules;
