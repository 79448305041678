/* eslint-disable react-hooks/exhaustive-deps */
import { useHistory } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';

import { AssessmentContext } from 'app_state/assessment/AssessmentProvider';
import { NotificationsContext } from 'app_state/notifications/NotificationsProvider';
import { SessionContext } from 'app_state/session/SessionProvider';
import { USER_ALREADY_TOOK_ASSESSMENT } from 'app_constants/apiErrorCodes';
import addAnswer from 'app_state/assessment/actions/addAnswer';
import Answer from 'classes/assessment/Answer.class';
import expireSession from 'app_state/session/actions/expireSession';
import fetchAssessment from 'app_state/assessment/actions/fetchAssessment';
import fetchContent from 'app_state/session/actions/fetchContent';
import fetchUpdateAssessment from 'app_state/assessment/actions/fetchUpdateAssessment';
import Profile from 'classes/Profile/Profile.class';
import Question from 'classes/assessment/Question.class';
import updateUser from 'app_state/session/actions/updateUser';
import URLS from 'app_constants/urls';
import useNotification from 'custom_hooks/useNotification';

const ASSESSMENT_TIMEOUT = 900000;

const useAssessment = () : IUseAssessment => {
  const history = useHistory();
  const { state: { currentUser }, dispatch: sessionDispatch } = useContext(SessionContext);
  const { dispatch: notificationDispatch } = useContext(NotificationsContext);
  const { addErrorNotification } = useNotification();
  const [isCompleted, setIsCompleted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {
    state: { answers, remainingQuestions },
    dispatch: assessmentDispatch,
  } = useContext(AssessmentContext);

  const currentQuestion = isCompleted || remainingQuestions.length === 0
    ? null : remainingQuestions[0];

  const handleUpdateAssessment = (assessmentScores: number[]) => {
    setIsCompleted(true);
    setIsLoading(false);

    const profile = new Profile({
      ...currentUser,
      user: {
        ...currentUser?.user,
        assessmentScores,
        hasOngoingAssessment: false,
      },
    });

    sessionDispatch(updateUser(profile));
    fetchContent(sessionDispatch);
  };

  const updateAssessment = () => {
    setIsLoading(true);
    const onError = () => {
      addErrorNotification('assessmentUpdateApiError');
      setIsLoading(false);
    };

    fetchUpdateAssessment(assessmentDispatch, answers, onError, handleUpdateAssessment);
  };

  useEffect(() => {
    setIsLoading(true);

    const onError = (error:any) => {
      setIsLoading(false);

      if (error?.errorCode === USER_ALREADY_TOOK_ASSESSMENT) {
        addErrorNotification('assessmentAlreadyCompleted');
        history.push(URLS.corporateAccount);
        return;
      }

      addErrorNotification('assessmentApiError');
    };

    fetchAssessment(assessmentDispatch, currentUser?.user.hasOngoingAssessment || false, onError);

    const timeout = setTimeout(() => {
      expireSession(sessionDispatch, notificationDispatch, 'assessmentTimeExpired');
      setIsLoading(false);
    }, ASSESSMENT_TIMEOUT);

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    if (answers.length > 0 && remainingQuestions.length === 0) {
      updateAssessment();
    } else if (isLoading && remainingQuestions.length !== 0) {
      setIsLoading(false);
    }
  }, [answers, remainingQuestions]);

  const onFinish = () => history.push(URLS.home);

  const onAnswer = (answer: Answer) => {
    assessmentDispatch(addAnswer(answer));
  };

  return {
    currentQuestion,
    currentUser,
    isCompleted,
    isLoading,
    onAnswer,
    onFinish,
  };
};

export default useAssessment;

export interface IUseAssessment {
  currentQuestion: Question | null,
  currentUser: Profile | null,
  isCompleted: boolean,
  isLoading: boolean,
  onAnswer: (answer: Answer) => void,
  onFinish: () => void,
}
