import { Link } from 'react-router-dom';
import propTypes from 'prop-types';
import React, { ReactNode } from 'react';

import { CHILDREN } from 'app_constants/propTypes';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons';
import IconButton from 'generics/IconButton/IconButton';
import styles from './CardWrapper.scss';

const CardWrapper = ({ children, route, onClick }: ICardWrapperProps) : JSX.Element => (
  route || onClick ? (
    <Link
      className={styles.card__wrapper}
      href={route}
      to={route}
      onClick={onClick}
    >
      { children }
      <IconButton
        tag="span"
        className={styles['card__link-indicator']}
        icon={faChevronRight}
      />
    </Link>
  ) : (
    <span className={styles.card__wrapper}>
      {children}
    </span>
  ));

CardWrapper.propTypes = {
  children: CHILDREN.isRequired,
  route: propTypes.string,
  onClick: propTypes.func,
};

CardWrapper.defaultProps = {
  route: '',
  onClick: null,
};

interface ICardWrapperProps {
  children: ReactNode,
  route: string,
  onClick?: () => void,
}

export default CardWrapper;
