import { IAction } from 'app_state/types';
import Notification from 'classes/Notification/Notification.class';
import combineReducers from 'utils/combineReducers';

import initialState from './initialState.json';
import notificationsActionTypes from './actionTypes';

const currentNotification = (
  state: Notification | null = initialState.currentNotification,
  action: IAction,
): Notification | null => {
  switch (action.type) {
    case notificationsActionTypes.ADD_NOTIFICATION:
      return action.payload;

    case notificationsActionTypes.CLEAR_NOTIFICATION:
      return null;

    default:
      return state;
  }
};

export default combineReducers({
  currentNotification,
});
