import { get } from 'lodash';
import React, { ComponentProps } from 'react';
import propTypes from 'prop-types';
import styles from './Textarea.scss';

const Textarea = ({
  error,
  label,
  ...rest
}: ITextareaProps) : JSX.Element => (
  <>
    <div className={styles.textarea__wrapper}>
      <textarea
        className={styles.textarea}
        {...rest}
      />
      <label
        className={styles.textarea__label}
        htmlFor={get(rest, 'id')}
      >
        {label}
      </label>
    </div>
    {error && (
      <span className="error-text">
        {error}
      </span>
    )}
  </>
);

Textarea.propTypes = {
  error: propTypes.string,
  label: propTypes.string.isRequired,
};

Textarea.defaultProps = {
  error: null,
};

interface ITextareaProps extends ComponentProps<'textarea'> {
  error?: string | null,
  label: string,
}

export default Textarea;
