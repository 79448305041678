import { IAction } from 'app_state/types';
import initialState from 'app_state/session/initialState.json';
import Profile from 'classes/Profile/Profile.class';
import sessionActionTypes from 'app_state/session/actionTypes';

const currentUser = (
  state: Profile | null = initialState.currentUser,
  action: IAction,
): Profile | null => {
  switch (action.type) {
    case sessionActionTypes.FETCH_EDIT_PROFILE_SUCCESS:
    case sessionActionTypes.FETCH_LOGIN_SUCCESS:
    case sessionActionTypes.FETCH_SIGN_UP_SUCCESS:
    case sessionActionTypes.UPDATE_USER:
      return action.payload;

    case sessionActionTypes.RESET_STATE:
      return initialState.currentUser;

    case sessionActionTypes.FETCH_ACCOUNT_SUCCESS:
      return new Profile({
        ...state,
        account: action.payload,
      });

    case sessionActionTypes.FETCH_UPDATE_AVATAR_SUCCESS:
      return new Profile({
        ...state,
        avatar: action.payload,
      });

    case sessionActionTypes.FETCH_LINK_CORPORATE_SUCCESS:
      return new Profile({
        ...state,
        user: {
          ...state?.user,
          assessmentScores: action.payload.assessmentScores,
          hasActiveAssessment: false,
          hasOngoingAssessment: false,
        },
      });

    default:
      return state;
  }
};

export default currentUser;
