import { I18nextProvider } from 'react-i18next';
import { Router } from 'react-router-dom';
import history from 'utils/history';
import HttpsRedirect from 'react-https-redirect';
import i18n from 'utils/i18n';
import React from 'react';

import { AssessmentProvider } from 'app_state/assessment/AssessmentProvider';
import { InvitesProvider } from 'app_state/invites/InvitesProvider';
import { NotificationsProvider } from 'app_state/notifications/NotificationsProvider';
import { SessionProvider } from 'app_state/session/SessionProvider';
import NoPrint from 'components/NoPrint/NoPrint';
import Routes from 'components/Routes/Routes';

export default () : JSX.Element => (
  <React.StrictMode>
    <SessionProvider>
      <InvitesProvider>
        <AssessmentProvider>
          <NotificationsProvider>
            <I18nextProvider i18n={i18n}>
              <HttpsRedirect>
                <NoPrint>
                  <Router history={history}>
                    <Routes />
                  </Router>
                </NoPrint>
              </HttpsRedirect>
            </I18nextProvider>
          </NotificationsProvider>
        </AssessmentProvider>
      </InvitesProvider>
    </SessionProvider>
  </React.StrictMode>
);
