import {
  ISendStripeTransactionProps,
  ISettingsResult,
  IValidateTradeshowCode,
} from './types';

/**
   * @description Settings for controller
   */
export default {
  getAllCountries: {
    /**
       * @description Get request settings (Get All Countries)
       * @return {object} Request Settings
       */
    settings() : ISettingsResult {
      return {
        endpoint: '/country',
        method: 'GET',
      };
    },
  },
  payAPGIReport: {
    /**
       * @description Get request settings (Get All Countries)
       * @return {object} Request Settings
       */
    settings() : ISettingsResult {
      return {
        endpoint: '/personal/charges/pay_apgi_report',
        method: 'GET',
      };
    },
  },
  sendPayment: {
    /**
       * @description Post request settings (Send Stripe Report Payment)
       * @param  {object} options
       * @param  {string} options.stripeToken - Stripe transaction token
       * @param  {string} options.stripeEmail - Stripe transaction email
       * @return {object} Request Settings
       */
    settings(
      { countryId, fullName, postalCode, stripeToken, stripeEmail } : ISendStripeTransactionProps,
    ) : ISettingsResult {
      return {
        endpoint: '/personal/charges',
        body: {
          countryId,
          fullName,
          postalCode,
          stripeToken,
          stripeEmail,
        },
        method: 'POST',
      };
    },
  },
  validateTradeshowCode: {
    /**
       * @description Post request settings (Send Stripe Report Payment)
       * @param  {object} options
       * @param  {string} options.stripeToken - Stripe transaction token
       * @param  {string} options.stripeEmail - Stripe transaction email
       * @return {object} Request Settings
       */
    settings(
      { tradeshowCode } : IValidateTradeshowCode,
    ) : ISettingsResult {
      return {
        endpoint: `/personal/apgi_code/valid_code/${tradeshowCode}`,
        method: 'GET',
      };
    },
  },
};
