import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IContextMenuOptionProps } from 'components/generics/ContextMenu/components/MenuOption/MenuOption';
import { USERS_UPDATE_PASSWORD_INVALID } from 'app_constants/apiErrorCodes';
import api from 'api';
import ApiError from 'classes/errors/ApiError.class';
import URLS from 'app_constants/urls';
import useForm, { IErrors } from 'custom_hooks/useForm';
import useFormContextMenu from 'custom_hooks/useFormContextMenu';
import useFormValidation from 'custom_hooks/useFormValidation';
import useNotification from 'custom_hooks/useNotification';

const useChangePassword = () : IUseChangePassword => {
  const { t } = useTranslation(['changePassword']);
  const history = useHistory();
  const [currentPasswordApiError, setCurrentPasswordApiError] = useState<string | null>(null);
  const { validator } = useFormValidation();
  const { addErrorNotification, addSuccessNotification } = useNotification();

  const handleValidate = (
    { currentPassword, password, passwordConfirmation }: IChangePasswordFormProps,
  ) : IErrors => ({
    currentPassword: validator.validateRequired(currentPassword ?? ''),
    password: validator.validateRequired(password ?? '')
      || validator.validatePassword(password ?? '', currentPassword ?? ''),
    passwordConfirmation: validator.validateRequired(passwordConfirmation ?? '')
      || validator.validatePasswordConfirmation(passwordConfirmation ?? '', password ?? ''),
  });

  const handleSubmit = (values: IChangePasswordFormProps) => {
    const onSuccess = () => {
      history.push(URLS.editProfile);
      addSuccessNotification('passwordChanged');
    };

    const onError = (error: ApiError) => {
      if (error.errorCode === USERS_UPDATE_PASSWORD_INVALID) {
        setCurrentPasswordApiError(t('currentPasswordError'));
        handleValidate(values);
      } else {
        addErrorNotification('changePasswordApiError');
      }
    };

    api.users.changePassword(values, onSuccess, onError);
  };

  const {
    errors,
    isUpdated,
    isValid,
    onChangeField,
    onSubmitForm,
    values = { currentPassword: null, password: null, passwordConfirmation: null },
  } = useForm<IChangePasswordFormProps>({ onSubmit: handleSubmit, onValidate: handleValidate });

  const contextMenu = useFormContextMenu({ onSubmitForm: () => handleSubmit(values) });

  const handleChange = (name: string, value: string | boolean) => {
    if (name === 'currentPassword') {
      setCurrentPasswordApiError(null);
    }
    onChangeField(name, value);
  };

  const handleGoBack = () => {
    if (isUpdated) {
      contextMenu.onShow(URLS.editProfile, isValid);
    } else {
      history.push(URLS.editProfile);
    }
  };

  return {
    contextMenu: {
      isVisible: contextMenu.isVisible,
      options: contextMenu.options,
      onClose: contextMenu.onClose,
    },
    form: {
      canSubmit: isValid,
      currentPasswordApiError,
      errors,
      onChangeField: handleChange,
      onSubmitForm,
      values,
    },
    onGoBack: handleGoBack,
  };
};

export default useChangePassword;

export interface IChangePasswordFormProps {
  currentPassword: string | null,
  password: string | null,
  passwordConfirmation: string | null,
}

export interface IContextMenu {
  options: IContextMenuOptionProps[],
  isVisible: boolean,
  onClose: () => void,
}

export interface IForm {
  canSubmit: boolean,
  currentPasswordApiError: string | null,
  errors: IErrors,
  onChangeField: (name: string, value: string | boolean) => void,
  onSubmitForm: () => void,
  values: IChangePasswordFormProps
    | { currentPassword: null, password: null, passwordConfirmation: null },
}

export interface IUseChangePassword {
  contextMenu: IContextMenu,
  form: IForm,
  onGoBack: () => void,
}
