import { ITradeshowProps } from 'api/controllers/types';
import api from 'api';
import useForm, { IErrors } from 'custom_hooks/useForm';
import useFormValidation from 'custom_hooks/useFormValidation';
import useNotification from 'custom_hooks/useNotification';

import { IUseTradeshowForm } from './types';

const useCheckoutForm = (
  onFinish : ((validCode: string | undefined) => void) | undefined,
) : IUseTradeshowForm => {
  const { TRADESHOW_CODE, NODE_ENV } = process.env;
  const { validator } = useFormValidation();
  const { addSuccessNotification, addErrorNotification } = useNotification();

  const tradeshowTestCode = 'testCode1';
  const tradeshowCodeEnv = NODE_ENV === 'test' ? tradeshowTestCode : TRADESHOW_CODE || ' ';

  const handleValidation = ({
    tradeshowCode,
  } : ITradeshowProps) : IErrors => ({
    tradeshowCode: validator.validateRequired(tradeshowCode || ''),
  });

  const handleSubmit = (values: ITradeshowProps) => {
    const { tradeshowCode } = values;

    const onSuccess = (response:any) => {
      const { apgiValidCode } = response;

      if (apgiValidCode) {
        if (onFinish) {
          onFinish(tradeshowCode);
        }
        addSuccessNotification('tradeshowSuccessfullCode');
      } else {
        addErrorNotification('tradeshowInvalidCode');
      }
    };

    const onError = (error:any) => {
      addErrorNotification('tradeshowInvalidCode');
    };

    api.charges.validateTradeshowCode({ tradeshowCode }, onSuccess, onError);
  };

  const {
    errors,
    onChangeField,
    onSubmitForm,
    isValid,
    values = { tradeshowCode: null },
  } = useForm<ITradeshowProps>({
    onSubmit: handleSubmit,
    onValidate: handleValidation,
  });

  return {
    errors,
    isValid,
    onChangeField,
    onSubmitForm,
    values,
  };
};

export default useCheckoutForm;
