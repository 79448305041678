/* eslint-disable no-nested-ternary */
import { Redirect, Route as RRoute, RouteProps } from 'react-router-dom';
import propTypes from 'prop-types';
import React from 'react';

import MainLayout from 'components/layouts/MainLayout/MainLayout';
import NoAuthMainLayout from 'components/layouts/NoAuthMainLayout/NoAuthMainLayout';
import URLS from 'app_constants/urls';

const Route = ({
  canNavigate,
  component: Component,
  ignoreLayout,
  isAuthenticated,
  isPrivatePath,
  isRestrictedLayout,
  location,
  noAuthLayout,
  ...rest
}: PersonalsRouteProps): JSX.Element => {
  const corporateAccountPath = {
    pathname: URLS.corporateAccount,
    state: { from: location },
  };

  const loginPath = {
    pathname: URLS.login,
    state: { from: location },
  };

  const stopRestrictedNavigation = () => (
    isRestrictedLayout && canNavigate && isAuthenticated && isPrivatePath
  );

  const verifyNotAuthenticated = () => (!isAuthenticated && isPrivatePath);

  const verifyRestrictedNavigation = () => (
    !isRestrictedLayout && isPrivatePath && !canNavigate && isAuthenticated
  );

  const verifyRestrictedPublicNavigation = () => (
    !isPrivatePath && !canNavigate
  );

  return verifyNotAuthenticated() || stopRestrictedNavigation() ? (
    <Redirect to={loginPath} />
  ) : verifyRestrictedNavigation() ? (
    <Redirect to={corporateAccountPath} />
  ) : (
    <RRoute
      location={location}
      render={(props) => (
        ignoreLayout ? (
          <Component {...props} />
        ) : noAuthLayout ? (
          <NoAuthMainLayout>
            <Component {...props} />
          </NoAuthMainLayout>
        ) : (
          <MainLayout isRestrictedLayout={isRestrictedLayout || verifyRestrictedPublicNavigation()}>
            <Component {...props} />
          </MainLayout>
        )
      )}
      {...rest}
    />
  );
};

Route.propTypes = {
  isAuthenticated: propTypes.bool,
  isPrivatePath: propTypes.bool,
  isRestrictedLayout: propTypes.bool,
  layout: propTypes.func,
};

Route.defaultProps = {
  isAuthenticated: false,
  isPrivatePath: false,
  isRestrictedLayout: false,
  layout: undefined,
};

interface PersonalsRouteProps extends Omit<RouteProps, 'component'> {
  canNavigate?: boolean,
  ignoreLayout?: boolean,
  isAuthenticated?: boolean,
  isPrivatePath?: boolean,
  isRestrictedLayout?: boolean,
  noAuthLayout?: boolean,
  component: React.ElementType
}

export default Route;
