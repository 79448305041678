import { useTranslation } from 'react-i18next';
import propTypes, { instanceOf } from 'prop-types';
import React, { useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import Truncate from 'react-truncate';

import { breakpoints } from 'app_constants/breakpoints.json';
import BeachBall from 'generics/BeachBall/BeachBall';
import Content from 'generics/Content/Content';
import Eyebrow from 'generics/Eyebrow/Eyebrow';
import Profile from 'classes/Profile/Profile.class';

import ToggleVisibilityButton from './components/ToggleVisibilityButton/ToggleVisibilityButton';
import styles from './Overview.scss';

// Epic is returning motto inside overview content
const MOTTO_REGEX = /<p><em>(.*)<\/em><\/p>/;

const Overview = ({ isMyProfile, overview, profile }: IOverviewProps) : JSX.Element => {
  const { t } = useTranslation('profile');
  const mq = window.matchMedia(`(min-width: ${breakpoints.mobile})`);
  const showToggleVisibilityButton = isMyProfile || mq.matches;
  const [isExpanded, setExpanded] = useState(showToggleVisibilityButton);
  const handleToggle = () => setExpanded(!isExpanded);
  const result = overview.match(MOTTO_REGEX);
  const motto = result && result.length > 0 ? result[1] : '';
  const overviewText = overview.replace(MOTTO_REGEX, '');

  return (
    <>
      <div className={styles['profile-overview__motto__stage']}>
        <h2 className={styles['profile-overview__motto__wrapper']}>
          <Eyebrow
            tag="span"
            className={styles['profile-overview__motto__eyebrow']}
          >
            {t('overview.motto')}
          </Eyebrow>
          <span className={styles['profile-overview__motto']}>
            {motto}
          </span>
        </h2>
        <div className={styles['profile-overview__motto__beach-ball']}>
          <BeachBall assessmentScores={profile.assessmentScores} />
        </div>
      </div>

      <div className={styles['profile-overview']}>
        <Truncate
          lines={!isExpanded && 3}
          ellipsis={(
            <>
              &hellip;&nbsp;
              <ToggleVisibilityButton
                onClick={handleToggle}
                label={t('overview.expand')}
              />
            </>
          )}
        >
          {!isExpanded
            ? ReactHtmlParser(overviewText)
            : (
              <>
                <Content content={overviewText} useHtmlParser />
                {
                  !showToggleVisibilityButton && (
                    <ToggleVisibilityButton
                      onClick={handleToggle}
                      label={t('overview.collapse')}
                    />
                  )
                }
              </>
            )}
        </Truncate>
      </div>
    </>
  );
};

Overview.propTypes = {
  isMyProfile: propTypes.bool,
  overview: propTypes.string,
  profile: instanceOf(Profile).isRequired,
};

Overview.defaultProps = {
  isMyProfile: false,
  overview: '',
};

interface IOverviewProps {
  isMyProfile: boolean,
  overview: string,
  profile: any,
}

export default Overview;
