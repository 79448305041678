import { faTimes, faTrash } from '@fortawesome/pro-light-svg-icons';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IContextMenuOptionProps } from 'generics/ContextMenu/components/MenuOption/MenuOption';
import { SessionContext } from 'app_state/session/SessionProvider';
import api from 'api';
import fetchAccount from 'app_state/session/actions/fetchAccount';
import fetchRelationships from 'app_state/session/actions/fetchRelationships';
import Invitation from 'classes/Invitation/Invitation.class';
import useNotification from 'custom_hooks/useNotification';
import usePagination, { IUsePagination } from 'custom_hooks/usePagination';

const INVITES_COLLECTION_NAME = 'invites';

const useInvites = () : IUseInvites => {
  const { state, dispatch } = useContext(SessionContext);
  const { relationships } = state;
  const [isContextMenuVisible, setIsContextMenuVisible] = useState(false);
  const [selectedInvite, setSelectedInvite] = useState<Invitation | null>(null);
  const { t } = useTranslation('home');
  const { addErrorNotification } = useNotification();

  const handleCloseMenu = () => setIsContextMenuVisible(false);

  const sentInvites = usePagination({
    onRequest: api.invites.getSentInvites,
    collectionName: INVITES_COLLECTION_NAME,
    initialCollection: relationships.sentInvites,
  });

  const handleShowDeleteInvite = (invite: Invitation) => {
    setSelectedInvite(invite);
    setIsContextMenuVisible(true);
  };

  const handleDeleteInvite = () => {
    const onSuccess = () => {
      handleCloseMenu();
      fetchRelationships(dispatch);
      fetchAccount(dispatch);
    };

    const onError = () => {
      handleCloseMenu();
      addErrorNotification('deleteInvitationError');
    };

    api.invites.deleteInvitation(selectedInvite?.id, onSuccess, onError);
  };

  const contextMenuOptions = [
    {
      icon: faTrash,
      label: t('deleteInvite'),
      onClick: () => handleDeleteInvite(),
    },
    {
      icon: faTimes,
      label: t('cancel'),
      onClick: handleCloseMenu,
    },
  ];

  return {
    selectedInvite,
    sentInvites,
    onDeleteInvite: handleShowDeleteInvite,
    contextMenu: {
      isVisible: isContextMenuVisible,
      onClose: handleCloseMenu,
      options: contextMenuOptions,
    },
  };
};
export interface IUseInvites {
  contextMenu: {
    isVisible: boolean,
    onClose: () => void,
    options: IContextMenuOptionProps[],
  },
  onDeleteInvite: (invite: Invitation) => void,
  selectedInvite: Invitation | null,
  sentInvites: IUsePagination,
}

export default useInvites;
