import { Dispatch } from 'react';

import { ISessionAction } from 'app_state/types';
import history from 'utils/history';
import sessionActionTypes from 'app_state/session/actionTypes';
import URLS from 'app_constants/urls';

const saveLoginEmail = (payload: string) : ISessionAction => ({
  payload,
  type: sessionActionTypes.SAVE_EMAIL,
});

export default (
  dispatch: Dispatch<ISessionAction>,
  email: string,
) : void => {
  dispatch(saveLoginEmail(email));
  history.push(URLS.signUpInvitation);
};
