import React, { ReactNode } from 'react';
import classNames from 'classnames';
import propTypes from 'prop-types';
import { CHILDREN } from 'app_constants/propTypes';
import styles from './PageSection.scss';

const defaultTag = 'section';

const PageSection = ({
  children,
  className,
  tag: Tag = defaultTag,
}: IPageSectionProps) : JSX.Element => (
  <Tag className={classNames(className, [styles['page-section__wrapper']])}>
    <div className={styles['page-section']}>
      {children}
    </div>
  </Tag>
);

PageSection.propTypes = {
  children: CHILDREN,
  className: propTypes.string,
  tag: propTypes.string,
};

PageSection.defaultProps = {
  children: null,
  className: null,
  tag: defaultTag,
};

interface IPageSectionProps {
  children: ReactNode,
  className?: string,
  tag?: keyof JSX.IntrinsicElements,
}

export default PageSection;
