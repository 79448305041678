import React, { ReactNode, SVGProps } from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';

import { CHILDREN } from 'app_constants/propTypes';

import styles from './svg.scss';

const Svg = ({ children, className, viewBox, ...rest }: ISvgProps) : JSX.Element => {
  const [width, height] = viewBox.split(' ');

  return (
    <span
      className={classNames(className, [styles.svg])}
      role="img"
    >
      <canvas
        className={styles.svg__canvas}
        {...{ height, width }}
      />
      <svg
        className={styles.svg__content}
        version="1.1"
        viewBox={viewBox}
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
      >
        {children}
      </svg>
    </span>
  );
};

Svg.propTypes = {
  children: CHILDREN,
  className: propTypes.string,
  viewBox: propTypes.string,
};

Svg.defaultProps = {
  children: null,
  className: null,
  viewBox: '0 0 0 0',
};

interface ISvgProps extends SVGProps<SVGSVGElement> {
  children?: ReactNode,
  className?: string,
  viewBox: string,
}

export default Svg;
