enum actionTypes {
  FETCH_ASSESSMENT_FAILURE = 'ASSESSMENT/FETCH_ASSESSMENT_FAILURE',
  FETCH_ASSESSMENT_REQUEST = 'ASSESSMENT/FETCH_ASSESSMENT_REQUEST',
  FETCH_ASSESSMENT_SUCCESS = 'ASSESSMENT/FETCH_ASSESSMENT_SUCCESS',
  FETCH_UPDATE_ASSESSMENT_FAILURE = 'ASSESSMENT/FETCH_UPDATE_ASSESSMENT_FAILURE',
  FETCH_UPDATE_ASSESSMENT_REQUEST = 'ASSESSMENT/FETCH_UPDATE_ASSESSMENT_REQUEST',
  FETCH_UPDATE_ASSESSMENT_SUCCESS = 'ASSESSMENT/FETCH_UPDATE_ASSESSMENT_SUCCESS',
  SET_TIEBREAK = 'ASSESSMENT/SET_TIEBREAK',
  ADD_ANSWER = 'ASSESSMENT/ADD_ANSWER',
}

export default actionTypes;
