import { size } from 'lodash';
import { sprintf } from 'sprintf-js';
import moment from 'moment';

import { APP_DATE_FORMAT } from 'app_constants/dateFormats';

import {
  sizeValidationType,
  tFunctionType,
  validatePasswordType,
  validateTextFnType,
  validateTextLengthFn,
  validateZipCode,
  validationFnType,
} from './types';

const EMAIL_MAX_LEGTH = 120;
const TEXT_MAX_LENGTH = 252;
const TEXT_MIN_LENGTH = 2;

const validateMaxLength : sizeValidationType = (
  text, maxLength = TEXT_MAX_LENGTH,
) => size(text) > maxLength;

const validateMinLength : sizeValidationType = (
  text, minLength = TEXT_MIN_LENGTH,
) => size(text) < minLength;

const validateDigit : validationFnType = (text) => !/(?=.*\d)/.test(text);
const validateEmailFormat : validationFnType = (email) => !/^[A-Za-zÀ-ÖØ-öø-ÿ0-9.'‘’`´_%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i.test(email);
const validateEmailLength : validationFnType = (email) => size(email) > EMAIL_MAX_LEGTH;
const validateLowerCase : validationFnType = (text) => !/(?=.*[a-z])/.test(text);

const validatePhoneFormat : validationFnType = (
  text,
) => !/^(?:\+?\d{1,3}\s*[- .])?\(?(?:\d{1,4})?\)?[- .]?\d{1,4}[- ]?\d{1,4}\s*[a-z]*\d*$/.test(text);

const validateSpecialCharacters : validationFnType = (text) => !/[~!@#$%^&*()_+.']/.test(text);
const validateString : validationFnType = (text) => !/^[-a-zA-ZÀ-ÖØ-öø-ÿç¨'‘’`´ ]*$/.test(text);
const validateUpperCase : validationFnType = (text) => !/(?=.*[A-Z])/.test(text);

const validateZipCodeFormat : validationFnType = (zipCode) => !/^\d{5}(-\d{4})?$/.test(zipCode);

/**
 *  Form Validation Class
 */
export default class FormValidator {
  t: tFunctionType

  constructor(t: tFunctionType) {
    this.t = t;
  }

  /**
   *  Validates password field value
   *  @param {string} password
   *  @param {string} oldPassword
   *  @returns {string} error
   */
  validatePassword : validatePasswordType = (password, oldPassword = '') => {
    let error = null;

    if (!password) {
      error = this.t('required');
    } else if (password.length < 6) {
      error = this.t('passwordLength');
    } else if (validateLowerCase(password)) {
      error = this.t('passwordLowercase');
    } else if (validateUpperCase(password)) {
      error = this.t('passwordUppercase');
    } else if (validateDigit(password)) {
      error = this.t('passwordDigit');
    } else if (validateSpecialCharacters(password)) {
      error = this.t('passwordSpecialChars');
    } else if (password === oldPassword) {
      error = this.t('samePassword');
    }

    return error;
  };

  /**
   *  Validates password confirmation field
   *  @param {string} password
   *  @param {string} passwordConfirmation
   *  @returns {string} error
   */
  validatePasswordConfirmation : validatePasswordType = (
    password, passwordConfirmation,
  ) => {
    let error = null;

    if (!passwordConfirmation) {
      error = this.t('required');
    } else if (password !== passwordConfirmation) {
      error = this.t('passwordConfirmNoMatch');
    }

    return error;
  };

  /**
   *  Validates name field value
   *  @param {string} name
   *  @returns {string} error
   */
  validateName : validateTextFnType = (name) => {
    let error = null;

    if (!name) {
      error = this.t('required');
    } else if (validateMaxLength(name)) {
      error = this.t('maxLength');
    } else if (validateString(name)) {
      error = this.t('noSpecialCharsOrNumbers');
    }

    return error;
  };

  /**
   *  Validates date field value
   *  @param {string} date
   *  @returns {string} error
   */
  validateDate : validateTextFnType = (strDate) => {
    let error = null;
    const date = moment(strDate, APP_DATE_FORMAT);

    if (strDate) {
      error = date.isValid() ? null : this.t('invalidDate');
    }

    return error;
  };

  validateIsDate18orMoreYearsOld : validateTextFnType = (birthday) => {
    let error = null;

    if (birthday) {
      const day = new Date(birthday).getDate();
      const month = new Date(birthday).getMonth();
      const year = new Date(birthday).getFullYear();

      if (new Date(year + 18, month, day) > new Date()) {
        error = this.t('restrictedAge');
      }
    }

    return error;
  };

  /**
   *  Validates email field value
   *  @param {string} email
   *  @returns {string} error
   */
  validateEmail : validateTextFnType = (email) => {
    let error = null;
    if (validateEmailFormat(email)) {
      error = this.t('invalidEmail');
    } else if (validateEmailLength(email)) {
      error = this.t('invalidEmailLength');
    }

    return error;
  };

  /**
   *  Validates phone field value
   *  @param {string} phone
   *  @returns {string} error
   */
  validatePhone : validateTextFnType = (phone) => {
    let error = null;

    if (validateMaxLength(phone)) {
      error = this.t('maxLength');
    } else if (phone && validatePhoneFormat(phone)) {
      error = this.t('invalidPhone');
    }

    return error;
  };

  /**
   *  Validates that a field is required
   *  @param {string} text
   *  @returns {string} error
   */
  validateRequired : validateTextFnType = (value) => (!value ? this.t('required') : null);

  /**
   *  Validates text
   *  @param {string} text
   *  @param {number} minLength
   *  @param {number} maxLength
   *  @returns {string} error
   */
  validateTextLength : validateTextLengthFn = (
    text, minLength = TEXT_MIN_LENGTH, maxLength = TEXT_MAX_LENGTH,
  ) => {
    let error = null;

    if (text && validateMinLength(text, minLength)) {
      error = sprintf(this.t('customMinLength'), minLength);
    } else if (validateMaxLength(text, maxLength)) {
      error = sprintf(this.t('customMaxLength'), maxLength);
    }

    return error;
  };

  validateZipCode : validateZipCode = (
    zipCode,
  ) => {
    let error = null;

    if (zipCode && validateZipCodeFormat(zipCode)) {
      error = sprintf(this.t('zipCode'), zipCode);
    }
    return error;
  }
}
