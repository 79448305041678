import Question, { IQuestion } from './Question.class';

export default class Assessment {
  assessmentScores: number[];

  createdAt: string;

  finished: boolean;

  id: number;

  questions: Question[];

  tiebreak: boolean;

  constructor({
    id, finished = false, tiebreak = false, createdAt, assessmentScores = [], questions = [],
  } : IAssessment) {
    this.assessmentScores = assessmentScores;
    this.createdAt = createdAt;
    this.finished = finished;
    this.id = id;
    this.questions = questions;
    this.tiebreak = tiebreak;
    this.questions = questions.map((question) => new Question(question));
  }
}

interface IAssessment {
  assessmentScores?: number[],
  createdAt: string,
  finished?: boolean,
  id: number,
  questions: IQuestion[],
  tiebreak?: boolean,
}
