import React, { ReactNode } from 'react';
import classNames from 'classnames';
import propTypes from 'prop-types';
import shortid from 'shortid';

import { CHILDREN } from 'app_constants/propTypes';

import styles from './Menu.scss';

const Menu = ({ className, items }: IMenuProps) : JSX.Element => (
  <ul className={classNames(className, styles.menu)}>
    {items.map((item) => (
      <li key={shortid()}>
        {item}
      </li>
    ))}
  </ul>
);

Menu.propTypes = {
  className: propTypes.string,
  items: CHILDREN.isRequired,
};

Menu.defaultProps = {
  className: null,
};

interface IMenuProps {
  className?: string,
  items: Array<ReactNode>,
}

export default Menu;
