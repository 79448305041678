import { Dispatch } from 'react';

import { IList, ISessionAction } from 'app_state/types';
import api from 'api';
import ApiError from 'classes/errors/ApiError.class';
import sessionActionTypes from 'app_state/session/actionTypes';

const fetchFriendsFailure = (error: ApiError) : ISessionAction => ({
  payload: error,
  type: sessionActionTypes.FETCH_FRIENDS_FAILURE,
});

const fetchFriendsRequest = () : ISessionAction => ({
  payload: null,
  type: sessionActionTypes.FETCH_FRIENDS_REQUEST,
});

const fetchFriendsSuccess = (payload: IList) : ISessionAction => ({
  payload,
  type: sessionActionTypes.FETCH_FRIENDS_SUCCESS,
});

/**
 * @description: Gets the first page of Friends (My Community)
 */
export default (
  dispatch: Dispatch<ISessionAction>,
) : void => {
  dispatch(fetchFriendsRequest());

  const onError = (error: ApiError) => {
    dispatch(fetchFriendsFailure(error));
  };

  const onSuccess = (response: any) => {
    const { meta, profiles: collection } = response;
    dispatch(fetchFriendsSuccess({ meta, collection }));
  };

  api.profiles.getFriends({ page: 1 }, onSuccess, onError);
};
