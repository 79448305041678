import { IAction } from 'app_state/types';
import initialState from 'app_state/session/initialState.json';
import sessionActionTypes from 'app_state/session/actionTypes';

const loginEmail = (
  state: string | null = initialState.loginEmail,
  action: IAction,
): string | null => {
  switch (action.type) {
    case sessionActionTypes.SAVE_EMAIL:
      return action.payload;

    default:
      return state;
  }
};

export default loginEmail;
