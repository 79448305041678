import { isNil } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import React from 'react';

import Button from 'generics/Button/Button';
import Form from 'generics/Form/Form';
import Input from 'generics/Input/Input';
import PageSection from 'generics/PageSection/PageSection';
import PageWrapper from 'generics/PageWrapper/PageWrapper';
import URLS from 'app_constants/urls';

import styles from './SignUpInvitation.scss';
import useSignUpInvitation from './useSignUpInvitation';

const SignUpInvitation = () : JSX.Element => {
  const { t } = useTranslation('signUpInvitation');
  const history = useHistory();

  const {
    errors,
    isAuthenticated,
    onChangeField,
    onSubmitForm,
    isValid,
    values,
  } = useSignUpInvitation();

  if (isAuthenticated) {
    history.push(URLS.home);
  }

  return (
    <PageWrapper>
      <div
        className={classNames(
          [styles.layout],
          [styles['no-auth']],
        )}
      >
        <PageSection>

          <div
            className={classNames(
              [styles.intro],
              [styles['no-auth']],
            )}
          >
            <h1>{t('title')}</h1>
            <p>{t('description')}</p>
          </div>

          <Form onSubmit={onSubmitForm}>

            <Input
              error={isNil(values.firstName) ? null : errors.firstName}
              id="firstName"
              isBlockLabel
              label={t('form.firstName')}
              name="firstName"
              onChange={(e) => onChangeField('firstName', e.currentTarget.value)}
              type="firstName"
              value={values.firstName || ''}
            />

            <Input
              error={isNil(values.lastName) ? null : errors.lastName}
              id="lastName"
              isBlockLabel
              label={t('form.lastName')}
              name="lastName"
              onChange={(e) => onChangeField('lastName', e.currentTarget.value)}
              type="lastName"
              value={values.lastName || ''}
            />

            <Input
              error={isNil(values.email) ? null : errors.email}
              id="email"
              isBlockLabel
              label={t('form.email')}
              name="email"
              onChange={(e) => onChangeField('email', e.currentTarget.value)}
              value={values.email || ''}
            />

            <Button
              className={styles.submit}
              disabled={!isValid}
              type="submit"
              isBlockAtTablet
              isRounded
            >
              {t('form.btn')}
            </Button>
          </Form>

          <p className={styles['sign-up-cta']}>
            <Button
              isLink
              onClick={() => history.push(URLS.login)}
            >
              {t('sign-up-cta')}
            </Button>
          </p>

        </PageSection>
      </div>
    </PageWrapper>
  );
};

export default SignUpInvitation;
