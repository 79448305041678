import sessionActionTypes from 'app_state/session/actionTypes';
import initialState from 'app_state/session/initialState.json';
import { IAction } from 'app_state/types';

const isAuthenticated = (
  state: boolean = initialState.isAuthenticated,
  action: IAction,
): boolean => {
  switch (action.type) {
    case sessionActionTypes.FETCH_LOGIN_SUCCESS:
    case sessionActionTypes.FETCH_SIGN_UP_SUCCESS:
      return true;

    case sessionActionTypes.RESET_STATE:
      return initialState.isAuthenticated;

    default:
      return state;
  }
};

export default isAuthenticated;
