/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { AssessmentContext } from 'app_state/assessment/AssessmentProvider';
import { SessionContext } from 'app_state/session/SessionProvider';
import Assessment from 'classes/assessment/Assessment.class';
import fetchAssessment from 'app_state/assessment/actions/fetchAssessment';
import fetchContent from 'app_state/session/actions/fetchContent';
import INVITATION_TYPES from 'app_constants/invitationTypes';
import Profile from 'classes/Profile/Profile.class';
import updateUser from 'app_state/session/actions/updateUser';
import URLS from 'app_constants/urls';

const useCorporateAccount = () : IUseCorporateAccount => {
  const history = useHistory();
  const { dispatch: sessionDispatch } = useContext(SessionContext);
  const { state } = useContext(SessionContext);
  const { currentUser } = state;
  const canTakeAssessment = currentUser?.user.invitationType === INVITATION_TYPES.personal
    || currentUser?.user.invitationType === INVITATION_TYPES.personalSignup;

  const {
    dispatch: assessmentDispatch,
  } = useContext(AssessmentContext);

  const checkAssessmentStatus = () => {
    const onSuccess = (assessment: Assessment) => {
      const { assessmentScores, finished } = assessment;
      if (finished) {
        const profile = new Profile({
          ...currentUser,
          user: {
            ...currentUser?.user,
            assessmentScores,
            hasOngoingAssessment: false,
          },
        });

        sessionDispatch(updateUser(profile));
        fetchContent(sessionDispatch);
        history.push(URLS.home);
      }
    };

    const onError = (error:any) => {
      console.log(error);
    };

    fetchAssessment(assessmentDispatch, true, onError, onSuccess);
  };

  useEffect(() => {
    checkAssessmentStatus();
  }, []);

  return {
    canTakeAssessment,
    checkAssessmentStatus,
    userFirstName: currentUser?.firstName || '',
    onLinkProfile: () => history.push(URLS.linkCorporateProfile),
    onContinue: () => history.push(URLS.welcome),
  };
};

export default useCorporateAccount;

export interface IUseCorporateAccount {
  canTakeAssessment: boolean,
  checkAssessmentStatus: () => void,
  userFirstName: string,
  onLinkProfile: () => void,
  onContinue: () => void,
}
