/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';

import api from 'api';
import ApiError from 'classes/errors/ApiError.class';
import useNotification from 'custom_hooks/useNotification';

const useShareReport = () : IUseShareReport => {
  const { addSuccessNotification, addErrorNotification } = useNotification();
  const [externalShareReportRequests, setExternalShareReportRequests] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);

  const fetchShareReportRequests = () => {
    const onSuccess = ({ externalReports }:any) => {
      if (externalReports?.length) {
        setExternalShareReportRequests(externalReports.map(({ account, code, id, sender }:any) => ({
          code,
          id,
          organizationName: account.name,
          organizationOwner: `${sender.firstName} ${sender.lastName}`,
        })));
      }
      setIsLoading(false);
    };

    const onError = (error:any) => {
      console.log(error);
      setIsLoading(false);
    };

    api.tokens.getShareReportRequests(null, onSuccess, onError);
  };

  useEffect(() => {
    fetchShareReportRequests();
  }, []);

  const handleShareRequest = (reportCode:string) => {
    setIsLoading(true);
    const { id } = externalShareReportRequests.find(
      ({ code }:any) => code === reportCode,
    ) || {};

    const onSuccess = () => {
      const newReports = [...externalShareReportRequests];
      newReports.splice(
        externalShareReportRequests?.findIndex(({ id: requestId }:any) => id === requestId),
        1,
      );
      setExternalShareReportRequests(newReports);
      setIsLoading(false);
      const label = 'shareReportSuccess';
      addSuccessNotification(label);
    };

    const onError = (error: ApiError) => {
      console.log(error);
      addErrorNotification(error.message);
      setIsLoading(false);
    };

    api.tokens.shareReport({ id }, onSuccess, onError);
  };

  return {
    externalShareReportRequests,
    fetchShareReportRequests,
    handleShareRequest,
    isLoading,
  };
};

interface IUseShareReport {
  externalShareReportRequests: any,
  fetchShareReportRequests: () => void,
  handleShareRequest: (code:string) => void,
  isLoading: boolean,
}

export default useShareReport;
