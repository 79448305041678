import React, { MouseEvent } from 'react';
import Button from 'generics/Button/Button';
import styles from './ToggleVisibilityButton.scss';

const ToggleVisibilityButton = ({ label, onClick }: IToggleVisibilityButtonProps) : JSX.Element => (
  <Button
    isLink
    type="button"
    className={styles['toggle-visibility-button']}
    onClick={onClick}
  >
    {label}
  </Button>
);

interface IToggleVisibilityButtonProps {
  label: string,
  onClick: (event: MouseEvent<HTMLButtonElement>) => void,
}

export default ToggleVisibilityButton;
