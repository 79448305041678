import {
  IList,
  IAction,
} from 'app_state/types';
import initialState from 'app_state/session/initialState.json';
import sessionActionTypes from 'app_state/session/actionTypes';

const receivedFriendRequests = (
  state: IList = initialState.relationships.receivedFriendRequests,
  action: IAction,
): IList => {
  switch (action.type) {
    case sessionActionTypes.FETCH_RECEIVED_FRIEND_REQUESTS_REQUEST:
      return {
        ...initialState.relationships.receivedFriendRequests,
        isLoading: true,
      };

    case sessionActionTypes.FETCH_RECEIVED_FRIEND_REQUESTS_SUCCESS:
      return action.payload;

    case sessionActionTypes.RESET_STATE:
    case sessionActionTypes.FETCH_RECEIVED_FRIEND_REQUESTS_FAILURE:
      return initialState.relationships.receivedFriendRequests;

    default:
      return state;
  }
};

export default receivedFriendRequests;
