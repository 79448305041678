import { faStar } from '@fortawesome/pro-light-svg-icons';
import { faStar as faStarSolid } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import propTypes from 'prop-types';
import React, { useState } from 'react';
import shortid from 'shortid';

import { keyType, iconType } from 'app_constants/adviceDesigns';
import { ICON } from 'app_constants/propTypes';
import Content from 'components/generics/Content/Content';
import Form from 'components/generics/Form/Form';

import AdviceIcon from '../AdviceIcon/AdviceIcon';
import styles from './AdviceCard.scss';

const AdviceCard = ({
  className,
  adviceCategory,
  content,
  icon,
  id,
  isSaved,
  onSave,
  onUnsave,
  title,
}: IAdviceCardProps) : JSX.Element => {
  const [isDisabled, setIsDisabled] = useState(false);
  const idString = `advice-card-${id || shortid()}`;

  const toggleStar = () => {
    const onFinish = () => setIsDisabled(false);
    setIsDisabled(true);

    if (isSaved && onUnsave) {
      onUnsave(onFinish);
    } else if (onSave) {
      onSave(onFinish);
    }
  };

  return (
    <div className={classNames(styles['advice-card'], className)}>

      <Form
        className={styles['advice-card__star__wrapper']}
        onSubmit={toggleStar}
      >
        <label
          className={classNames(
            styles['advice-card__star'],
            { [styles['is-starred']]: isSaved },
          )}
          htmlFor={idString}
        >
          <input
            checked={isSaved}
            className={styles['advice-card__star__toggle']}
            disabled={isDisabled}
            id={idString}
            onChange={toggleStar}
            type="checkbox"
          />
          <FontAwesomeIcon icon={isSaved ? faStarSolid : faStar} />
        </label>
      </Form>

      <div className={styles['advice-card__title__wrapper']}>
        <h2 className={styles['advice-card__title']}>
          {title}
        </h2>
        <AdviceIcon
          className={classNames(styles['advice-card__icon'])}
          adviceCategory={adviceCategory}
          icon={icon}
        />
      </div>

      <Content
        className={styles['advice-card__content']}
        content={content}
        useHtmlParser
      />

    </div>
  );
};

AdviceCard.propTypes = {
  adviceCategory: propTypes.string.isRequired,
  className: propTypes.string,
  content: propTypes.string.isRequired,
  icon: ICON.isRequired,
  id: propTypes.number,
  isSaved: propTypes.bool,
  onSave: propTypes.func,
  onUnsave: propTypes.func,
  title: propTypes.string.isRequired,
};

AdviceCard.defaultProps = {
  className: null,
  id: null,
  isSaved: false,
  onSave: null,
  onUnsave: null,
};

interface IAdviceCardProps {
  adviceCategory: keyType,
  className: string,
  content: string,
  icon: iconType,
  id?: number,
  isSaved?: boolean,
  onSave?: (onFinish?: () => void) => void,
  onUnsave?: (onFinish?: () => void) => void,
  title: string,
}

export default AdviceCard;
