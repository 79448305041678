const PRONOUN_PREFERENCES = [
  'F',
  'M',
  'N2',
  'N1',
  'N3',
  'N4',
];

export default PRONOUN_PREFERENCES;
