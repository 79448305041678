import { Dispatch } from 'react';

import { ISessionAction } from 'app_state/types';
import api from 'api';
import history from 'utils/history';
import resetState from 'app_state/session/actions/resetState';
import URLS from 'app_constants/urls';

export default (
  dispatch: Dispatch<ISessionAction>,
  onSuccess: () => void,
  onError: () => void,
) : void => {
  // clean the session
  const handleSuccess = () => {
    dispatch(resetState());
    history.push(URLS.login);
    onSuccess();
  };

  api.profiles.deleteAccount(null, handleSuccess, onError);
};
