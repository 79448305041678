import React from 'react';
import propTypes from 'prop-types';
import slugify from 'slugify';

import MenuOption, { IContextMenuOptionProps } from '../MenuOption/MenuOption';

import styles from './MenuOptions.scss';

const MenuOptions = ({ options, color }: IContextMenuProps) : JSX.Element => (
  <ul className={styles['context-menu__options']}>
    {options.map((option) => (
      <li key={slugify(option.label)}>
        <MenuOption
          color={color}
          {...option}
        />
      </li>
    ))}
  </ul>
);

MenuOptions.propTypes = {
  color: propTypes.string,
  options: propTypes.arrayOf(propTypes.shape(MenuOption.propTypes)).isRequired,
};

MenuOptions.defaultProps = {
  color: 'primary',
};

interface IContextMenuProps {
  color?: 'primary' | 'secondary',
  options: Array<IContextMenuOptionProps>,
}

export default MenuOptions;
