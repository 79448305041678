import { faUserPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { sprintf } from 'sprintf-js';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import React from 'react';

import Button from 'generics/Button/Button';
import Card from 'generics/Card/Card';
import CardsList from 'generics/CardsList/CardsList';
import Invitation from 'classes/Invitation/Invitation.class';
import MenuMessage from 'generics/ContextMenu/components/MenuMessage/MenuMessage';
import MenuOptions from 'generics/ContextMenu/components/MenuOptions/MenuOptions';
import Modal from 'generics/Modal/Modal';
import Pagination from 'generics/Pagination/Pagination';

import useInvites from './useInvites';
import styles from '../../Home.scss';

const SentInvites = () : JSX.Element => {
  const { t } = useTranslation('home');
  const {
    contextMenu,
    sentInvites,
    selectedInvite,
    onDeleteInvite,
  } = useInvites();

  const cards = sentInvites.results.map((invite: any) => {
    const invitation = new Invitation({ ...invite });
    const { email, createdAt } = invitation;
    const originalDate = moment.utc(createdAt);
    const now = moment.utc(moment.now());

    return (
      <Card
        title={email || ''}
        subtitle={sprintf(t('invitedTimeAgo'), now.to(originalDate))}
        media={<FontAwesomeIcon icon={faUserPlus} />}
        showBeachBall
      >
        <Button
          className={styles['relationship-cards__button']}
          isSecondary
          isSmall
          onClick={() => onDeleteInvite(invitation)}
        >
          {t('deleteInvite')}
        </Button>
      </Card>
    );
  });

  return (
    <>
      <CardsList
        className={styles['relationship-cards']}
        cards={cards}
      />
      <Pagination
        isVisible={sentInvites.areMorePages}
        onClick={sentInvites.onGetMoreResults}
      />
      <Modal
        isSmall
        isVisible={contextMenu.isVisible}
        onClose={contextMenu.onClose}
      >
        <MenuMessage
          color="secondary"
          message={(
            <p className={styles['relationship-cards__context-menu']}>
              <strong>{t('deleteInviteMenu.title')}</strong>
              <br />
              {sprintf(t('deleteInviteMenu.message'), selectedInvite?.email)}
            </p>
          )}
        />
        <MenuOptions color="secondary" options={contextMenu.options} />
      </Modal>
    </>
  );
};

export default SentInvites;
