import { Dispatch } from 'react';

import { IEditProfileProps } from 'api/controllers/types';
import { ISessionAction } from 'app_state/types';
import api from 'api';
import ApiError from 'classes/errors/ApiError.class';
import Profile from 'classes/Profile/Profile.class';
import sessionActionTypes from 'app_state/session/actionTypes';

const editProfileFailure = (error: ApiError) : ISessionAction => ({
  payload: error,
  type: sessionActionTypes.FETCH_EDIT_PROFILE_FAILURE,
});

const editProfileRequest = () : ISessionAction => ({
  payload: null,
  type: sessionActionTypes.FETCH_EDIT_PROFILE_REQUEST,
});

const editProfileSuccess = (payload: Profile) : ISessionAction => ({
  payload,
  type: sessionActionTypes.FETCH_EDIT_PROFILE_SUCCESS,
});

export default (
  dispatch: Dispatch<ISessionAction>,
  values: IEditProfileProps,
  successCallback: () => void,
  errorCallback: (error: ApiError) => void,
) : void => {
  dispatch(editProfileRequest());

  const onSuccess = ({ profile: updatedProfile } : any) => {
    const profile = api.sessionState?.currentUser;

    dispatch(editProfileSuccess(new Profile({
      ...profile,
      ...updatedProfile,
    })));

    successCallback();
  };

  const onError = (error: ApiError) => {
    dispatch(editProfileFailure(error));
    errorCallback(error);
  };

  api.profiles.editProfile(values, onSuccess, onError);
};
