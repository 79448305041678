import { IAction } from 'app_state/types';
import Question from 'classes/assessment/Question.class';

import actionTypes from '../actionTypes';
import initialState from '../initialState.json';

const remainingQuestions = (
  state: Question[] = initialState.remainingQuestions,
  action: IAction,
) : Question[] => {
  switch (action.type) {
    case actionTypes.ADD_ANSWER:
      // remove first element
      return state.slice(1);

    case actionTypes.SET_TIEBREAK:
    case actionTypes.FETCH_ASSESSMENT_SUCCESS:
      return action.payload;

    case actionTypes.FETCH_ASSESSMENT_REQUEST:
    case actionTypes.FETCH_UPDATE_ASSESSMENT_SUCCESS:
      return initialState.remainingQuestions;

    default:
      return state;
  }
};

export default remainingQuestions;
