import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { sprintf } from 'sprintf-js';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import React, { useContext, useEffect, useState } from 'react';

import { NotificationsContext } from 'app_state/notifications/NotificationsProvider';
import { SessionContext } from 'app_state/session/SessionProvider';
import { clearNotification } from 'app_state/notifications/actions';

import styles from './NotificationBar.scss';

const NotificationBar = () : JSX.Element | null => {
  const { state, dispatch } = useContext(NotificationsContext);
  const { currentNotification } = state;
  const { state: sessionState } = useContext(SessionContext);
  const { isAuthenticated } = sessionState;
  const { t } = useTranslation(['notifications', 'generics']);
  const [isVisible, setVisibility] = useState(false);

  const handleClose = () => {
    if (currentNotification?.timeout) {
      clearTimeout(currentNotification?.timeout);
    }
    dispatch(clearNotification());
  };

  useEffect(() => {
    setVisibility(!!currentNotification);
  }, [currentNotification]);

  return currentNotification ? (
    <aside
      className={classNames(
        styles['notification-bar__wrapper'],
        { [styles['is-visible']]: isVisible },
        { [styles['no-auth']]: !isAuthenticated },
      )}
    >
      <div
        className={classNames(
          styles['notification-bar'],
          styles[currentNotification?.type],
        )}
      >

        <p>
          {sprintf(t(`notifications:${currentNotification?.label}`), ...currentNotification?.params)}
        </p>

        <button
          className={styles['notification-bar__close--button']}
          onClick={handleClose}
          title={t('generics:close')}
          type="button"
        >
          <FontAwesomeIcon icon={faTimes} size="lg" />
        </button>

      </div>
    </aside>
  ) : null;
};

export default NotificationBar;
