/* eslint-disable react-hooks/exhaustive-deps */
import { useHistory, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { DELETE_ACCOUNT_CURRENT_PASSWORD } from 'app_constants/apiErrorCodes';
import api from 'api';
import ApiError from 'classes/errors/ApiError.class';
import Profile from 'classes/Profile/Profile.class';
import URLS from 'app_constants/urls';
import useForm, { IErrors, IUseForm } from 'custom_hooks/useForm';
import useFormValidation from 'custom_hooks/useFormValidation';
import useNotification from 'custom_hooks/useNotification';

const useResetPassword = () : IUseResetPassword => {
  const [profile, setProfile] = useState<Profile | null>(null);
  const { token } = useParams<paramsType>();
  const { validator } = useFormValidation();
  const history = useHistory();
  const { addErrorNotification, addSuccessNotification } = useNotification();

  useEffect(() => {
    const onError = () => {
      history.push(URLS.login);
    };

    const onSuccess = ({ profile: profileResult }: any) => {
      if (profileResult) {
        setProfile(new Profile(profileResult));
      } else {
        onError();
      }
    };

    api.users.getForgotPasswordInfo(token, onSuccess, onError);
  }, []);

  const handleValidate = ({ password, passwordConfirmation }: IResetPassword) : IErrors => ({
    password: validator.validateRequired(password ?? '')
      || validator.validatePassword(password ?? ''),
    passwordConfirmation: validator.validateRequired(passwordConfirmation ?? '')
      || validator.validatePasswordConfirmation(passwordConfirmation ?? '', password ?? ''),
  });

  const handleSubmit = ({ password, passwordConfirmation }: IResetPassword) => {
    const onSuccess = () => {
      addSuccessNotification('passwordResetSuccess');
      history.push(URLS.login);
    };

    const onError = (error: ApiError) => {
      const label = error.errorCode === DELETE_ACCOUNT_CURRENT_PASSWORD ? 'passwordResetSamePasswordError' : 'passwordResetError';
      addErrorNotification(label);
    };

    api.users.resetPassword({ token, password, passwordConfirmation }, onSuccess, onError);
  };

  const form = useForm<IResetPassword>({ onSubmit: handleSubmit, onValidate: handleValidate });

  return {
    form,
    profile,
  };
};

interface IResetPassword {
  password: string,
  passwordConfirmation: string,
}

export interface IUseResetPassword {
  form: IUseForm<IResetPassword>,
  profile: Profile | null,
}

type paramsType = {
  token: string,
}

export default useResetPassword;
