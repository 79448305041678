import React, { ReactNode, HTMLAttributes } from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';
import { CHILDREN } from 'app_constants/propTypes';
import styles from './Eyebrow.scss';

const defaultTag = 'h2';

const Eyebrow = ({
  children,
  className,
  tag: Tag = defaultTag,
}: IEyebrowProps) : JSX.Element => (
  <Tag className={classNames(className, [styles.eyebrow])}>
    {children}
  </Tag>
);

Eyebrow.propTypes = {
  children: CHILDREN,
  className: propTypes.string,
  tag: propTypes.string,
};

Eyebrow.defaultProps = {
  children: null,
  className: null,
  tag: defaultTag,
};

interface IEyebrowProps extends HTMLAttributes<HTMLOrSVGElement> {
  children: ReactNode,
  className?: string,
  tag?: keyof JSX.IntrinsicElements,
}

export default Eyebrow;
