import propTypes from 'prop-types';

export const ICON = propTypes.oneOfType([
  propTypes.object, propTypes.array, propTypes.string,
]);

export const NAME = propTypes.string;

export const CHILDREN = propTypes.oneOfType([
  propTypes.arrayOf(propTypes.node),
  propTypes.func,
  propTypes.node,
]);
