import AppError, { AppErrorProps } from './AppError.class';

interface ApiErrorProps extends AppErrorProps {
  errorCode: number,
}

/**
 * @class ApiError
 * @description Api Error Class
 * @extends Error
 * */
export default class ApiError extends AppError {
  errorCode: number;

  constructor({ errorCode, label, message } : ApiErrorProps) {
    super({ label, message });
    this.errorCode = errorCode;
  }
}
