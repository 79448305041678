import { isNil } from 'lodash';
import { useTranslation } from 'react-i18next';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';

import Button from 'generics/Button/Button';
import Form from 'generics/Form/Form';
import Input from 'generics/Input/Input';
import MenuMessage from 'generics/ContextMenu/components/MenuMessage/MenuMessage';
import MenuOptions from 'generics/ContextMenu/components/MenuOptions/MenuOptions';
import Modal from 'generics/Modal/Modal';
import PageSection from 'generics/PageSection/PageSection';
import PageTitle from 'generics/PageTitle/PageTitle';
import PageWrapper from 'generics/PageWrapper/PageWrapper';

import useLinkCorporateProfile from './useLinkCorporateProfile';

import styles from './LinkCorporateProfile.scss';

const LinkCorporateProfile = () : JSX.Element => {
  const { t } = useTranslation(['corporateAccount']);

  const {
    contextMenu,
    form,
    isSSO,
    onGoBack,
  } = useLinkCorporateProfile();

  const {
    canSubmit,
    errors,
    values,
    onChangeField,
    onSubmitForm,
  } = form;

  const isDisabled = !canSubmit;

  const input = isSSO ? (
    <Input
      autoComplete="off"
      error={isNil(values.token) ? null : errors.token}
      id="token"
      label={t('form.token')}
      name="token"
      onChange={(e) => onChangeField('token', e.currentTarget.value)}
      type="number"
      value={values.token || ''}
    />
  ) : (
    <Input
      autoComplete="off"
      error={isNil(values.password) ? null : errors.password}
      id="password"
      label={t('form.password')}
      name="password"
      onChange={(e) => onChangeField('password', e.currentTarget.value)}
      type="password"
      value={values.password || ''}
    />
  );

  return (
    <>
      <PageWrapper>
        <div className={styles.layout}>
          <Form onSubmit={onSubmitForm}>
            <PageSection>
              <PageTitle
                title={t('form.title')}
                onBackButtonClick={onGoBack}
                actions={(
                  <Button isSmall type="submit" disabled={isDisabled} className={styles['mobile-button']}>
                    {t('form.btn')}
                  </Button>
                )}
              />
            </PageSection>
            <PageSection>
              <Input
                autoComplete="off"
                error={isNil(values.email) ? null : errors.email}
                id="email"
                label={t('form.email')}
                name="email"
                onChange={(e) => onChangeField('email', e.currentTarget.value)}
                value={values.email || ''}
              />
              { input }
              <Button type="submit" disabled={isDisabled} className={styles['desktop-button']}>
                {t('form.btn')}
              </Button>
              {
                isSSO && (
                  <div className={styles['form-description']}>
                    <p><b>{t('sso.title')}</b></p>
                    <p>{ReactHtmlParser(t('sso.description'))}</p>
                  </div>
                )
              }
            </PageSection>
          </Form>
        </div>
      </PageWrapper>

      <Modal
        isSmall
        isVisible={contextMenu.isVisible}
        onClose={contextMenu.onClose}
      >
        <MenuMessage
          color="secondary"
          message={(
            <p>
              <strong>{t('contextMenu.title')}</strong>
              <br />
              {t('contextMenu.message')}
            </p>
          )}
        />
        <MenuOptions color="secondary" options={contextMenu.options} />
      </Modal>
    </>
  );
};

export default LinkCorporateProfile;
