import { some, isEmpty, isNil } from 'lodash';
import { useState } from 'react';
import diff from 'object-diff';

const hasFormErrors = (formErrors: IErrors) : boolean => some(
  formErrors,
  (prop: string | null) => !isNil(prop),
);

/**
 * @param  {function} onSubmit
 * @param  {function} validate
 * @description custom hook to add form functionality
 */
const useForm = <T>({ defaultValues, onSubmit, onValidate }: IParams<T>) : IUseForm<T> => {
  const [values, setValues] = useState(defaultValues);
  const [errors, setErrors] = useState({});
  const [isValid, setIsValid] = useState(false);

  const handleReset = () => {
    setErrors({});
    setIsValid(false);
    setValues(defaultValues);
  };

  const handleChange = (name: string, value: string | boolean) => {
    const newValues = {
      ...values,
      [name]: value,
    };

    const newErrors = onValidate ? onValidate(<T>newValues) : {};

    setValues(<T>newValues);
    setErrors(newErrors);
    setIsValid(!hasFormErrors(newErrors));
  };

  const handleSubmit = (event?: React.FormEvent<HTMLFormElement>) => {
    if (event) {
      event.preventDefault();
    }

    if (!hasFormErrors(errors)) {
      onSubmit(<T>values);
    }
  };

  const isUpdated = !isEmpty(diff(defaultValues || {}, values || {}));

  return {
    errors,
    onChangeField: handleChange,
    onResetForm: handleReset,
    onSubmitForm: handleSubmit,
    isUpdated,
    isValid,
    values,
  };
};

export interface IErrors {
  [key: string]: string | null,
}

interface IParams<T> {
  defaultValues?: any,
  onSubmit: (values: T) => void,
  onValidate?: (values: T) => IErrors,
}

export interface IUseForm<T> {
  errors: IErrors,
  onChangeField: (name: string, value: string | boolean) => void,
  onResetForm: () => void,
  onSubmitForm: (event?: React.FormEvent<HTMLFormElement>) => void,
  isUpdated: boolean,
  isValid: boolean,
  values: T | undefined,
}

export default useForm;
