import { IAction } from 'app_state/types';
import initialState from 'app_state/session/initialState.json';
import sessionActionTypes from 'app_state/session/actionTypes';

const overview = (
  state: string = initialState.content.overview,
  action: IAction,
): string => {
  switch (action.type) {
    case sessionActionTypes.FETCH_CONTENT_SUCCESS:
      return action.payload?.userOverview ?? initialState.content.overview;

    case sessionActionTypes.RESET_STATE:
    case sessionActionTypes.FETCH_CONTENT_REQUEST:
    case sessionActionTypes.FETCH_CONTENT_FAILURE:
      return initialState.content.overview;

    default:
      return state;
  }
};

export default overview;
