import { useTranslation } from 'react-i18next';
import propTypes from 'prop-types';
import React from 'react';

import Button from 'generics/Button/Button';

import styles from './Pagination.scss';

const Pagination = (
  { isVisible, onClick, text } : IPagination,
) : JSX.Element | null => {
  const { t } = useTranslation('generics');

  return isVisible ? (
    <div className={styles['more-results']}>
      <Button
        className={styles['more-results__link']}
        isLink
        onClick={onClick}
      >
        { text ?? t('moreResults') }
      </Button>
    </div>
  ) : null;
};

Pagination.propTypes = {
  isVisible: propTypes.bool,
  onClick: propTypes.func.isRequired,
  text: propTypes.string,
};

Pagination.defaultProps = {
  isVisible: false,
  text: null,
};

interface IPagination {
  isVisible: boolean
  onClick: () => void
  text?: string,
}

export default Pagination;
