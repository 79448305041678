/**
 * Advice colors and icons based on keys
 */
export const ADVICE_DESIGNS : IAdviceDesigns[] = [
  {
    key: 'relationship-decoder',
    category: 'relationship',
    icon: 'user',
  },
  {
    key: 'friend-family-decoder',
    category: 'friend-family',
    icon: 'user-friends',
  },
  {
    key: 'dating-decoder',
    category: 'dating',
    icon: 'hands-heart',
  },
  {
    key: 'significant-other-decoder',
    category: 'significant-other',
    icon: 'rings-wedding',
  },
];

export const DEFAULT_CATEGORY = 'relationship';

export const DEFAULT_ICON = 'user';

interface IAdviceDesigns {
  category: keyType,
  icon: iconType,
  key: string,
}

export type iconType = 'user' | 'user-friends' | 'hands-heart' | 'rings-wedding';
export type keyType = 'relationship' | 'friend-family' | 'dating' | 'significant-other';
