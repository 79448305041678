import propTypes from 'prop-types';
import React from 'react';

import AppHeader from 'components/AppHeader/AppHeader';
import AppFooter from 'components/AppFooter/AppFooter';
import NotificationBar from 'components/NotificationBar/NotificationBar';

const AuthenticatedLayout : React.FC<any> = ({ children, isRestrictedLayout } : any) => (
  <>
    <AppHeader isRestrictedLayout={isRestrictedLayout} />
    <NotificationBar />
    { children }
    <AppFooter />
  </>
);

AuthenticatedLayout.propTypes = {
  children: propTypes.node.isRequired,
  isRestrictedLayout: propTypes.bool.isRequired,
};

export default AuthenticatedLayout;
