/* eslint-disable react-hooks/exhaustive-deps */
import { formatRoute } from 'react-router-named-routes';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import api from 'api';
import Advice from 'classes/Advice/Advice.class';
import Profile from 'classes/Profile/Profile.class';
import URLS from 'app_constants/urls';
import useGetProfile from 'custom_hooks/useGetProfile';
import useSavedTips from 'custom_hooks/useSavedTips';
// TODO: Remove this when epic returns titles
const DEFAULT_TITLE = 'Advice';

const useAdvice = () : IUseAdvice => {
  const history = useHistory();
  const { id, key: moduleKey } = useParams<paramsType>();
  const profileId = parseInt(id, 10);
  const { currentProfile, onGetProfile } = useGetProfile();
  const { savedTips, onSaveTip, onUnsaveTip, onFetchSavedTips } = useSavedTips();

  const [isLoading, setIsLoading] = useState(true);
  const [advice, setAdvice] = useState<Advice | null>(null);
  const [items, setItems] = useState<Advice[]>([]);

  const fetchContent = () => {
    const onError = () => {
      setIsLoading(false);
      history.push(URLS.home);
    };

    const onSuccess = ({ documents }: any) : void => {
      if (!documents) {
        onError();
        return;
      }

      const document = documents.length > 0 ? documents[0] : null;
      const newAdvice = new Advice({
        key: moduleKey,
        title: document?.title,
      });

      const newItems = document?.matches.map(
        (
          { title, content }: { title: string, content: string},
          index: number,
        ) => {
          // TODO: Remove default title when epic returns titles
          const newTitle = title || `${DEFAULT_TITLE} ${index + 1}`;

          return new Advice({
            content,
            contextType: 'pair',
            key: moduleKey,
            title: newTitle,
          });
        });

      setAdvice(newAdvice);
      setItems(newItems);
      setIsLoading(false);
    };

    api.contentManager.getContent(
      {
        contentType: 'pair',
        moduleKey,
        profileId,
      },
      onSuccess,
      onError,
    );
  };

  useEffect(() => {
    onGetProfile();
  }, [profileId]);

  useEffect(() => {
    if (currentProfile) {
      if (currentProfile.isConnected) {
        onFetchSavedTips();
        fetchContent();
      } else {
        history.push(formatRoute(URLS.profile, { id }));
      }
    }
  }, [currentProfile]);

  return {
    advice,
    currentProfile,
    isLoading,
    items,
    onSaveTip,
    onUnsaveTip,
    savedTips,
  };
};

export interface IUseAdvice {
  advice: Advice | null,
  currentProfile: Profile | null,
  isLoading: boolean,
  items: Advice[],
  onSaveTip: (tip: Advice, onFinish?: () => void) => void,
  onUnsaveTip: (tipId: number, onFinish?: () => void) => void,
  savedTips: Advice[],
}

type paramsType = {
  id: string,
  key: string,
}

export default useAdvice;
