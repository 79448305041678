/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import api from 'api';
import Profile from 'classes/Profile/Profile.class';
import URLS from 'app_constants/urls';

const useGetProfile = () : IUseGetProfile => {
  const history = useHistory();
  const { id } = useParams<paramsType>();
  const profileId = parseInt(id, 10);

  const [currentProfile, setCurrentProfile] = useState<Profile | null>(null);

  const fetchProfile = () => {
    const onSuccess = ({ profile } : any) => {
      setCurrentProfile(new Profile({
        ...profile,
      }));
    };

    const onError = () => {
      history.push(URLS.home);
    };

    api.profiles.getProfile(
      profileId,
      onSuccess,
      onError,
    );
  };

  return {
    currentProfile,
    onGetProfile: fetchProfile,
    setCurrentProfile,
  };
};

export interface IUseGetProfile {
  currentProfile: Profile | null,
  onGetProfile: () => void,
  setCurrentProfile: (profile: Profile | null) => void,
}

type paramsType = {
  id: string,
}

export default useGetProfile;
