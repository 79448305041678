import { isNil } from 'lodash';
import { sprintf } from 'sprintf-js';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import React, { useContext } from 'react';

import { SessionContext } from 'app_state/session/SessionProvider';
import Button from 'generics/Button/Button';
import Form from 'generics/Form/Form';
import Input from 'generics/Input/Input';
import Modal from 'generics/Modal/Modal';
import PageSection from 'generics/PageSection/PageSection';
import PageWrapper from 'generics/PageWrapper/PageWrapper';
import URLS from 'app_constants/urls';

import styles from './ForgotPassword.scss';
import useForgotPassword from './useForgotPassword';

const ForgotPassword = () : JSX.Element => {
  const { state } = useContext(SessionContext);
  const { isAuthenticated } = state;
  const { t } = useTranslation('forgotPassword');
  const history = useHistory();
  const goToLogin = () => history.push(URLS.login);

  if (isAuthenticated) {
    history.push(URLS.home);
  }

  const {
    form: {
      errors,
      isValid,
      onChangeField,
      onSubmitForm,
      values = { email: null },
    },
    isComplete,
  } = useForgotPassword();

  return (
    <PageWrapper>
      <div
        className={classNames(
          [styles.layout],
          [styles['no-auth']],
        )}
      >
        <PageSection>

          <div
            className={classNames(
              [styles.intro],
              [styles['no-auth']],
            )}
          >
            <h1>{t('title')}</h1>
            <p>{t('description')}</p>
          </div>

          <Form onSubmit={onSubmitForm}>
            <Input
              error={isNil(values.email) ? null : errors.email}
              id="email"
              isBlockLabel
              label={t('form.email')}
              name="email"
              onChange={(e) => onChangeField('email', e.currentTarget.value)}
              value={values.email || ''}
            />

            <Button
              className={styles.submit}
              disabled={!isValid}
              type="submit"
              isBlockAtTablet
              isRounded
            >
              {t('form.btn')}
            </Button>
          </Form>

          <p className={styles['login-back']}>
            <Button
              isLink
              onClick={goToLogin}
            >
              {t('backToLogin')}
            </Button>
          </p>

          {
            isComplete && (
              <Modal
                isVisible
                onClose={goToLogin}
              >
                <div className="u-align--center">
                  <h2>{t('successTitle')}</h2>
                  <p>
                    {sprintf(t('messageSent'), values.email)}
                  </p>
                  <p>
                    {t('successDescription')}
                  </p>
                  <br />
                  <Button onClick={goToLogin}>
                    {t('backToLogin')}
                  </Button>
                </div>
              </Modal>
            )
          }

        </PageSection>
      </div>
    </PageWrapper>
  );
};

export default ForgotPassword;
