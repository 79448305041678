import { isNil } from 'lodash';
import { useTranslation } from 'react-i18next';
import propTypes from 'prop-types';
import React, { useState } from 'react';
import { sprintf } from 'sprintf-js';

import Button from 'generics/Button/Button';
import Eyebrow from 'generics/Eyebrow/Eyebrow';
import Form from 'generics/Form/Form';
import Input from 'generics/Input/Input';
import LoadingAnimation from 'generics/LoadingAnimation/LoadingAnimation';
import Select from 'generics/Select/Select';

import CardSection from '../CardSection/CardSection';
import styles from './CheckoutForm.scss';
import useCheckoutForm from './useCheckoutForm';

const CheckoutForm = ({ onFinish } : ICheckoutFormProps) : JSX.Element => {
  const { APGI_REPORT_PRICE } = process.env;
  const { t } = useTranslation('payment');
  const [error, setError] = useState('');

  const {
    countries,
    defaultCountryId,
    errors,
    isLoading,
    isValid,
    onChangeField,
    onSubmitForm,
    stripe,
    stripeEmail,
    values,
  } = useCheckoutForm(onFinish, setError);

  const order = `${t('reportQuantity')} ${t('reportTitle')}`;

  return (
    <Form onSubmit={onSubmitForm}>
      <div className={styles.formGroup}>
        <Input
          className={styles['order-input']}
          id="order"
          label="Order"
          name="order"
          disabled
          type="text"
          value={order}
        />
        <Input
          id="stripeEmail"
          label={t('form.email')}
          name="stripeEmail"
          disabled
          value={stripeEmail || ''}
        />
      </div>
      <div className={styles.formGroup}>
        <Eyebrow className={styles.eyebrow}>
          {t('form.cardInformation.title')}
        </Eyebrow>
        <CardSection />
        {error && (
          <p className={styles.error}>{error}</p>
        )}
      </div>
      <div className={styles.formGroup}>
        <Input
          error={isNil(values.fullName) ? null : errors.fullName}
          id="fullName"
          label={t('form.fullName')}
          name="fullName"
          onChange={(e) => onChangeField('fullName', e.currentTarget.value)}
          type="text"
          value={values.fullName || ''}
        />
        <Select
          error={isNil(values.countryId) ? null : errors.countryId}
          label={t('form.country.name')}
          placeholder={t('form.country.placeholder')}
          id="countryId"
          name="countryId"
          required
          onChange={(e) => onChangeField('countryId', e.currentTarget.value)}
          options={countries}
          value={values.countryId || ''}
        />
        {defaultCountryId === values?.countryId && (
          <Input
            error={isNil(values.postalCode) ? null : errors.postalCode}
            id="postalCode"
            label={t('form.postalCode')}
            name="postalCode"
            onChange={(e) => onChangeField('postalCode', e.currentTarget.value)}
            type="text"
            value={values.postalCode || ''}
          />
        )}
      </div>
      {isLoading ? (
        <LoadingAnimation />
      ) : (
        <Button disabled={!stripe || !isValid} isBlock type="submit">{sprintf(t('payButton'), APGI_REPORT_PRICE)}</Button>
      )}
    </Form>
  );
};

interface ICheckoutFormProps {
  onFinish?: () => void | null,
}

CheckoutForm.propTypes = {
  onFinish: propTypes.func,
};

CheckoutForm.defaultProps = {
  onFinish: null,
};

export default CheckoutForm;
